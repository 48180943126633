export const fields = {
  buyer: {
    purchaseOrderNumber: {
      accessor: 'purchaseOrderNumber',
      fullAccessor: 'buyerData.purchaseOrderNumber',
      component: 'text',
      defaultValue: '',
      displayName: 'Purchase Order Number',
      type: 'string',
      blotter: true,
    },
    purchaseOrderDate: {
      accessor: 'purchaseOrderDate',
      fullAccessor: 'buyerData.purchaseOrderDate',
      component: 'date',
      defaultValue: new Date(),
      displayName: 'Purchase Order Date',
      type: 'date',
      blotter: true,
    },
    buyerNote: {
      accessor: 'buyerNote',
      fullAccessor: 'buyerData.buyerNote',
      component: 'text',
      defaultValue: '',
      displayName: 'Buyer Note',
      type: 'string',
      blotter: true,
    },
    inspectionReport: {
      accessor: 'inspectionReport',
      fullAccessor: 'buyerData.inspectionReport',
      component: 'select',
      defaultValue: null,
      displayName: 'Inspection Report',
      isFile: true,
      fileType: 'INSPECTION_REPORT',
      type: 'file',
      blotter: false,
    },
    other: {
      accessor: 'other',
      fullAccessor: 'buyerData.other',
      component: 'select',
      defaultValue: null,
      displayName: 'Other',
      isFile: true,
      fileType: 'OTHER',
      type: 'file',
      blotter: false,
    },
    /*  orderSpecification: {
      accessor: 'orderSpecification',
      fullAccessor: 'buyerData.orderSpecification',
      component: 'select',
      defaultValue: null,
      displayName: 'Order Specification',
      isFile: true,
      fileType: 'ORDER_SPECIFICATION',
      type: 'file',
      blotter: false,
    }, */
  },
  seller: {
    invoiceNumber: {
      accessor: 'invoiceNumber',
      fullAccessor: 'sellerData.invoiceNumber',
      component: 'text',
      defaultValue: '',
      displayName: 'Invoice Number',
      type: 'string',
      blotter: true,
    },
    invoiceDate: {
      accessor: 'invoiceDate',
      fullAccessor: 'sellerData.invoiceDate',
      component: 'date',
      defaultValue: new Date(),
      displayName: 'Invoice Date',
      type: 'date',
      blotter: true,
    },
    trackingNumber: {
      accessor: 'trackingNumber',
      fullAccessor: 'sellerData.trackingNumber',
      component: 'text',
      defaultValue: '',
      displayName: 'Tracking Number',
      type: 'string',
      blotter: true,
    },
    sellerNote: {
      accessor: 'sellerNote',
      fullAccessor: 'sellerData.sellerNote',
      component: 'text',
      defaultValue: '',
      displayName: 'Seller Note',
      type: 'string',
      blotter: true,
    },
    invoice: {
      accessor: 'invoice',
      fullAccessor: 'sellerData.invoice',
      component: 'select',
      defaultValue: null,
      displayName: 'Invoice',
      isFile: true,
      fileType: 'INVOICE',
      type: 'file',
      blotter: false,
    },
    packagingList: {
      accessor: 'packagingList',
      fullAccessor: 'sellerData.packagingList',
      component: 'select',
      defaultValue: null,
      displayName: 'Packing List',
      isFile: true,
      fileType: 'PACKING_LIST',
      type: 'file',
      blotter: false,
    },
    airwayBill: {
      accessor: 'airwayBill',
      fullAccessor: 'sellerData.airwayBill',
      component: 'select',
      defaultValue: null,
      displayName: 'Air Way Bill',
      isFile: true,
      fileType: 'AIRWAY_BILL',
      type: 'file',
      blotter: false,
    },
    healthCertificate: {
      accessor: 'healthCertificate',
      fullAccessor: 'sellerData.healthCertificate',
      component: 'select',
      defaultValue: null,
      displayName: 'Health Certificate',
      isFile: true,
      fileType: 'HEALTH_CERTIFICATE',
      type: 'file',
      blotter: false,
    },
    certificateOfOrigin: {
      accessor: 'certificateOfOrigin',
      fullAccessor: 'sellerData.certificateOfOrigin',
      component: 'select',
      defaultValue: null,
      displayName: 'Certificate of Origin',
      isFile: true,
      fileType: 'CERTIFICATE_OF_ORIGIN',
      type: 'file',
      blotter: false,
    },
    inspectionReport: {
      accessor: 'inspectionReport',
      fullAccessor: 'sellerData.inspectionReport',
      component: 'select',
      defaultValue: null,
      displayName: 'Inspection Report',
      isFile: true,
      fileType: 'INSPECTION_REPORT',
      type: 'file',
      blotter: false,
    },

    fullPack: {
      accessor: 'fullPack',
      fullAccessor: 'sellerData.fullPack',
      component: 'select',
      defaultValue: null,
      displayName: 'Full Pack',
      isFile: true,
      fileType: 'FULL_PACK',
      type: 'file',
      blotter: false,
    },
    other: {
      accessor: 'other',
      fullAccessor: 'sellerData.other',
      component: 'select',
      defaultValue: null,
      displayName: 'Other',
      isFile: true,
      fileType: 'OTHER',
      type: 'file',
      blotter: false,
    },

    /* fishCv: {
      accessor: 'fishCv',
      fullAccessor: 'sellerData.fishCv',
      component: 'select',
      defaultValue: null,
      displayName: 'Fish CV',
      isFile: true,
      fileType: 'FISH_CV',
      type: 'file',
      blotter: false,
    }, */
    /*   productSpecification: {
      accessor: 'productSpecification',
      fullAccessor: 'sellerData.productSpecification',
      component: 'select',
      defaultValue: null,
      displayName: 'Product Specification',
      isFile: true,
      fileType: 'PRODUCT_SPECIFICATION',
      type: 'file',
      blotter: false,
    }, */
  },
};

export const fieldMap = {
  ORDER_SPECIFICATION: 'orderSpecification',
  PRODUCT_SPECIFICATION: 'productSpecification',
  FISH_CV: 'fishCv',
  PACKING_LIST: 'packagingList',
  INVOICE: 'invoice',
  AIRWAY_BILL: 'airwayBill',
  HEALTH_CERTIFICATE: 'healthCertificate',
  CERTIFICATE_OF_ORIGIN: 'certificateOfOrigin',
  FULL_PACK: 'fullPack',
  INSPECTION_REPORT: 'inspectionReport',
  OTHER: 'other',
};

export const getPostTradeBlotterItems = () => {
  const postTradeFields = { ...fields.buyer, ...fields.seller };
  const nextFields = {};
  Object.keys(postTradeFields).forEach((field) => {
    nextFields[field] = {
      request: false,
      response: false,
      fresh: true,
      frozen: true,
      rfqDetail: false,
      rfqForm: false,
      orderBlotter: false,
      tradeBlotter: true,
      blotter: postTradeFields[field].blotter,
      spot: true,
      contract: true,
      vap: true,
      vapcontract: true,
      displayName: postTradeFields[field].displayName,
      accessor: postTradeFields[field].accessor,
      fullAccessor: postTradeFields[field].fullAccessor,
      type: postTradeFields[field].type,
      component: null,
      defaultValue: null,
    };
  });

  return nextFields;
};
