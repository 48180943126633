import { takeEvery, put, select } from 'redux-saga/effects';

import * as actionTypes from '../actionTypes';

import { createAction } from '../../../commons/actions';

import schemas from '../../schemas';
import { getFieldsObject } from '../../schemas/utils/modelFunctions';

/**
 *
 * @param {*} action
 */

function* openRfqForm(action) {
  try {
    const payload = action.payload;
    const meta = getMeta();
    const schema = yield select(schemas.selectors.getAllSchemas);

    const allFields = getFieldsObject(schema, 'rfq');

    yield put(createAction(actionTypes.OPEN_RFQ_FORM_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(createAction(actionTypes.OPEN_RFQ_FORM_ERROR, { error, type: action.payload.type }));
  }
}

export function* watchOpenRfqForm() {
  yield takeEvery(actionTypes.OPEN_RFQ_FORM, openRfqForm);
}

function getMeta() {
  return {
    receivedAt: new Date(),
  };
}
