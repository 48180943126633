import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Button,
  Icon,
  IconButton,
  TextField,
  Tooltip,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton, ConfirmButton } from '../../../commons/components/buttons';

import { removeFromLS } from '../../../commons/localStorage';

import { generalSettingsOpen, generalSettingsUpdate } from '../actions';
import { GENERAL_SETTINGS_OPEN, GENERAL_SETTINGS_UPDATE } from '../constants';
import { isGeneralSettingsOpen, getSavedRfqPresets } from '../selectors';

const styles = (theme) => ({
  container: { flex: 1, display: 'flex', flexDirection: 'column' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
  editText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  btn: {
    marginLeft: theme.spacing(2),
  },
});

class ManageRfqPresets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  apply = () => {
    removeFromLS('myRfqs');
    this.props.onClose();
  };

  deleteWorkspace = (id) => {
    const payload = {
      type: 'deleteWorkspace',
      id,
    };

    this.props.update(payload);
    this.setState({});
  };

  onSave = () => {
    const payload = {
      type: 'saveAll',
    };

    this.props.update(payload);
  };

  onEditClick = (id) => {
    if (this.state.showText === id) {
      this.setState({ showText: -1 });
    } else {
      this.setState({ showText: id });
    }
  };

  onNameChange = (e, id) => {
    this.setState({ [id]: e.target.value });
  };

  saveNameChange = (e, id) => {
    const payload = {
      type: 'saveName',
      id,
      name: this.state[id],
    };
    this.props.update(payload);
    this.setState({ showText: -1 });
  };

  close = () => {
    const payload = {
      type: 'closeManage',
    };

    this.props.update(payload);
  };

  render() {
    const { classes, open, savedRfqPresets } = this.props;

    return (
      <PopUp open={open} title="MANAGE RFQ PRESETS" onClose={this.props.onClose}>
        <div className={classes.container}>
          <div className={classes.listContainer}>
            <List open classes={{ root: classes.list }}>
              <ListItem>
                <Button onClick={this.apply} fullWidth variant="outlined">
                  <Icon style={{ paddingRight: '32px' }}>remove</Icon>CLEAR LAST RFQS
                </Button>
              </ListItem>
              {savedRfqPresets.map((preset) => (
                <div key={`${preset.id}_w`} className={classes.listItem}>
                  <ListItem key={preset.id} button>
                    <ListItemText primary={preset.name} />

                    <ListItemSecondaryAction>
                      <Tooltip title="RENAME" aria-label="RENAME">
                        <IconButton onClick={() => this.onEditClick(preset.id)}>
                          <Icon>edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="DELETE" aria-label="DELETE">
                        <IconButton onClick={() => this.deleteWorkspace(preset.id)}>
                          <Icon>delete</Icon>
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {this.state.showText === preset.id && (
                    <div className={classes.editText}>
                      <TextField
                        classes={{ root: classes.nameChange }}
                        InputProps={{ className: classes.nameChangeInput }}
                        onChange={(e) => this.onNameChange(e, preset.id)}
                        placeholder="edit Name"
                        fullWidth
                      />
                      <Button
                        variant="outlined"
                        className={classes.btn}
                        onClick={(e) => this.saveNameChange(e, preset.id)}
                      >
                        DONE
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </List>
          </div>
          <div className={classes.toolbarContainer}>
            <ToolbarBottom>
              <>
                <CancelButton onClick={this.props.onClose} />
                <ConfirmButton title="Apply" onClick={this.onSave} />
              </>
            </ToolbarBottom>
          </div>
        </div>
      </PopUp>
    );
  }
}
ManageRfqPresets.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    open: isGeneralSettingsOpen(state),
    savedRfqPresets: getSavedRfqPresets(state) || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => {
      dispatch(generalSettingsOpen(GENERAL_SETTINGS_OPEN, { open: false }));
    },
    update: (payload) => {
      dispatch(generalSettingsUpdate(GENERAL_SETTINGS_UPDATE, payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManageRfqPresets));
