import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';

import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';
import { directions } from '../../../../commons/config/constants';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    backgroundColor: 'rgba(0,0,0,.1)',
  },
  containerOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    backgroundColor: 'rgba(0,0,0,.2)',
  },

  rowNotVisible: {
    height: '1px',
  },
  rowMargin: {
    marginBottom: theme.spacing(),
  },
  small: {
    width: theme.spacing(2.4),
    height: theme.spacing(2.4),
    backgroundColor: theme.palette.text.primary,
  },
  week: {
    color: theme.palette.secondary.main,
    width: '75px',
  },
});

class ExecuteTradeFormRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      showCalculator: false,
    };
  }

  getDirection = (direction) => {
    let value = directions.BUY;
    if (direction === directions.BUY) {
      value = directions.SELL;
    }
    return value;
  };

  render() {
    const { classes, orderRow } = this.props;
    const FIELD_WIDTH = '150px';
    const subSegment = { displayName: 'Weight Class', accessor: 'weightClass' };

    return (
      <Card style={{ margin: '4px', padding: '8px', display: 'flex', flexDirection: 'row' }}>
        <div className={classes.containerItems} style={{ flex: 1 }}>
          <ReadOnlyField
            displayName={subSegment.displayName}
            value={orderRow[subSegment.accessor]}
            width={FIELD_WIDTH}
            ellipsis
          />
          <ReadOnlyField
            displayName={subSegment.displayName}
            value={orderRow['volume']}
            width={FIELD_WIDTH}
          />
          <ReadOnlyField
            displayName={subSegment.displayName}
            value={orderRow['price']}
            width={FIELD_WIDTH}
          />
        </div>
      </Card>
    );
  }
}

ExecuteTradeFormRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExecuteTradeFormRow);
