import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import lightBlue from '@material-ui/core/colors/lightBlue';

import logoLight from '../../../assets/logos/Logo_Light.png';
import logoDark from '../../../assets/logos/LFEX-Logo.png';

import Toast from '../Toast';
import { required, password, phone } from '../validators';
import FormTextField from '../FormTextField';

import {
  getStatus,
  getError,
  getRequiredAttributes,
  getThemeType,
} from '../../../modules/auth/selectors';

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    width: '100vw',
  },
  fieldContainer: {
    margin: '30px 0px',
    padding: '0px 8px',
    width: '100%',
    flex: 1,
    minWidth: 500,
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
  image: {
    width: '10%',
  },
  submit: {
    color: theme.palette.common.white,

    margin: 0,
    backgroundColor: lightBlue[500],
    '&:hover': {
      backgroundColor: lightBlue[700],
    },
  },
  backButton: {
    color: theme.palette.common.white,
    margin: 0,
    fontSize: '11px',
  },

  buttonRowAlignment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '500px',
    flex: 1,
  },
  logoContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
  },
  imageName: {
    height: '10vmin',
    margin: '15px 0px 35px 0px',
    flex: 1,
  },
  imageIcon: {
    flex: 1,
  },
});
const convertToPlaceholder = (str) => {
  switch (str) {
    case 'name':
      return 'First Name';
    case 'family_name':
      return 'Last Name';
    default:
      return str
        .split('_')
        .map((part) => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
        .join(' ');
  }
};
const Form = (props) => {
  const {
    handleSubmit,
    classes,
    pristine,
    submitting,
    requiredAttributes,
    backToSignIn,
    error,
    status,
    themType,
  } = props;
  const showError =
    status === 'error' && (error !== null && error !== undefined ? (error ? true : false) : false);
  const errorMessage = error === null || error === undefined ? '' : error.error;
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <div className={classes.fieldContainer}>
        <Typography variant="subtitle1"> Change Password</Typography>
      </div>

      <div className={classes.fieldContainer}>
        <Field
          name="password"
          type="password"
          component={FormTextField}
          label="New Password"
          validate={[required, password]}
        />
      </div>

      {requiredAttributes.map((attribute) => {
        const label = convertToPlaceholder(attribute);
        return (
          <div className={classes.fieldContainer} key={attribute}>
            <Field
              name={attribute}
              type="text"
              component={FormTextField}
              label={label}
              validate={attribute === 'phone_number' ? [required, phone] : required}
            />
          </div>
        );
      })}
      <div className={classes.fieldContainer}>
        <div className={classes.buttonRowAlignment}>
          <div className={classes.buttonRow}>
            <Button className={classes.loginButton} onClick={(e) => backToSignIn(e)}>
              Back to Sign In
            </Button>
            <Button
              className={classes.backButton}
              variant="contained"
              color="secondary"
              type="submit"
              disabled={pristine || submitting}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.logoContainer}>
        <img
          className={classes.imageName}
          src={themType === 'DARK' ? logoLight : logoDark}
          alt="logo"
        />
      </div>
      {showError && <Toast message={errorMessage} />}
    </form>
  );
};

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    error: getError(state),
    status: getStatus(state),
    requiredAttributes: getRequiredAttributes(state),
    themType: getThemeType(state),
  };
}

export default reduxForm({
  form: 'require-password',
})(connect(mapStateToProps)(withStyles(styles)(Form)));
