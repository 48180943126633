import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Icon, Tooltip, Avatar, Card } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TableChartIcon from '@material-ui/icons/TableChart';

import SelectField from '../../../../commons/components/formFields/SelectField';
import NumberFieldCustom from '../../../../commons/components/formFields/numberFieldCustom';
import DateField from '../../../../commons/components/formFields/dateField';
import { errorMessages } from '../../../../commons/models/constants';
import { checkPrice, checkVolume } from '../../../../commons/config/formatters';
import { getContractWeek } from '../../../contracts/utils/deliveryWeeks';
import { getFresh } from '../../../contracts/utils/modelFunctions';
import contractType from '../../../contracts/models/contractType';
import orderUnderlying from '../../../contracts/models/orderUnderlying';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    backgroundColor: 'rgba(0,0,0,.1)',
  },
  containerOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    backgroundColor: 'rgba(0,0,0,.2)',
  },

  rowNotVisible: {
    height: '1px',
  },
  rowMargin: {
    marginBottom: theme.spacing(),
  },
  small: {
    width: theme.spacing(2.4),
    height: theme.spacing(2.4),
    backgroundColor: theme.palette.text.primary,
  },
  week: {
    color: theme.palette.secondary.main,
    width: '75px',
  },
});

// TODO: Extract all functionality to saga ans store
class ResponseFormRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  updatePrice = (price) => {
    this.setState({ price });
    const payload = {
      type: 'response',
      action: 'updateOrderRow',
      key: this.props.orderRow.key,
      field: 'price',
      value: price,
    };

    this.props.updateOrderRow(payload);
  };

  checkValid = (key, value) => {
    const returnValue = { message: '', value: false };
    switch (key) {
      case 'price':
        const price = checkPrice(value);
        returnValue.value = !price;
        returnValue.message = price ? '' : errorMessages.price;
        break;
      case 'volume':
        const volume = checkVolume(value);

        returnValue.value = !volume;
        returnValue.message = volume ? '' : errorMessages.volume;
        break;
      case 'targetOrganisations':
        returnValue.value = value.length !== 0;
        returnValue.message = errorMessages.targetOrganisations;
        break;
      default:
    }
    return returnValue;
  };

  handleChange = (name) => (event) => {
    const { errors } = this.state;
    const valid = this.checkValid(name, event.target.value);

    errors[name] = valid;

    this.setState({
      errors,
    });
    const payload = {
      type: 'response',
      action: 'updateOrderRow',
      key: this.props.orderRow.key,
      field: name,
      value: event.target.value,
    };

    this.props.updateOrderRow(payload);
  };

  handleDateChange = (name) => (date) => {
    const payload = {
      type: 'response',
      action: 'updateOrderRow',
      key: this.props.orderRow.key,
      field: name,
      value: date,
    };

    this.props.updateOrderRow(payload);
  };

  getErrors = (key) => {
    if (this.state.errors[key]) {
      return this.state.errors[key];
    }
    return { message: '', value: false };
  };

  /*  getMinDeliveryDate = () => {
    {
      const { activeRfq } = this.props;
      const deliveryDateFrom =
        activeRfq.contract.deliveryDate !== null
          ? moment(activeRfq.contract.deliveryDate.from)
          : moment(new Date());
      const harvestDate =
        this.state.harvestDate !== null ? moment(this.state.harvestDate) : moment(new Date());
      const returnDate = harvestDate > deliveryDateFrom ? harvestDate : deliveryDateFrom;
      return returnDate;
    }
  }; */

  getShowFreezeDate = () => {
    if (this.props.activeRfq) {
      if (this.props.activeRfq.contract) {
        if (this.props.activeRfq.contract.underlying.states) {
          if (
            this.props.activeRfq.contract.underlying.states.includes('FROZEN') ||
            this.props.activeRfq.contract.underlying.states.includes('ANY')
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  getMinDate = (field) => {
    return this.props.activeRfq.contract[field] !== null
      ? moment(this.props.activeRfq.contract[field].from)
      : moment(new Date());
  };
  getMaxDate = (field) => {
    return this.props.activeRfq.contract.freezeDate !== null
      ? moment(this.props.activeRfq.contract[field].to)
      : moment(new Date()).add(365, 'days');
  };

  render() {
    const { classes, activeRfq, enumerations, formFields } = this.props;

    const fresh = getFresh(activeRfq.contract.underlying);
    const orderRow = this.props.orderRow || {};

    const subSegment = orderUnderlying.fields[contractType.subSegment];
    const FIELD_WIDTH = '150px';
    const isOdd = this.props.index % 2 ? classes.containerOdd : classes.container;
    const weekDate = fresh === 'fresh' ? orderRow.harvestDate : orderRow.deliveryDate;
    const hDate = weekDate ? new Date(weekDate) : new Date();
    //const week = orderRow.week ? orderRow.week : getContractWeek(hDate);
    const week = getContractWeek(hDate);
    return (
      <>
        <Card style={{ margin: '4px', padding: '8px', display: 'flex', flexDirection: 'row' }}>
          {activeRfq.contract.week && (
            <div className={classes.containerItems}>
              <div className={classes.week}>WEEK</div>
              <div className={classes.week}>{this.props.defaultOrders ? '' : week}</div>
            </div>
          )}

          {/*  <div className={classes.containerItems}>
            <IconButton>
              <Avatar className={classes.small}>{this.props.index + 1}</Avatar>
            </IconButton>
          </div> */}
          <div className={classes.containerItems} style={{ flex: 1 }}>
            <SelectField
              accessor={subSegment.accessor}
              displayName={subSegment.displayName}
              value={orderRow[subSegment.accessor]}
              values={enumerations[subSegment.accessor]}
              handleChange={this.handleChange}
              width={FIELD_WIDTH}
            />{' '}
            <NumberFieldCustom
              accessor={'volume'}
              key={`${'volume'}-${orderRow.key}`}
              displayName={formFields['volume'].displayName}
              value={orderRow['volume']}
              handleChange={this.handleChange}
              error={this.getErrors('volume')}
              adornment={'CTNS'}
              width={FIELD_WIDTH}
              step={formFields['volume'].step}
            />
            <NumberFieldCustom
              accessor={'price'}
              key={`${'price'}-${orderRow.key}`}
              displayName={formFields['price'].displayName}
              value={orderRow.price}
              handleChange={this.handleChange}
              error={this.getErrors('price')}
              adornment={orderRow[formFields['price'].adornment]}
              width={FIELD_WIDTH}
              step={formFields['price'].step}
            />
            {/*    {Object.keys(formFields).map((field) => {
              if (!formFields[field].amendable) {
                switch (formFields[field].component) {
                  case 'number':
                    return (
                      <NumberFieldCustom
                        accessor={field}
                        key={`${field}-${orderRow.key}`}
                        displayName={formFields[field].displayName}
                        value={orderRow[field]}
                        handleChange={this.handleChange}
                        error={this.getErrors(field)}
                        adornment={orderRow[formFields[field].adornment]}
                        width={FIELD_WIDTH}
                        step={formFields[field].step}
                        fontSizeLarge={true}
                      />
                    );

                  case 'select':
                    return (
                      <SelectField
                        accessor={field}
                        key={`${field}-${orderRow.key}`}
                        displayName={formFields[field].displayName}
                        value={orderRow[field] || ''}
                        values={enumerations[field]}
                        handleChange={this.handleChange}
                        width={FIELD_WIDTH}
                      />
                    );
                  case 'date':
                    return (
                      <DateField
                        accessor={field}
                        displayName={formFields[field].displayName}
                        value={orderRow[field]}
                        disablePast={formFields[field].disablePast}
                        handleDateChange={this.handleDateChange}
                        minDate={
                          activeRfq.contract[field] !== null
                            ? moment(activeRfq.contract[field].from)
                            : moment(new Date())
                        }
                        maxDate={
                          activeRfq.contract[field].to !== null
                            ? moment(activeRfq.contract[field].to)
                            : moment(new Date()).add(365, 'days')
                        }
                        width={FIELD_WIDTH}
                        key={`${field}-${orderRow.key}`}
                        clearable={formFields[field].clearable}
                      />
                    );
                  default:
                    return null;
                }
              }
            })} */}
          </div>
          <div className={classes.containerItems}>
            {/*  <Tooltip title="Copy Order" aria-label="Copy Order">
              <IconButton onClick={this.props.copyOrderRow}>
                <Icon>file_copy</Icon>
              </IconButton>
            </Tooltip> */}

            <Tooltip title="Delete Order" aria-label="Delete Order">
              <IconButton onClick={this.props.deleteOrderRow}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Card>
      </>
    );
  }
}

ResponseFormRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResponseFormRow);
