import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SelectField from '../../../commons/components/formFields/SelectField';
import UserProfile from './UserProfile';
import { Icon, TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultipleSelectFieldFilled from '../../../commons/components/formFields/multipleSelectFieldFilled';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  listItem: {
    flex: 1,
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(),
  },
  icon: {
    padding: theme.spacing(),
  },
});

class Users extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      userValue: null,
      species: [],
      connectionState: null,
    };
  }
  getUsers = () => {
    const organisation = this.state.value;
    const user = this.state.userValue;
    const users = [...this.props.users]

      .filter((u) => (organisation !== null ? u.organisationId === organisation.id : true))
      .filter((u) => (user !== null ? u.id === user.id : true))
      .filter((u) => {
        if (this.state.species.length > 0) {
          const array1 = [...this.state.species];
          const array2 = u.details ? (u.details.species ? u.details.species : []) : [];
          const filteredArray = array1.filter((value) => array2.includes(value));
          return filteredArray.length > 0;
        } else {
          return true;
        }
      })
      .filter((u) => (this.state.connectionState !== null ? u.connectionState === 'online' : true));
    return users;
  };

  render() {
    const {
      organisationsObject,
      isSuperAdmin,
      isOrganisationAdmin,
      myOrganisation,
      classes,
      organisations,
    } = this.props;
    const users = this.getUsers();
    const CustomPaper = (props) => {
      return <Paper elevation={8} {...props} />;
    };
    return (
      <React.Fragment>
        <div className={classes.filterRow}>
          <div className={classes.icon}>
            <Icon>filter_list</Icon>
          </div>
          <Autocomplete
            id="combo-box-demo"
            options={this.props.users}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            style={{ width: 256, margin: 2 }}
            PaperComponent={CustomPaper}
            renderInput={(params) => (
              <TextField {...params} label="Search User" variant="filled" size="small" />
            )}
            onChange={(e, value) => this.setState({ userValue: value })}
            onInputChange={(e, value) => this.setState({ userInputValue: value })}
          />
          <Autocomplete
            id="organisations"
            options={organisations}
            getOptionLabel={(option) => option.name}
            style={{ width: 256, margin: 2 }}
            PaperComponent={CustomPaper}
            renderInput={(params) => (
              <TextField {...params} label="Search Organisation" variant="filled" size="small" />
            )}
            onChange={(e, value) => this.setState({ value: value })}
            onInputChange={(e, value) => this.setState({ inputValue: value })}
          />
          <MultipleSelectFieldFilled
            accessor="species"
            displayName="Species"
            addUnderline
            value={this.state.species}
            values={this.props.species || []}
            handleChange={(name) => (e) => {
              this.setState({ [name]: e.target.value });
            }}
            width={200}
          />
          <Autocomplete
            id="connectionState"
            options={['LoggedIn']}
            getOptionLabel={(option) => option}
            style={{ width: 200, margin: 2 }}
            PaperComponent={CustomPaper}
            renderInput={(params) => (
              <TextField {...params} label="LoggedIn" variant="filled" size="small" />
            )}
            onChange={(e, value) => this.setState({ connectionState: value })}
            //onInputChange={(e, value) => this.setState({ inputValue: value })}
          />
        </div>

        {users.map((user) => {
          return (
            <UserProfile
              user={user}
              organisationsObject={organisationsObject}
              key={user.id}
              editUser={this.props.editUser}
              isSuperAdmin={isSuperAdmin}
              isOrganisationAdmin={isOrganisationAdmin}
              myOrganisation={myOrganisation}
              myUser={this.props.myUser}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Users);
