import { createSelector } from 'reselect';
import { NAME } from '../constants';

const getOrderModelProp = (state) => {
  const items = state[NAME].orderModel;
  return items;
};
const getOrderAssetsModelProp = (state) => {
  const items = state[NAME].orderAssetsModel;
  return items;
};
const getOrderContractModelProp = (state) => {
  const items = state[NAME].orderContractModel;
  return items;
};
const getOrderContractUnderlyingModelProp = (state) => {
  const items = state[NAME].orderContractUnderlyingModel;
  return items;
};
const getRfqModelProp = (state) => {
  const items = state[NAME].rfqModel;
  return items;
};
const getRfqAssetsModelProp = (state) => {
  const items = state[NAME].rfqAssetsModel;
  return items;
};
const getRfqContractModelProp = (state) => {
  const items = state[NAME].rfqContractModel;
  return items;
};
const getRfqContractUnderlyingModelProp = (state) => {
  const items = state[NAME].rfqContractUnderlyingModel;
  return items;
};
export const getAllSchemas = createSelector(
  getOrderModelProp,
  getOrderAssetsModelProp,
  getOrderContractModelProp,
  getOrderContractUnderlyingModelProp,
  getRfqModelProp,
  getRfqAssetsModelProp,
  getRfqContractModelProp,
  getRfqContractUnderlyingModelProp,
  (
    orderModel,
    orderAssetsModel,
    orderContractModel,
    orderContractUnderlyingModel,
    rfqModel,
    rfqAssetsModel,
    rfqContractModel,
    rfqContractUnderlyingModel,
  ) => {
    return {
      orderModel,
      orderAssetsModel,
      orderContractModel,
      orderContractUnderlyingModel,
      rfqModel,
      rfqAssetsModel,
      rfqContractModel,
      rfqContractUnderlyingModel,
    };
  },
);
