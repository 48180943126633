import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import clsx from 'clsx';
import MomentUtils from '@date-io/moment';

import { textField, fieldFontSize } from './formFieldStyles';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';

const styles = (theme) => ({
  textStyle: {
    ...textField(theme),
  },
  darkBackground: {
    background: theme.palette.background.darkRow,
  },
  fieldFont: {
    ...fieldFontSize(theme),
    padding: 0,
  },
});

const DateField = (props) => {
  const { classes, accessor, displayName, disablePast } = props;
  const value = props.value || null;
  const defaultValue = props.defaultValue || null;
  let textStyle = {};

  if (displayName.toUpperCase().includes('HARVEST')) {
    textStyle = { color: teal[300] };
  } else if (displayName.toUpperCase().includes('DELIVERY')) {
    if (!displayName.toUpperCase().includes('MAX')) {
      textStyle = { color: lightBlue[300] };
    } else {
      textStyle = { color: lightBlue[300] };
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        clearable={props.clearable}
        id={props.key}
        key={props.key}
        variant="filled"
        size="small"
        autoOk
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        className={classes.textStyle}
        disablePast={disablePast}
        label={displayName}
        format="DD/MM/YYYY"
        
        value={value}
        onChange={props.handleDateChange(accessor)}
        InputProps={{
          disableUnderline: true,
          className: clsx(classes.fieldFont, props.dark && classes.darkBackground),
          style: textStyle,
        }}
        fullWidth
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </MuiPickersUtilsProvider>
  );
};

DateField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateField);
