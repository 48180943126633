import { directions } from '../models/constants';

export const getOrganisationShortNameFromId = (id, organisations) => {
  let shortName = 'Anon';
  const index = organisations.findIndex((c) => c.id === id);

  shortName = index !== -1 ? organisations[index].shortName : 'Anon';
  return shortName;
};

export const getOrganisationFullNameFromId = (id, organisations) => {
  let fullName = '';
  const index = organisations.findIndex((c) => c.id === id);

  fullName = index !== -1 ? organisations[index].name : 'Anon';
  return fullName;
};

export const getTotals = (orderRows, orderStatus) => {
  const underlying = 'underlying';

  let totalVolume = 0;

  let totalNominal = 0;
  let direction = directions.BUY;
  let unit = '';
  let currency = '';
  let isMyOrder = false;
  let isCompanyOrder = false;
  let tradable = true;
  let amendable = true;
  let status;
  let product = 'SALMON';
  let cartonNetWeight = null;

  const volumePerUnit = (unit, product, cartonNetWeight = null) => {
    let value = 1;
    if (unit === 'BOX') {
      if (product === 'SALMON') {
        value = 20;
      } else {
        value = 22.5;
      }
    }
    switch (cartonNetWeight) {
      case 'fifteen':
        value = 15;
        break;
      case 'twelveAndHalf':
      case 'tweleveAndHalf':
        value = 12.5;
        break;
      case 'twelve':
        value = 12;
        break;
      default:
        value = 1;
    }

    return value;
  };
  if (orderRows !== undefined) {
    if (orderRows.length !== 0) {
      orderRows.forEach((row) => {
        status = row.status
          ? row.status
          : orderStatus === null || orderStatus === undefined
          ? orderStatus
          : 'ACTIVE';
        unit = row.contract ? row.contract[underlying].unit : row.unit;
        currency = row.contract ? row.contract[underlying].currency : row.currency;
        cartonNetWeight = row.contract
          ? row.contract[underlying].cartonNetWeight
          : row.cartonNetWeight;
        direction = row.direction;
        const vol = Number(row.volume);
        const pri = Number(row.price);
        totalVolume += vol;
        product = row.contract
          ? row.contract[underlying].product
          : row.product
          ? row.product
          : 'SALMON';
        const nominal = vol * volumePerUnit(unit, product, cartonNetWeight) * pri;
        totalNominal += nominal;
        isMyOrder = row.isMyOrder;
        isCompanyOrder = row.isCompanyOrder;
        if (
          status === 'CANCELLED' ||
          status === 'SUSPENDED' ||
          status === 'FILLED' ||
          status === 'IN-PROGRESS' ||
          isCompanyOrder
        ) {
          tradable = false;
        }
        if (
          status === 'CANCELLED' ||
          status === 'FILLED' ||
          status === 'IN-PROGRESS' ||
          isMyOrder === false
        ) {
          amendable = false;
        }
      });
    }
  }
  const avgPrice = totalNominal / totalVolume / volumePerUnit(unit, product, cartonNetWeight) || 0;

  return {
    volume: totalVolume,
    avgPrice,
    nominal: totalNominal,
    direction,
    unit,
    currency,
    isMyOrder,
    isCompanyOrder,
    tradable,
    amendable,
    status,
  };
};

export const idGenerator = () => {
  return (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
};

export const getTradeTotals = (orderRows) => {
  console.log('orderRows', orderRows);
  const underlying = 'underlying';
  let totalVolume = 0;
  let totalNominal = 0;
  let direction = directions.BUY;
  let unit = '';
  let currency = '';
  let isMyOrder = false;
  let isCompanyOrder = false;
  let tradable = true;
  let amendable = true;
  let status;
  let product = 'SALMON';
  let cartonNetWeight = null;
  const volumePerUnit = (unit, product, cartonNetWeight) => {
    let value = 1;
    if (unit === 'BOX') {
      if (product === 'SALMON') {
        value = 20;
      } else {
        value = 22.5;
      }
    }
    switch (cartonNetWeight) {
      case 'fifteen':
        value = 15;
        break;
      case 'twelveAndHalf':
      case 'tweleveAndHalf':
        value = 12.5;
        break;
      case 'twelve':
        value = 12;
        break;
      default:
        value = 1;
    }
    return value;
  };
  if (orderRows !== undefined) {
    if (orderRows.length !== 0) {
      orderRows.forEach((r) => {
        const row = r.order ? r.order : r;

        status = row.status;
        unit = row.contract ? row.contract[underlying].unit : row.unit;
        cartonNetWeight = row.contract
          ? row.contract[underlying].cartonNetWeight
          : row.cartonNetWeight;
        currency = row.contract ? row.contract[underlying].currency : row.currency;
        direction = row.direction;
        const vol = Number(row.volume);
        const pri = Number(row.price);
        totalVolume += vol;
        product = row.contract
          ? row.contract[underlying].product
          : row.product
          ? row.product
          : 'SALMON';
        const nominal = vol * volumePerUnit(unit, product, cartonNetWeight) * pri;
        totalNominal += nominal;
        isMyOrder = row.isMyOrder;
        isCompanyOrder = row.isCompanyOrder;
        if (
          row.status === 'CANCELLED' ||
          row.status === 'SUSPENDED' ||
          row.status === 'FILLED' ||
          row.status === 'IN-PROGRESS' ||
          row.isCompanyOrder
        ) {
          tradable = false;
        }
        if (
          row.status === 'CANCELLED' ||
          row.status === 'FILLED' ||
          row.status === 'IN-PROGRESS' ||
          row.isMyOrder === false
        ) {
          amendable = false;
        }
      });
    }
  }
  const avgPrice = totalNominal / totalVolume / volumePerUnit(unit, product, cartonNetWeight) || 0;

  return {
    volume: totalVolume,
    avgPrice,
    nominal: totalNominal,
    direction,
    unit,
    currency,
    isMyOrder,
    isCompanyOrder,
    tradable,
    amendable,
    status,
  };
};
export function filterObject(obj, filter, filter2, filterValue) {
  const filtered = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key][filter][filter2] === filterValue) {
      filtered[key] = obj[key];
    }
  });
  return filtered;
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export function isBoolean(obj) {
  return Object.prototype.toString.call(obj) === '[object Boolean]';
}

export function existId(objectItems, id) {
  return Object.prototype.hasOwnProperty.call(objectItems, id);
}

export function getId(item) {
  let id = null;

  if (typeof item.id !== 'undefined' && item.id !== null) {
    id = item.id;
  } else if (
    Array.isArray(item.assets) &&
    item.assets.length === 1 &&
    isObject(item.assets[0]) &&
    typeof item.assets[0].id !== 'undefined' &&
    item.assets[0].id !== null
  ) {
    id = item.assets[0].id;
  }

  return id;
}

export function insertItems(
  { items },
  {
    byId = {},
    allIds = [],
    lastCreatedTime: createdTime = null,
    lastUpdatedTime: updatedTime = null,
  },
  idKey = 'id',
) {
  const insertedById = { ...byId };
  const insertedAllIds = [...allIds];

  let lastCreatedTime = createdTime;
  let lastUpdatedTime = updatedTime;

  items.forEach((item) => {
    item.id = item[idKey]; // TODO! REFACTOR! TEMP SOLUTION!

    const id = getId(item);

    if (id !== null) {
      if (!existId(byId, id)) {
        insertedAllIds.push(id);
      }

      insertedById[id] = item;

      if (
        item.createdTime &&
        (lastCreatedTime === null || new Date(lastCreatedTime) < new Date(item.createdTime))
      ) {
        lastCreatedTime = item.createdTime;
      }

      if (
        item.updatedTime &&
        (lastUpdatedTime === null || new Date(lastUpdatedTime) < new Date(item.updatedTime))
      ) {
        lastUpdatedTime = item.updatedTime;
      }
    }
  });

  return {
    byId: insertedById,
    allIds: insertedAllIds,
    lastCreatedTime,
    lastUpdatedTime,
  };
}

export function updateItems({ items }, { byId, allIds }, idKey = 'id') {
  const updatedById = { ...byId };
  const updatedAllIds = [...allIds];

  items.forEach((item) => {
    item.id = item[idKey]; // TODO! REFACTOR! TEMP SOLUTION!

    const id = getId(item);

    if (id !== null) {
      if (existId(byId, id)) {
        updatedById[id] = { ...byId[id], ...item };
      }
    }
  });

  return {
    byId: updatedById,
    allIds: updatedAllIds,
  };
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const createId = (length = 10) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
