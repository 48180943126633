import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Card,
  CardMedia,
  Box,
} from '@material-ui/core';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton } from '../../../commons/components/buttons';
import { tradingPartnersListOpen } from '../actions';
import { TRADING_PARTNERS_LIST_OPEN } from '../constants';
import { isTradingPartnersListOpen, getTradingPartners } from '../selectors';
import lobster from '../../../assets/images/lobster.jpeg';

import UserAvatar from '../../../commons/components/userAvatar';
import UserList from './UserList';

const styles = (theme) => ({
  container: { flex: 1, display: 'flex', flexDirection: 'column' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
  orgContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  companyHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {
    padding: '8px',
  },
  sectionHeader: {
    fontWeight: 'bold',
    color: theme.palette.text.subtitle,
  },
  sectionBody: {
    padding: '8px',
  },
  companyImage: {
    maxHeight: '200px',
    objectFit: 'cover',
  },
  chip: {
    margin: '2px',
  },
});

class TradingPartnersList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      org: null,
    };
  }

  handleCloseOrg = () => {
    this.setState({ org: null });
  };

  handleOpenOrg = (org) => {
    this.setState({ org });
  };

  render() {
    const { classes, tradingPartners } = this.props;
    const title = this.state.org ? this.state.org.name.toUpperCase() : 'TRADING PARTNERS';
    const containerWidth = this.state.org ? 940 : 640;
    return (
      <PopUp
        open={this.props.open}
        title={title}
        onClose={this.props.onClose}
        containerWidth={containerWidth}
      >
        <React.Fragment>
          {this.state.org !== null && (
            <div className={classes.orgContainer}>
              <div className={classes.section}>
                <Card sx={{ display: 'flex', flexDirection: 'row' }}>
                  <div className={classes.section} style={{ flex: 1 }}>
                    <div className={classes.sectionHeader}></div>
                    <div className={classes.sectionBody}>
                      <div className={classes.companyHeader}>
                        <UserAvatar organisationId={this.state.org.id} />
                        <div>{title}</div>
                      </div>
                    </div>
                  </div>

                  <CardMedia
                    component="img"
                    sx={{ height: 50, flex: 1 }}
                    image={lobster}
                    alt="Live from space album cover"
                  ></CardMedia>
                </Card>
              </div>

              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>Address</div>
                    <div className={classes.sectionBody}>
                      882 Back Line Road, Forest, Tasmania 7330
                    </div>
                  </div>
                </Card>
              </div>

              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>About Us</div>
                    <div className={classes.sectionBody}>
                      The Worlds #1 Lobster available from our company under our brand. Our unique
                      species (Jasus Edwardsii) are caught only in the cool waters of Southern
                      Australia and New Zealand.
                      <br></br> <br></br>
                      Limited numbers available globally and with high demand from discerning Asian
                      consumers who value above all else the purest tasting Sashimi Lobster in the
                      World.
                    </div>
                  </div>
                </Card>
              </div>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>Our Products</div>
                    <div className={classes.sectionBody}>
                      <Chip
                        key={'southernRockLobster'}
                        label={'southernRockLobster'}
                        className={classes.chip}
                      />
                      <Chip
                        key={'easternRockLobster'}
                        label={'easternRockLobster'}
                        className={classes.chip}
                      />
                      <Chip
                        key={'westernRockLobster'}
                        label={'westernRockLobster'}
                        className={classes.chip}
                      />
                    </div>
                  </div>
                </Card>
              </div>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>Our Team</div>
                    <div className={classes.sectionBody}>
                      <UserList />
                    </div>
                  </div>
                </Card>
              </div>

              <div className={classes.toolbarContainer}>
                <ToolbarBottom>
                  <>
                    <CancelButton title="Return" onClick={this.handleCloseOrg} />
                  </>
                </ToolbarBottom>
              </div>
            </div>
          )}
          {this.state.org === null && (
            <div className={classes.container}>
              <div className={classes.listContainer}>
                <List open classes={{ root: classes.list }}>
                  {tradingPartners.map((org) => (
                    <ListItem
                      key={org.id}
                      button
                      classes={{ root: classes.listItem }}
                      onClick={() => this.handleOpenOrg(org)}
                    >
                      <ListItemIcon>
                        <UserAvatar organisationId={org.id} />
                      </ListItemIcon>
                      <ListItemText primary={org.name} />
                      <ListItemText primary={org.shortName} align="right" />
                    </ListItem>
                  ))}
                </List>
              </div>
              <div className={classes.toolbarContainer}>
                <ToolbarBottom>
                  <>
                    <CancelButton onClick={this.props.onClose} />
                    {/* <ConfirmButton onClick={this.onSave} /> */}
                  </>
                </ToolbarBottom>
              </div>
            </div>
          )}
        </React.Fragment>
      </PopUp>
    );
  }
}
TradingPartnersList.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    open: isTradingPartnersListOpen(state),
    tradingPartners: getTradingPartners(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => {
      dispatch(tradingPartnersListOpen(TRADING_PARTNERS_LIST_OPEN, { open: false }));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(TradingPartnersList));
