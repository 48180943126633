import * as actionTypes from '../actionTypes';
import { createAction } from '../../../commons/actions';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { isObject } from '../../../commons/utils/functions';
import auth from '../../auth';
import workspaces from '../../workspaces';
import api from '../api';
import * as selectors from '../selectors';
import marketSegments from '../../contracts/models/marketSegments';
function* init(action) {
  try {
    const {
      payload: { id, widget },
    } = action;

    const payload = {
      id,
    };

    const meta = { receivedAt: new Date() };

    let product = 'spinyLobster';
    let species = 'southernRock';
    let deliveryCountry = 'ANY';

    if (isObject(widget)) {
      payload.widget = widget;
      if (
        Object.prototype.hasOwnProperty.call(widget, 'savedState') &&
        Object.prototype.hasOwnProperty.call(widget.savedState, 'product')
      ) {
        product = widget.savedState.product;
      }
      if (
        Object.prototype.hasOwnProperty.call(widget, 'savedState') &&
        Object.prototype.hasOwnProperty.call(widget.savedState, 'species')
      ) {
        species = widget.savedState.species;
      }
      if (
        Object.prototype.hasOwnProperty.call(widget, 'savedState') &&
        Object.prototype.hasOwnProperty.call(widget.savedState, 'deliveryCountry')
      ) {
        deliveryCountry = widget.savedState.deliveryCountry;
      }
    }
    const token = yield call(auth.selectors.getToken);
    const data = {
      token,
      params: {},
    };
    payload.species = species;
    payload.product = product;
    payload.deliveryCountry = deliveryCountry;

    const orders = yield call(api.get, 'ordersVolume', data);
    const trades = yield call(api.get, 'tradesVolume', data);
    const stats = getStats(orders, trades, species, deliveryCountry);
    payload.orders = orders;
    payload.trades = trades;
    payload.stats = stats;

    yield put(createAction(actionTypes.INIT_STATISTICS_SUCCESS, payload, meta));
  } catch (error) {
    yield put(
      createAction(actionTypes.INIT_STATISTICS_ERROR, { error }, { receivedAt: new Date() }),
    );
  }
}

function* destroyStatistics(action) {
  try {
    const payload = {
      id: action.payload.id,
    };

    const meta = { receivedAt: new Date() };

    yield put(createAction(actionTypes.DESTROY_STATISTICS_SUCCESS, payload, meta));
  } catch (error) {
    yield put(
      createAction(actionTypes.DESTROY_STATISTICS_ERROR, { error }, { receivedAt: new Date() }),
    );
  }
}

function* update(action) {
  try {
    const {
      payload: { id, action: actionStats, name, value, dates },
    } = action;

    let payload = {
      id,
    };

    const meta = { receivedAt: new Date() };
    const item = yield select(selectors.getLocalStatisticsById, id);

    const { widget } = item;
    payload.widget = widget;
    if (actionStats === 'update') {
      if (isObject(widget)) {
        const newWidget = {
          ...widget,
          savedState: {
            ...widget.savedState,
            [name]: value,
          },
        };
        console.log('newWidget', newWidget);

        yield put(
          workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: newWidget }),
        );
        payload.widget = newWidget;
      } else {
        console.log('widget is not object', widget);
      }
    }
    let product = 'spinyLobster';
    let species = 'southernRock';
    let deliveryCountry = 'ANY';

    if (
      Object.prototype.hasOwnProperty.call(payload.widget, 'savedState') &&
      Object.prototype.hasOwnProperty.call(payload.widget.savedState, 'product')
    ) {
      product = payload.widget.savedState.product;
    }
    if (
      Object.prototype.hasOwnProperty.call(payload.widget, 'savedState') &&
      Object.prototype.hasOwnProperty.call(payload.widget.savedState, 'species')
    ) {
      species = payload.widget.savedState.species;
    }
    if (
      Object.prototype.hasOwnProperty.call(payload.widget, 'savedState') &&
      Object.prototype.hasOwnProperty.call(payload.widget.savedState, 'deliveryCountry')
    ) {
      deliveryCountry = payload.widget.savedState.deliveryCountry;
    }

    const token = yield call(auth.selectors.getToken);
    const data = {
      token,
      params: {},
    };
    payload.species = species;
    payload.product = product;
    payload.deliveryCountry = deliveryCountry;

    data.params.createdDateFrom = dates.createdDateFrom;
    data.params.createdDateTo = dates.createdDateTo;

    const orders = yield call(api.get, 'ordersVolume', data);
    const trades = yield call(api.get, 'tradesVolume', data);
    const stats = getStats(orders, trades, species, deliveryCountry);
    payload.orders = orders;
    payload.trades = trades;
    payload.stats = stats;

    console.log('saga:payload', payload);

    yield put(createAction(actionTypes.UPDATE_STATISTICS_SUCCESS, payload, meta));
  } catch (error) {
    yield put(
      createAction(actionTypes.UPDATE_STATISTICS_ERROR, { error }, { receivedAt: new Date() }),
    );
  }
}

export function* watchInitStatistics() {
  yield takeEvery(actionTypes.INIT_STATISTICS, init);
}

export function* watchDestroyStatistics() {
  yield takeEvery(actionTypes.DESTROY_STATISTICS, destroyStatistics);
}

export function* watchUpdateStatistics() {
  yield takeEvery(actionTypes.UPDATE_STATISTICS, update);
}

function getStats(orders, trades, species, deliveryCountry = 'ANY') {
  const marketSegment = Object.keys(marketSegments).find(
    (item) => marketSegments[item].species === species,
  );
  const weightClasses = Object.keys(marketSegments[marketSegment].enums.weightClass) || [];
  const stats = [];
  weightClasses.forEach((wgt) => {
    const filteredOrders = [...orders]
      .filter(
        (o) =>
          o.direction === 'SELL' &&
          o.species === species &&
          o.weightClass === wgt &&
          o.status === 'ACTIVE',
      )
      .filter((o) => {
        if (deliveryCountry === 'ANY') {
          return true;
        } else {
          return deliveryCountry === o.deliveryCountry;
        }
      })
      .reduce(function (sum, value) {
        return sum + value.volume;
      }, 0);
    const filteredIndicative = [...orders]
      .filter(
        (o) =>
          o.direction === 'SELL' &&
          o.species === species &&
          o.weightClass === wgt &&
          o.status === 'SUSPENDED',
      )
      .filter((o) => {
        if (deliveryCountry === 'ANY') {
          return true;
        } else {
          return deliveryCountry === o.deliveryCountry;
        }
      })
      .reduce(function (sum, value) {
        return sum + value.volume;
      }, 0);
    const filteredTrades = trades
      .filter((t) => t.species === species && t.weightClass === wgt)
      .filter((o) => {
        if (deliveryCountry === 'ANY') {
          return true;
        } else {
          return deliveryCountry === o.deliveryCountry;
        }
      })
      .reduce(function (sum, value) {
        return sum + value.volume;
      }, 0);
    const obj = {
      name: wgt,
      Offers: filteredOrders ? filteredOrders : 0,
      Indicative: filteredIndicative ? filteredIndicative : 0,
      Trades: filteredTrades ? filteredTrades : 0,
    };
    stats.push(obj);
  });

  return stats;
}
