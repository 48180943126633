import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Icon, IconButton, Tooltip, Badge } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import { darken, fade } from '@material-ui/core/styles/colorManipulator';

import { directions, status } from '../../../commons/models/constants';
import { SaveButton, CancelButton } from '../../../commons/components/buttons';

const Toolbar = (props) => {
  const { totals, classes } = props;
  let containerStyle =
    props.totals.direction === directions.BUY
      ? `${classes.containerBuy} ${classes.container}`
      : `${classes.containerSell} ${classes.container}`;
  if (totals.status === status.SUSPENDED || totals.status === status.IN_PROGRESS) {
    containerStyle = `${classes.containerSuspended} ${classes.container}`;
  }
  if (totals.status === status.CANCELLED) {
    containerStyle = `${classes.containerCancelled} ${classes.container}`;
  }
  if (totals.status === status.FILLED || props.noColor) {
    containerStyle = `${classes.containerFilled} ${classes.container}`;
  }

  const active = props.rfqIsActive;

  return (
    <div className={classes.wrapper}>
      <div className={containerStyle}>
        <div className={classes.actionButtons}>
          <div className={classes.actionButtonContainer}>
            {totals.isCompanyOrder === false && totals.tradable === true && active === true && (
              <CancelButton
                title="TRADE"
                icon="input"
                onClick={() => props.trade(props.orderGroup)}
                style={{ padding: '2px', borderRadius: '0px', margin: '0px' }}
                iconStyle={{ marginRight: '4px' }}
              ></CancelButton>
              /* <Tooltip title="Trade">
                
                <IconButton onClick={() => props.trade(props.orderGroup)}>
                  <Icon>input</Icon>
                </IconButton>
              </Tooltip> */
            )}
          </div>
          <div className={classes.actionButtonContainer}>
            {active === true && totals.isCompanyOrder === false && (
              <SaveButton
                title="COUNTER"
                icon="note_add"
                onClick={() => props.counter(props.orderGroup)}
                style={{ padding: '2px', borderRadius: '0', margin: '0' }}
                iconStyle={{ marginRight: '4px' }}
              ></SaveButton>
              /*  <Tooltip title="Counter">
                <IconButton onClick={() => props.counter(props.orderGroup)}>
                  <Icon>note_add</Icon>
                </IconButton>
              </Tooltip> */
            )}
          </div>

          <div className={classes.actionButtonContainer}>
            {totals.amendable === true && active === true && (
              <Tooltip title="Amend">
                <IconButton onClick={() => props.amend(props.orderGroup)}>
                  <Icon>edit</Icon>
                </IconButton>
              </Tooltip>
            )}
          </div>

          <div className={classes.actionButtonContainer}>
            {props.hasChat && !props.interest && (
              <Tooltip title="Chat">
                <IconButton onClick={props.chat}>
                  <Badge badgeContent={props.numberOfMessages} color="secondary">
                    <Icon>chat_bubble</Icon>
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={classes.trafficLights}>
          {totals.isMyOrder &&
            totals.status !== status.CANCELLED &&
            totals.status !== status.FILLED && (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Tooltip title="Cancel" aria-label="Cancel">
                  <FiberManualRecordIcon style={{ color: red[500] }} onClick={props.massCancel} />
                </Tooltip>
                <Tooltip title="Suspend" aria-label="Suspend">
                  <FiberManualRecordIcon
                    style={{ color: amber[500] }}
                    onClick={props.massSuspend}
                  />
                </Tooltip>

                <Tooltip title="Activate" aria-label="Activate">
                  <FiberManualRecordIcon
                    style={{ color: teal['300'] }}
                    onClick={props.massActivate}
                  />
                </Tooltip>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  wrapper: { display: 'flex', flex: 1, height: 32 },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    border: '1px solid',
    borderBottomWidth: 0,
  },
  containerBuy: {
    backgroundColor: darken(lightBlue[500], 0.2),

    borderColor: lightBlue[300],
  },
  containerSell: {
    backgroundColor: darken(teal[500], 0.2),

    borderColor: teal[300],
  },
  containerSuspended: {
    backgroundColor: darken(amber[500], 0.2),

    borderColor: amber[300],
  },
  containerCancelled: {
    backgroundColor: darken(red[500], 0.2),

    borderColor: red[300],
  },
  containerFilled: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.divider,
  },

  actionButtons: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRight: '1px solid',
    borderRightColor: fade(theme.palette.text.primary, 0.25),
  },
  actionButtonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  trafficLights: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  trafficLightContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  trafficLight: {
    borderRadius: 10,
    height: 20,
    width: 20,
  },
  badgeStyle: {
    backgroundColor: theme.palette.secondary,
  },
});

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Toolbar);
