import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import amber from "@material-ui/core/colors/amber";
import lightBlue from '@material-ui/core/colors/lightBlue';
import {
  TextField,
  FormControl,
  InputAdornment,
  Button,
  ButtonGroup,
  Icon,
} from '@material-ui/core';

import { textField } from './formFieldStyles';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const styles = (theme) => ({
  textField: textField(theme),
  inputTextHighlight: {
    color: lightBlue[300],
  },
  input: {
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  adornment: {
    fontSize: theme.fontSize.xl,
  },
  button: {
    borderWidth: 0,
  },
});

const NumberFieldFilled = (props) => {
  const { classes, accessor, displayName, value, error } = props;
  const [showPad, setShowPad] = useState(false);
  return (
    <FormControl className={classes.textField} fullWidth={props.fullWidth} variant="filled">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          id={accessor}
          type="number"
          label={displayName}
          variant="filled"
          fullWidth
          //style={props.width !== null ? { width: props.width ? props.width : '250px' } : {}}
          onClick={() => setShowPad(!showPad)}
          value={value || ''}
          onChange={props.handleChange(accessor)}
          error={props.error ? error.value : false}
          helperText={props.error ? error.message : ''}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disableUnderline: true,
            className: classes.input,
            inputProps: {
              step: props.step ? props.step : 1,
            },
            endAdornment: (
              <InputAdornment position="end" classes={{ root: classes.adornment }}>
                {props.adornment ? props.adornment : ' '}
              </InputAdornment>
            ),
          }}
        />
        <ButtonGroup orientation="vertical">
          <Button className={classes.button}>
            <ArrowDropUpIcon />
          </Button>
          <Button className={classes.button}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </div>

      {showPad && (
        <div>
          <div>
            <Button>+</Button>
            <ButtonGroup aria-label="outlined primary button group">
              <Button>1</Button>
              <Button>5</Button>
              <Button>10</Button>
              <Button>50</Button>
              <Button>100</Button>
              <Button>500</Button>
            </ButtonGroup>
          </div>
          <div>
            <Button>-</Button>
            <ButtonGroup aria-label="outlined primary button group">
              <Button>1</Button>
              <Button>5</Button>
              <Button>10</Button>
              <Button>50</Button>
              <Button>100</Button>
              <Button>500</Button>
            </ButtonGroup>
          </div>
        </div>
      )}
    </FormControl>
  );
};

NumberFieldFilled.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NumberFieldFilled);
