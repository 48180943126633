import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import clsx from 'clsx';
const styles = (theme) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    maxHeight: '64px',
    minHeight: '64px',
    backgroundColor: theme.palette.primary.dark,
    // backgroundColor: "rgba(0,0,0,.15)"
  },
  alt: {
    backgroundColor: theme.palette.background.paper,
  },
});

const ToolbarBottom = (props) => {
  const { classes } = props;
  return (
    <Toolbar className={clsx(classes.toolbar, props.backgroundColorAlt && classes.alt)}>
      {props.children}
    </Toolbar>
  );
};

ToolbarBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withStyles(styles)(ToolbarBottom);
