import {
  CHECK_AUTH,
  CHECK_AUTH_SUCCESS,
  CHECK_AUTH_ERROR,
  SIGN_IN,
  SIGN_IN_NEW_PASSWORD_REQUIRED,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_OUT_SUCCESS,
  BACK_TO_SIGN_IN,
  AUTH_UPDATE_DATA_SUCCESS,
  UPDATE_SETTINGS_SUCCESS,
} from '../constants';

import { isBoolean, isObject } from '../../../commons/utils/functions';

const initialState = {
  status: null,
  authed: null,
  aws: {
    user: null,
  },
  server: {
    user: null,
  },
  connected: false,
  error: null,
  receivedAt: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case SIGN_IN:
    case CHECK_AUTH:
      return {
        ...state,
        status: 'pending',
      };
    case SIGN_IN_SUCCESS:
    case CHECK_AUTH_SUCCESS:
      nextState = {
        ...state,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

      const { authed, aws, server } = action.payload;

      if (isBoolean(authed) && authed !== state.authed) {
        nextState.authed = authed;
      }

      if (isObject(aws) && isObject(aws.user)) {
        nextState.aws.user = aws.user;
      }

      if (isObject(server) && isObject(server.user)) {
        nextState.server.user = server.user;
      }

      return nextState;
    case SIGN_IN_ERROR:
    case CHECK_AUTH_ERROR:
      return {
        ...state,
        status: 'error',
        authed: false,
        aws: {
          user: null,
        },
        server: {
          user: null,
        },
        error: action.payload,
        receivedAt: action.meta.receivedAt,
      };
    case SIGN_IN_NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        status: SIGN_IN_NEW_PASSWORD_REQUIRED,
        aws: {
          user: action.payload.aws.user,
        },
        server: {
          user: null,
        },
      };
    case AUTH_UPDATE_DATA_SUCCESS:
      if (isBoolean(action.payload.connected) && action.payload.connected !== state.connected) {
        return {
          ...state,
          connected: action.payload.connected,
          receivedAt: action.meta.receivedAt,
        };
      }

      return state;
    case UPDATE_SETTINGS_SUCCESS:
      if (typeof action.payload.appSettings !== 'undefined') {
        return {
          ...state,
          server: {
            user: {
              ...state.server.user,
              appSettings: action.payload.appSettings,
            },
          },
          receivedAt: action.meta.receivedAt,
        };
      }

      return state;
    case SIGN_OUT_SUCCESS:
    case BACK_TO_SIGN_IN:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
