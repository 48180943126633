import * as actionTypes from '../actionTypes';

//import { insertItems, updateItems } from '../../../commons/utils/functions';
import contract from '../../contracts/models/orderUnderlying';
import auth from '../../auth';

const initialState = {
  byId: [],
  allIds: [],
  items: {},
  speciesValues: Object.keys(contract.fields.species.enum),
  productValues: Object.keys(contract.fields.product.enum),
  deliveryCountryValues: Object.keys(contract.fields.deliveryCountry.enum),
  orders: [],
  trades: [],
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case actionTypes.INIT_STATISTICS_SUCCESS:
    case actionTypes.UPDATE_STATISTICS_SUCCESS: 
    console.log("Stats reducer", action)
      nextState = {
        ...state,
      };

      const { id: idInitStatistics, ...otherNextStatisticsProps } = action.payload;

      if (typeof idInitStatistics !== 'undefined') {
   
          nextState.items = {
            ...nextState.items,
            [idInitStatistics]: {
              id: idInitStatistics,
              ...otherNextStatisticsProps,
            },
          };
     
      }
      console.log("State reducer next State", JSON.parse(JSON.stringify(nextState)))
      return nextState;
    case actionTypes.DESTROY_STATISTICS_SUCCESS:
      nextState = {
        ...state,
      };
      const { id: idDestroyStatistics } = action.payload;
      if (typeof idDestroyStatistics !== 'undefined') {
        if (nextState.items[idDestroyStatistics]) {
          nextState.items = {
            ...nextState.items,
          };
          delete nextState.items[idDestroyStatistics];
        }
      }
      return nextState;
   
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
