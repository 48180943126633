import { takeEvery, put, select, call, cancel, delay } from 'redux-saga/effects';

import { Auth } from 'aws-amplify';

import { removeAWSKeysFromLS, saveToLS } from '../../../commons/localStorage';

import HTTPError from '../../../commons/api/HTTPError';

import {
  CHECK_AUTH,
  CHECK_AUTH_SUCCESS,
  CHECK_AUTH_ERROR,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_IN_NEW_PASSWORD_REQUIRED,
  BACK_TO_SIGN_IN,
  SIGN_IN_NEW_PASSWORD_SENT,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  AUTH_UPDATE_DATA_SUCCESS,
  AUTH_UPDATE_DATA_ERROR,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_ERROR,
  UPDATE_SETTINGS,
} from '../constants';

import {
  checkAuth as actionCheckAuth,
  signIn as actionSignIn,
  signOut as actionSignOut,
  updateData as actionUpdateData,
  updateSettings as actionUpdateSettings,
} from '../actions';

import {
  getAWSUser,
  isAuthed,
  getRequiredAttributes,
  getServerUser,
  getToken,
  getAppSettings,
} from '../selectors';

import history from '../../../commons/router/history';

import api from '../api';

import {
  SOCKET_CONNECTED,
  SOCKET_RECONNECTING,
  SOCKET_DISCONNECTED,
} from '../../../modules/socket/actionTypes';

import { createAction } from '../../../commons/actions';
import { INIT_THEME } from '../../../modules/themes/actionTypes';

export function* checkError(error) {
  if (error instanceof HTTPError) {
    if (error.status === 401) {
      yield put(actionSignOut(SIGN_OUT));
      return true;
    }
  }

  return false;
}

async function getAWSAuthedUser() {
  return Auth.currentAuthenticatedUser();
}

function checkRedirect({ redirect }, authed) {
  if (redirect && redirect.url) {
    if (redirect.whenAuthed === authed) {
      history.push(redirect.url);
    }
  }
}

async function awsVerifiedContact(user) {
  return Auth.verifiedContact(user);
}

async function awsSignIn({ username, password }) {
  return Auth.signIn(username, password);
}

async function awsSignOut() {
  return Auth.signOut();
}

async function awsCompleteNewPassword(user, password, attrs) {
  return Auth.completeNewPassword(user, password, attrs);
}

function objectWithProperties(obj, keys) {
  const target = {};
  for (const key in obj) {
    if (keys.indexOf(key) === -1) {
      continue;
    }
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }
    target[key] = obj[key];
  }
  return target;
}

function* checkAuth(action) {
  try {
    const payload = {
      authed: null,
    };

    const meta = {
      receivedAt: new Date(),
    };

    const authed = yield select(isAuthed);

    payload.authed = authed;

    if (!payload.authed) {
      payload.aws = {
        user: null,
      };

      payload.server = {
        user: null,
      };

      const awsAuth = yield call(getAWSAuthedUser);

      if (awsAuth.signInUserSession) {
        const awsAccessToken = awsAuth.signInUserSession.accessToken.jwtToken || '';

        if (awsAccessToken) {
          let serverUser = yield select(getServerUser);

          if (!serverUser) {
            serverUser = yield call(api.authenticate, awsAccessToken);
          }

          // TODO: EXTRACT IN METHOD
          if (serverUser) {
            payload.authed = true;
            payload.aws.user = awsAuth;
            payload.server.user = serverUser;

            const appSettings = JSON.parse(serverUser.appSettings) || {};
            payload.server.user.appSettings = appSettings;

            saveAppSettingsToLS(appSettings);

            yield put(createAction(INIT_THEME));

            meta.receivedAt = new Date();
          }
        } else {
          payload.authed = false;
          meta.receivedAt = new Date();
        }
      } else {
        payload.authed = false;
      }
    }

    if (payload.authed) {
      yield put(actionCheckAuth(CHECK_AUTH_SUCCESS, payload, meta));
    } else {
      yield put(actionCheckAuth(CHECK_AUTH_ERROR, {}, meta));
    }

    checkRedirect(action.payload, payload.authed);
  } catch (error) {
    console.error(error);
    //const checkedError = yield call(checkError, error); // TODO: sync operation. For asyn use fork
    // if (!checkedError) {
    yield put(
      actionCheckAuth(CHECK_AUTH_ERROR, { message: error.message }, { receivedAt: new Date() }),
    );
    checkRedirect(action.payload, false);
    // }
  }
}

function* signIn(action) {
  try {
    const awsUser = yield call(awsSignIn, action.payload);
    // user.authenticationFlowType.USER_SRP_AUTH for reuired new passwrod response
    // user.signInUserSession = null for reuired new passwrod response

    const payload = {
      authed: false,
      aws: {
        user: awsUser,
      },
      server: {
        user: null,
      },
    };

    const meta = {
      receivedAt: null,
    };

    if (awsUser.signInUserSession) {
      const awsAccessToken = awsUser.signInUserSession.accessToken.jwtToken || '';

      if (awsAccessToken) {
        const serverUser = yield call(api.authenticate, awsAccessToken);

        // TODO: EXTRACT IN METHOD
        if (serverUser) {
          const appSettings = JSON.parse(serverUser.appSettings) || {};

          payload.server.user = serverUser;
          payload.server.user.appSettings = appSettings;

          saveAppSettingsToLS(appSettings);

          yield put(createAction(INIT_THEME));

          payload.authed = true;

          meta.receivedAt = new Date();

          yield put(actionSignIn(SIGN_IN_SUCCESS, payload, meta));
        }
      } else {
        yield put(
          actionSignIn(
            SIGN_IN_ERROR,
            { message: 'user or password incorrect' },
            { receivedAt: new Date() },
          ),
        );
      }

      checkRedirect(action.payload, payload.authed);
    } else if (
      awsUser.challengeName === 'SMS_MFA' ||
      awsUser.challengeName === 'SOFTWARE_TOKEN_MFA'
    ) {
      yield put(actionSignIn('confirmSignIn', payload, meta));
    } else if (awsUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put(actionSignIn(SIGN_IN_NEW_PASSWORD_REQUIRED, payload, meta));
    } else if (awsUser.challengeName === 'MFA_SETUP') {
      yield put(actionSignIn('TOTPSetup', payload, meta));
    } else if (
      awsUser.challengeName === 'CUSTOM_CHALLENGE' &&
      awsUser.challengeParam &&
      awsUser.challengeParam.trigger === 'true'
    ) {
      yield put(actionSignIn('customConfirmSignIn', payload, meta));
    } else {
      const data = yield call(awsVerifiedContact, awsUser);

      if (!data.verified) {
        yield put(actionSignIn(SIGN_IN_ERROR, { message: 'not verified' }, meta));
      } else {
        payload.aws.user = Object.assign(awsUser, data);
        yield put(actionSignIn('verifyContact', payload, meta));
      }
    }
  } catch (error) {
    console.error(error);
    if (error.code === 'UserNotConfirmedException') {
      yield put(actionSignIn('confirmSignUp', {}, {})); // { username }
    } else if (error.code === 'PasswordResetRequiredException') {
      yield put(actionSignIn('PasswordResetRequiredException', {}, {})); // { username }
    } else {
      yield put(actionSignIn(SIGN_IN_ERROR, { error: error.message }, {})); // { error: message, showToast }
      checkRedirect(action.payload, false);
    }
  }
}

function* backTosignIn() {
  yield call(awsSignOut);
}

function* singInNewPasswordSent(action) {
  const paramUser = yield select(getAWSUser);

  const { password } = action.payload;
  const attrs = yield select(getRequiredAttributes);

  const awsUser = yield call(
    awsCompleteNewPassword,
    paramUser,
    password,
    objectWithProperties(action.payload, attrs),
  );

  const payload = {
    aws: {
      user: awsUser,
    },
    server: {
      user: null,
    },
  };

  const meta = {
    receivedAt: null,
  };

  if (awsUser.challengeName === 'SMS_MFA') {
    yield put(actionSignIn('confirmSignIn', payload, meta));
  } else if (awsUser.challengeName === 'MFA_SETUP') {
    yield put(actionSignIn('TOTPSetup', payload, meta));
  } else {
    const data = yield call(awsVerifiedContact, awsUser);

    if (!data.verified) {
      yield put(actionSignIn(SIGN_IN_ERROR, { message: 'not verified' }, meta));
    } else {
      payload.aws.user = Object.assign(awsUser, data);
      yield put(actionSignIn(SIGN_IN_SUCCESS, payload, meta));
    }
  }
}

export function* signOut(redirect, tasks) {
  try {
    yield call(saveSettings);

    yield call(awsSignOut);

    removeAWSKeysFromLS();

    yield put(actionSignOut(SIGN_OUT_SUCCESS));

    if (Array.isArray(tasks)) {
      yield cancel(tasks);
    }

    checkRedirect({ redirect }, false);
  } catch (error) {
    console.error(error);
  }
}

export function* watchCheckAuth() {
  yield takeEvery(CHECK_AUTH, checkAuth);
}

export function* watchSignIn() {
  yield takeEvery(SIGN_IN, signIn);
}

export function* watchBackToSignIn() {
  yield takeEvery(BACK_TO_SIGN_IN, backTosignIn);
}

export function* watchSignInNewPasswordSent() {
  yield takeEvery(SIGN_IN_NEW_PASSWORD_SENT, singInNewPasswordSent);
}

export function* watchSignOut(redirect, tasks) {
  yield takeEvery(SIGN_OUT, signOut, redirect, tasks);
}

/**
 *
 * @param {*} action
 */
function* socketConnected() {
  try {
    const payload = {
      connected: true,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionUpdateData(AUTH_UPDATE_DATA_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateData(AUTH_UPDATE_DATA_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* socketReconnecting() {
  try {
    const payload = {
      connected: false,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionUpdateData(AUTH_UPDATE_DATA_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateData(AUTH_UPDATE_DATA_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* socketDisconnected() {
  try {
    const payload = {
      connected: false,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionUpdateData(AUTH_UPDATE_DATA_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateData(AUTH_UPDATE_DATA_ERROR, { error }));
  }
}
export function* watchSocketConnected() {
  yield takeEvery(SOCKET_CONNECTED, socketConnected);
}

export function* watchSocketReconnecting() {
  yield takeEvery(SOCKET_RECONNECTING, socketReconnecting);
}

export function* watchSocketDisconnected() {
  yield takeEvery(SOCKET_DISCONNECTED, socketDisconnected);
}

/**
 *
 * @param {*} action
 */
// TODO: ADD ONE SCHEMA FOR ORDER
function* updateSettings() {
  try {
    const payload = {};

    const meta = {
      receivedAt: new Date(),
    };

    const serverUser = yield call(saveSettings);
    payload.appSettings = JSON.parse(serverUser.appSettings);
    // navigator.sendBeacon();
    yield put(actionUpdateSettings(UPDATE_SETTINGS_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(actionUpdateSettings(UPDATE_SETTINGS_ERROR, { error }));
    }
  }
}

function* saveSettings() {
  try {
    const token = yield call(getToken);

    const appSettings = JSON.stringify(yield select(getAppSettings));

    const settings = {
      appSettings,
    };

    const serverUser = yield call(api.update, { token, body: settings });

    return serverUser;
  } catch (e) {
    console.log(e);
  }
}

function saveAppSettingsToLS(appSettings) {
  Object.keys(appSettings).forEach((key) => {
    saveToLS(key, appSettings[key]);
  });
}

export function* watchUpdateSettings() {
  yield takeEvery(UPDATE_SETTINGS, updateSettings);
}

// TODO: REFACTOR. TEMP SOLUTION
function* autoUpdateSettings(time) {
  if (time) {
    yield delay(time);

    yield call(saveSettings);

    yield call(runAutoUpdateSettings, time);
  }
}

// TODO: REFACTOR. USE SOCKET CONNECT AND RECONNECT
export function* runAutoUpdateSettings(time) {
  try {
    yield call(autoUpdateSettings, time);
  } catch (e) {
    console.log(e);
    yield call(autoUpdateSettings, time);
  }
}
