import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OrganisationProfile from './OrganisationProfile';
import SelectField from '../../../commons/components/formFields/SelectField';
import { Icon, TextField, Paper, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultipleSelectField from '../../../commons/components/formFields/multipleSelectField';
import MultipleSelectFieldFilled from '../../../commons/components/formFields/multipleSelectFieldFilled';
const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  listItem: {
    flex: 1,
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(),
  },
  icon: {
    padding: theme.spacing(),
  },
});

class Organisations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      inputValue: '',
      type: null,
      species: [],
    };
  }

  getOrganisations = (organisations) => {
    if (this.state.value !== null) {
      return [this.state.value];
    } else {
      const newOrgs = [...organisations]
        .filter((o) => {
          if (this.state.type !== null) {
            return o.type === this.state.type;
          } else {
            return true;
          }
        })
        .filter((o) => {
          return o.name.toUpperCase().includes(this.state.inputValue.toUpperCase());
        })
        .filter((o) => {
          if (this.state.species.length > 0) {
            const array1 = [...this.state.species];
            const array2 = o.details ? (o.details.species ? o.details.species : []) : [];
            const filteredArray = array1.filter((value) => array2.includes(value));
            return filteredArray.length > 0;
          } else {
            return true;
          }
        });
      return newOrgs;
    }
  };

  render() {
    const {
      organisations,
      organisationsObject,
      isSuperAdmin,
      isOrganisationAdmin,
      myOrganisation,
      users,
      classes,
    } = this.props;
    const filteredOrgs = this.getOrganisations(organisations);

    const CustomPaper = (props) => {
      return <Paper elevation={8} {...props} />;
    };
    return (
      <React.Fragment>
        <div className={classes.filterRow}>
          <div className={classes.icon}>
            <Icon>filter_list</Icon>
          </div>
          <Autocomplete
            id="combo-box-demo"
            options={organisations}
            getOptionLabel={(option) => option.name}
            style={{ width: 256, margin: '2px' }}
            PaperComponent={CustomPaper}
            renderInput={(params) => (
              <TextField {...params} label="Search Organisation" variant="filled" size="small" />
            )}
            onChange={(e, value) => this.setState({ value: value })}
            onInputChange={(e, value) => this.setState({ inputValue: value })}
          />
          <Autocomplete
            id="combo-box-type"
            options={['BUYER', 'SELLER', 'AGENT']}
            getOptionLabel={(option) => option}
            style={{ width: 200, margin: '2px' }}
            PaperComponent={CustomPaper}
            renderInput={(params) => (
              <TextField {...params} label="Type" variant="filled" size="small" />
            )}
            onChange={(e, value) => this.setState({ type: value })}
          />
          {/*  <SelectField
            accessor="type"
            displayName="Type"
            value={this.state.type || ''}
            values={['BUYER', 'SELLER', 'AGENT']}
            addUnderline
            handleChange={(name) => (e) => {
              this.setState({ [name]: e.target.value });
            }}
            width={200}
          /> */}

          <MultipleSelectFieldFilled
            accessor="species"
            displayName="Species"
            addUnderline
            value={this.state.species}
            values={this.props.species || []}
            handleChange={(name) => (e) => {
              this.setState({ [name]: e.target.value });
            }}
            width={200}
          />
        </div>
        {filteredOrgs.map((org, index) => {
          return (
            <OrganisationProfile
              organisation={org}
              organisationsObject={organisationsObject}
              editOrganisation={this.props.editOrganisation}
              key={org ? org.id : index}
              isSuperAdmin={isSuperAdmin}
              isOrganisationAdmin={isOrganisationAdmin}
              users={users}
              myOrganisation={myOrganisation}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

Organisations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Organisations);
