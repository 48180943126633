import React from 'react';

import { Button, Icon, Badge } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import lightBlue from '@material-ui/core/colors/lightBlue';
import amber from '@material-ui/core/colors/amber';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { ThemeConsumer } from 'styled-components';

const styles = {
  button: {
    border: '1px solid',
    padding: '4px 8px',
    margin: '4px',
    minWidth: '132px',
    color: '#eee',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '.8em',
  },

  confirm: {
    //color: teal[100],
    backgroundColor: teal[400],
    borderColor: teal[400],
    '&:hover': {
      backgroundColor: teal[200],
    },
  },
  cancel: {
    //color: red[100],
    backgroundColor: red[400],
    borderColor: red[400],
    '&:hover': {
      backgroundColor: red[200],
    },
  },
  reset: {
    //color: lightBlue[100],
    backgroundColor: lightBlue[400],
    borderColor: lightBlue[400],
    '&:hover': {
      backgroundColor: lightBlue[200],
    },
  },
  save: {
    //color: amber[100],
    backgroundColor: '#c96001',
    borderColor: '#c96001',
    '&:hover': {
      backgroundColor: amber[200],
    },
  },

  clear: {
    //color: blueGrey[300],
    backgroundColor: 'transparent',
    borderColor: blueGrey[500],
    '&:hover': {
      backgroundColor: 'transparent',
    },
    borderColor: 'rgba(255,255,255,.1)',
  },
  disabled: {
    borderColor: 'rgba(255,255,255,.1)',
  },
};

export const ConfirmButton = (props) => {
  return (
    <Button
      style={
        props.disabled
          ? { ...styles.button, ...styles.disabled, ...props.style }
          : { ...styles.button, ...styles.confirm, ...props.style }
      }
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.title ? props.title : 'SEND'}
      <Icon style={{ ...styles.iconConfirm, ...props.iconStyle }}>
        {props.icon ? props.icon : 'check'}
      </Icon>
    </Button>
  );
};

export const CancelButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.cancel, ...props.style }} onClick={props.onClick}>
      {props.title ? props.title : 'CANCEL'}
      <Icon style={{ ...styles.iconCancel, ...props.iconStyle }}>
        {props.icon ? props.icon : 'cancel'}
      </Icon>
    </Button>
  );
};

export const ResetButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.reset, ...props.style }} onClick={props.onClick}>
      {props.title ? props.title : 'RESET'}
      <Icon style={{ ...styles.iconReset, ...props.iconStyle }}>
        {props.icon ? props.icon : 'replay'}
      </Icon>
    </Button>
  );
};

export const SaveButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.save, ...props.style }} onClick={props.onClick}>
      {props.title ? props.title : 'SAVE'}
      <Icon style={{ ...styles.iconSave, ...props.iconStyle }}>
        {props.icon ? props.icon : 'save'}
      </Icon>
    </Button>
  );
};

export const ClearButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.clear, ...props.style }} onClick={props.onClick}>
      {props.title ? props.title : 'PRESET'}
      <Badge badgeContent={props.badgeContent} color="secondary">
        <Icon style={{ ...styles.iconClear, ...props.iconStyle }}>
          {props.icon ? props.icon : 'archive'}
        </Icon>
      </Badge>
    </Button>
  );
};
