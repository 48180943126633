import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { Modal, Typography } from '@material-ui/core';
import { checkPrice, checkVolume } from '../../../../commons/config/formatters';
import AmendFormRow from './AmendFormRow';
import TotalsRow from '../TotalsRow';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';
import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';
import {
  isOpenFormAmend,
  getRfqAmendForm,
  getOrdersAmendForm,
  getAmendFormFields,
} from '../../selectors';

import { updateForm } from '../../actions';
import { UPDATE_RESPONSE_FORM } from '../../constants';

import orders from '../../../orders';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    //width: '98vw',
    //height: '95vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.palette.background.inner,
  },
  section: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.row,
  },
  sectionFlex: {
    marginTop: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    overflowY: 'scroll',
  },

  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(1),
    color: theme.palette.text.disabled,
  },
  title: { fontSize: theme.fontSize * 1.5 },
});

// TODO: Extract all functionality to saga ans store. REFACTOR!!!! CRITICAL!!!!!
class AmendForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orders: null,
    };
  }

  getErrors = () => {
    let error = false;
    const orders = this.state.orders || this.props.orders;
    orders.forEach((order) => {
      if (!checkPrice(order.price)) {
        error = true;
      }
      if (!checkVolume(order.volume)) {
        error = true;
      }
    });
    return error;
  };

  updateOrderRow = (payload) => {
    //this.props.updateResponseForm(payload);
    const existingOrders = this.state.orders || this.props.orders;
    const orders = [...existingOrders];
    /*  const payload = {
      type: 'amend',
      action: 'updateOrderRow',
      key: this.props.orderRow.key,
      field: name,
      value: event.target.value,
    }; */
    orders.forEach((order) => {
      if (payload.key === order.key) {
        order[payload.field] = payload.value;
      }
    });
    this.setState({ orders });
  };

  sendOrder = () => {
    let hasRowErrors = this.getErrors();

    if (!hasRowErrors) {
      this.setState({ orders: null });
      this.props.amendOrder();
      this.closeForm();
    }
  };

  closeForm = () => {
    this.setState({ orders: null });
    const payload = {
      type: 'amend',
      action: 'closeForm',
    };
    this.props.updateResponseForm(payload);
  };

  render() {
    const { classes, open, formFields } = this.props;
    const orders = this.state.orders || this.props.orders || [];
    const order = orders[0] || {};
    const direction = order.direction;
    const directions = [direction];
    return (
      <Modal open={open} onClose={this.closeForm}>
        <div style={getModalStyle()} className={classes.paper}>
          <Typography className={classes.title}>AMEND ORDER</Typography>
          <div className={classes.container}>
            {/*  <div className={classes.section}>
                <Typography className={classes.banner}>ORDER TYPE</Typography>
                <div className={classes.subsection}>
                  <ReadOnlyField displayName={'Direction'} value={direction} fullWidth />
                </div>
              </div> */}

            <ReadOnlyField displayName={'Direction'} value={direction} fullWidth />
            <div className={classes.sectionFlex}>
              {/* <Typography className={classes.banner}>{`ORDER (${orders.length} items)`}</Typography> */}
              <div className={classes.orders}>
                {orders.map((row, index) => (
                  <AmendFormRow
                    key={row.key}
                    orderRow={row}
                    updateOrderRow={this.updateOrderRow}
                    index={index}
                    formFields={formFields}
                  />
                ))}
              </div>
              <div className={classes.subsection}>
                <TotalsRow rows={orders} direction={directions[0]} />
              </div>
            </div>
          </div>

          <ToolbarBottom>
            <>
              <CancelButton onClick={this.closeForm} />
              <ConfirmButton onClick={this.sendOrder} />
            </>
          </ToolbarBottom>
        </div>
      </Modal>
    );
  }
}

AmendForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    orders: getOrdersAmendForm(state),
    rfq: getRfqAmendForm(state),
    formFields: getAmendFormFields(state),
    open: isOpenFormAmend(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateResponseForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
    amendOrder: (payload) => {
      dispatch(orders.actions.updateOrders(orders.constants.SEND_ORDER_AMENDMENT, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AmendForm));
