import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import FxRow from './FxRow';
import { getFxRates } from '../selectors';
import { INIT_FXRATE, DESTROY_FXRATE } from '../constants';
import { destroyFxrate, initFxrate } from '../actions';
import { getFromLS, saveToLS } from '../../../commons/localStorage';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  ratesContainer: {
    overflowY: 'scroll',
    flex: 1,
  },
  checkRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.primary.row,
    padding: theme.spacing(),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  toolRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  currencyName: {
    padding: theme.spacing(),
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  currencyRate: {
    padding: theme.spacing(),
    color: teal[300],
    fontSize: '16px',
  },
});

class FxRates extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      showAll: true,
      sortOrder: [],
    };
  }
  getSavedSettings = () => {
    const saved = getFromLS('SelectedFxRates');

    if (saved) {
      const selected = saved.selected || [];
      const showAll = saved.showAll || true;
      const sortOrder = saved.sortOrder || [];
      this.setState({ selected, showAll, sortOrder });
    }
  };
  componentDidMount() {
    const { id, widget } = this.props;

    const payload = {
      id,
      widget,
    };

    this.props.initFxrate(payload);
    this.getSavedSettings();
  }

  componentWillUnmount() {
    const payload = {
      id: this.props.id,
    };
    this.props.destroyFxrate(payload);
  }
  componentDidUpdate(prevProps) {
    const nextFxrates = Object.keys(this.props.fxrates);

    if (this.state.sortOrder.length !== nextFxrates.length) {
      const { sortOrder } = this.state;
      nextFxrates.forEach((rate) => {
        if (!sortOrder.includes(rate)) {
          sortOrder.push(rate);
        }
      });
      this.setState({ sortOrder });
    }
  }

  handleChecked = (currency) => {
    let selected = [];
    if (this.state.selected.includes(currency)) {
      selected = [...this.state.selected].filter((s) => s !== currency);
    } else {
      selected = [...this.state.selected];
      selected.push(currency);
    }
    this.setState({ selected });
    saveToLS('SelectedFxRates', { ...this.state, selected });
  };
  handleSwitch = (event) => {
    const showAll = event.target.checked;
    this.setState({ showAll });
    saveToLS('SelectedFxRates', { ...this.state, showAll });
  };

  handleDragEnd = (e) => {
    const index = e.destination.index;
    let sortOrder = [];
    let currency = e.draggableId;

    this.state.sortOrder.forEach((item) => {
      if (item !== currency) sortOrder.push(item);
    });

    sortOrder.splice(index, 0, currency);
    this.setState({ sortOrder });
    saveToLS('SelectedFxRates', { ...this.state, sortOrder });
  };
  render() {
    const { classes, fxrates } = this.props;
    const { sortOrder } = this.state;

    return (
      <div className={` ${classes.container} undraggable`}>
        <div className={classes.ratesContainer}>
          <DragDropContext onDragEnd={(e) => this.handleDragEnd(e)}>
            <Droppable droppableId={'FXRATES'}>
              {(provided) => {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {sortOrder.map((item, index) => {
                      const selected = this.state.selected.includes(item);
                      const rate = fxrates[item] || 0;
                      return this.state.showAll || selected ? (
                        <Draggable key={item} index={index} draggableId={item}>
                          {(provided) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <FxRow
                                  item={{ currency: item, rate: rate, checked: selected }}
                                  handleChecked={this.handleChecked}
                                ></FxRow>
                              </div>
                            );
                          }}
                        </Draggable>
                      ) : null;
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>
        <div className={classes.toolRow}>
          <div style={{ padding: '0px 4px' }}>Show All</div>
          <Switch checked={this.state.showAll} onChange={this.handleSwitch}></Switch>
        </div>
      </div>
    );
  }
}

FxRates.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id, widgetId, widget } = props;
  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    fxrates: getFxRates(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initFxrate: (payload) => {
      dispatch(initFxrate(INIT_FXRATE, payload));
    },
    destroyFxrate: (payload) => {
      dispatch(destroyFxrate(DESTROY_FXRATE, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FxRates));
