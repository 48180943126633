import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppMenu from '../../commons/components/AppMenu';

import GridLayout from './GridLayout';
import { createAction } from '../../commons/actions';
import { INIT_PROCESS } from '../../commons/constants';

import users from '../../modules/users';
import organisations from '../../modules/organisations';
import orders from '../../modules/orders';
import contracts from '../../modules/contracts';
import market from '../../modules/market';
import workspaces from '../../modules/workspaces';
import calculators from '../../modules/calculators';
import rfqs from '../../modules/rfqs';
import fxrates from '../../modules/fxrates';
import trades from '../../modules/trades';
import admin from '../../modules/admin';
import auth from '../../modules/auth';
import schemas from '../../modules/schemas';
import logos from '../../modules/logos';

const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    height: '100%',
    width: '100%',
    marginLeft: '55px',
    marginTop: '83px',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '12px',
      height: '12px',
      backgroundColor: 'transparent',
    },

    '*::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.scrollbar,
      borderRadius: '20px',
    },
    '*::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#30a9a4',
      borderRadius: '20px',
    },
    '*::-webkit-scrollbar-corner': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class ClientPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      notification: '',
      notificationVisible: false,
      notificationError: false,
      orderVisible: false,
      rfqVisible: false,
      rfqOrder: {},
      currentOrder: {},
      matchIds: [],
      hasError: false,
      initReady: false,
      workspacesReady: false,
      marketReady: false,
      contractReady: false,
      calculatorsReady: false,
      usersReady: false,
      organisationsReady: false,
    };
  }

  componentDidMount() {
    // TODO: Temporary solution. Extract to saga and invoke makrotasks(create method for generate settimeout operation)

    const {
      init,
      initWorkspace,
      initOrdersSettings,
      initCalculatorsSettings,
      getContract,
      getMarket,
      getUsers,
      getOrganisations,
      getMyOrganisation,
      getOrders,
      getRFQs,
      getFXRates,
      getTrades,
      getAdmins,
      initSchemas,
      getLogos,
    } = this.props;

    setTimeout(() => {
      init();
      this.setState({ initReady: true });
    }, 0);

    setTimeout(() => {
      initWorkspace();
      this.setState({ workspacesReady: true });
    }, 0);

    setTimeout(() => {
      getContract();
      this.setState({ contractReady: true });
    }, 0);

    setTimeout(() => {
      getMarket();
      this.setState({ marketReady: true });
    }, 0);

    setTimeout(() => {
      initOrdersSettings();
    }, 0);

    setTimeout(() => {
      initCalculatorsSettings();
      this.setState({ calculatorsReady: true });
    }, 0);

    setTimeout(() => {
      getFXRates();
    }, 0);

    setTimeout(() => {
      getUsers();
      this.setState({ usersReady: true });
    }, 0);

    setTimeout(() => {
      getOrganisations();
      this.setState({ organisationsReady: true });
    }, 0);
    setTimeout(() => {
      getMyOrganisation();
    }, 0);

    setTimeout(() => {
      getOrders();
    }, 0);

    setTimeout(() => {
      getRFQs();
    }, 0);
    setTimeout(() => {
      getTrades();
    }, 0);
    setTimeout(() => {
      initSchemas();
    }, 0);
    setTimeout(() => {
      getLogos();
    }, 0);
  }

  resetMatchIds = () => {
    this.setState({ matchIds: [] }, () => this.setState({ orderVisible: true }));
  };

  onCurrentOrderChange = (currentOrder, matchIds, open) => {
    this.setState({ currentOrder, matchIds }, () => this.setState({ orderVisible: open }));
  };

  showOrderEntry = () => {
    this.setState({ orderVisible: !this.state.orderVisible });
  };

  showRfqEntry = (order) => {
    this.setState({ rfqOrder: order }, () => this.setState({ rfqVisible: !this.state.rfqVisible }));
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    // if (this.state.hasError) {
    //   return <h1>Something went wrong.</h1>;
    // }
    if (
      !this.state.workspacesReady ||
      !this.state.marketReady ||
      !this.state.contractReady ||
      !this.state.calculatorsReady ||
      !this.state.initReady ||
      !this.state.organisationsReady ||
      !this.state.usersReady
    ) {
      return <CircularProgress />;
    }

    return (
      <div className={this.props.classes.main}>
        <AppMenu />
        <GridLayout
          showRfqEntry={this.showRfqEntry}
          onCurrentOrderChange={this.onCurrentOrderChange}
          onTradeClick={this.props.onTradeClick}
        />
        <orders.components.forms.CreateOrder />
        <orders.components.forms.AmendOrderForm />
        <orders.components.forms.AmendMultipleOrderForm />
        <orders.components.ManageOrderLists />
        <rfqs.components.forms.Form />
        <rfqs.components.forms.ResponseForm />
        <rfqs.components.forms.AmendForm />
        <rfqs.components.forms.ExecuteTradeForm />
        <workspaces.components.ManageWorkspaces />
        {/*    <calculators.components.ManageCalculators /> */}
        <trades.components.TradeDetails />
        <trades.components.PostTradeForm />
        <trades.components.EndRfqPrompt />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isSuperAdmin: auth.selectors.isSuperAdmin(state),
  };
};
function mapDispatchToProps(dispatch) {
  return {
    init: () => dispatch(createAction(INIT_PROCESS)),
    getUsers: () => dispatch(createAction(users.constants.GET_USERS)),
    getOrganisations: () => dispatch(createAction(organisations.constants.GET_ORGANISATIONS)),
    getMyOrganisation: () => dispatch(createAction(organisations.constants.GET_MY_ORGANISATION)),
    getOrders: () => dispatch(createAction(orders.constants.GET_ORDERS)),
    getRFQs: () => dispatch(createAction(rfqs.constants.GET_RFQS)),
    getContract: () => dispatch(createAction(contracts.constants.GET_CONTRACT)),
    getMarket: () => dispatch(createAction(market.constants.GET_MARKET)),
    getFXRates: () => dispatch(createAction(fxrates.constants.GET_FXRATES)),
    getTrades: () => dispatch(createAction(trades.constants.GET_TRADES)),
    getAdmins: () => dispatch(createAction(admin.constants.GET_ADMINS)),
    initOrdersSettings: () => dispatch(createAction(orders.constants.INIT_ORDERS_SETTINGS)),
    initCalculatorsSettings: () =>
      dispatch(createAction(calculators.constants.INIT_CALCULATORS_SETTINGS)),
    initWorkspace: () => dispatch(createAction(workspaces.constants.INIT_WORKSPACE)),
    initSchemas: () => dispatch(createAction(schemas.actionTypes.INIT_SCHEMAS)),
    getLogos: () => dispatch(createAction(logos.actionTypes.GET_LOGOS)),
    onTradeClick: () => {},
  };
}

ClientPage.propTypes = {
  classes: PropTypes.object.isRequired,
  init: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientPage));
