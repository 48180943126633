import { takeEvery, put } from 'redux-saga/effects';

import * as actionTypes from '../actionTypes';

import orderModel from '../models/order_model.json';
import orderAssetsModel from '../models/orderAssets_model.json';
import orderContractModel from '../models/orderContract_model.json';
import orderContractUnderlyingModel from '../models/orderContract_underlying_model.json';
import rfqModel from '../models/rfq_model.json';
import rfqAssetsModel from '../models/rfqAssets_model.json';
import rfqContractModel from '../models/rfqContract_model.json';
import rfqContractUnderlyingModel from '../models/rfqContract_underlying_model.json';

import { createAction } from '../../../commons/actions';

/**
 *
 * @param {*} action
 */
function* initSchemas(action) {
  try {
    const payload = {
      orderModel,
      orderAssetsModel,
      orderContractModel,
      orderContractUnderlyingModel,
      rfqModel,
      rfqAssetsModel,
      rfqContractModel,
      rfqContractUnderlyingModel,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(createAction(actionTypes.INIT_SCHEMAS_SUCCESS, payload, meta));
  } catch (error) {
    yield put(createAction(actionTypes.INIT_SCHEMAS_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */

export function* watchInitSchemas() {
  yield takeEvery(actionTypes.INIT_SCHEMAS, initSchemas);
}
