import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Icon, TextField } from '@material-ui/core';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';
import PopUp from '../../../../commons/components/popUp';
import { CancelButton } from '../../../../commons/components/buttons';
import { ConfirmButton } from '../../../../commons/components/buttons';
const styles = (theme) => ({
  container: { flex: 1, display: 'flex', flexDirection: 'column' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
  nameChange: {
    display: 'block',
    padding: '25px 8px',
  },
  nameChangeInput: { display: 'block' },
});

class SaveRfqForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
    };
  }
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  onSave = () => {
    if (this.state.title !== '') {
      this.props.saveRfqPreset(this.state.title);
    }
  };

  render() {
    const { classes } = this.props;

    const items = [...this.props.savedRfqPresets];
    return (
      <PopUp open={this.props.open} title="SAVE RFQ AS..." onClose={this.props.onClose}>
        <div className={classes.container}>
          <div className={classes.listContainer}>
            <List open classes={{ root: classes.list }}>
              {items.map((item) => (
                <ListItem
                  key={item.id}
                  button
                  classes={{ root: classes.listItem }}
                  onClick={() => this.setState({ title: item.name })}
                >
                  <ListItemText primary={item.name} />

                  <ListItemIcon align="right">
                    <Icon>save</Icon>
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          </div>
          <TextField
            classes={{ root: classes.nameChange }}
            InputProps={{ className: classes.nameChangeInput }}
            value={this.state.title}
            onChange={this.handleChange('title')}
            placeholder="Save Rfq As..."
          />
          <div className={classes.toolbarContainer}>
            <ToolbarBottom>
              <>
                <CancelButton onClick={this.props.onClose} />
                <ConfirmButton title="Save As" onClick={this.onSave} />
              </>
            </ToolbarBottom>
          </div>
        </div>
      </PopUp>
    );
  }
}
SaveRfqForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SaveRfqForm));
