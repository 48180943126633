import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { FormControl, MenuItem, InputLabel, Input, Chip, Select } from '@material-ui/core';

import { textField } from './formFieldStyles';

const styles = (theme) => ({
  textField: textField(theme),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '2px 2px',
    // padding: "0px 2px",
    // height: "auto"
  },
  any: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.text.primary,
  },
  option: {},
  'label + .MuiInput-formControl': {
    marginTop: '32px',
  },
});

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 4;
const MAX_ITEMS = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * MAX_ITEMS + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectField = (props) => {
  const { classes, accessor, displayName, value, values, defaultValue } = props;

  if (props.any) {
    const index = values.indexOf('ANY');
    if (index === -1) {
      values.splice(0, 0, 'ANY');
    }
  }

  let selectedValues = [];

  if (Array.isArray(value) && value.length > 0) {
    selectedValues = value;
  } else if (Array.isArray(defaultValue) && defaultValue.length > 0) {
    selectedValues = defaultValue;
  }

  return (
    <FormControl className={classes.textField} fullWidth={props.fullWidth} size="small">
      <InputLabel htmlFor={accessor}>{displayName}</InputLabel>
      <Select
        multiple
        value={selectedValues}
        onChange={props.handleChange(accessor)}
        input={<Input id={accessor} />}
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selectedValues.map((val) => (
              <Chip key={val} label={val} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
        disableUnderline={props.disableUnderline}
      >
        {values.map((option) => (
          <MenuItem
            key={option}
            value={option}
            className={option === 'ANY' ? classes.any : classes.options}
          >
            {option}
          </MenuItem>
        ))}
      </Select>

      {/* <FormHelperText>{errorRecipientsMessage}</FormHelperText> */}
    </FormControl>
  );
};

MultipleSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MultipleSelectField);
