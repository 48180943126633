import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { IconButton, Collapse, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { ResetButton } from './buttons';
const styles = (theme) => ({
  container: {
    paddingBottom: 4,
  },
  containerFlex: {
    flex: 1,
    paddingBottom: 4,
  },
  responses: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.banner,
    flex: 1,
  },
  responseHeader: {
    color: theme.palette.text.primary,
    padding: '4px',
  },
  subContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  expand: {
    transform: 'rotate(0deg)',

    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    padding: '4px',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    padding: '4px',
  },
});

const CollapseContainer = (props) => {
  const { classes, children, title, expanded } = props;

  return (
    <div className={clsx(props.noFlex ? classes.containerNoFlex : classes.container)}>
      <div className={classes.responses} style={props.style || null}>
        <div className={classes.subContainer} style={props.subContainerStyle || {}}>
          <div className={classes.responseHeader}>{title}</div>
          {props.hasAddedButton === true && (
            <div>
              <ResetButton
                title={props.addedButtonTitle}
                icon={props.addedButtonIcon}
                onClick={props.onClick}
              ></ResetButton>
            </div>
          )}
          <Tooltip title={expanded ? 'Hide' : 'Show'} aria-label={expanded ? 'Hide' : 'Show'}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={props.onClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Collapse in={expanded}>{children}</Collapse>
      </div>
    </div>
  );
};

CollapseContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withStyles(styles)(CollapseContainer);
