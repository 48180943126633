import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Badge } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import moment from 'moment';

import { fade } from '@material-ui/core/styles/colorManipulator';
import UserAvatar from '../../../../commons/components/userAvatar';
import organisations from '../../../organisations';
import users from '../../../users';
import { updateChats as updateChatsAction } from '../../actions';
import { CHATS_UPDATE } from '../../constants';
import CustomTooltip from './CustomTooltip';

const styles = (theme) => ({
  container: {
    padding: '10px',
    minHeight: '74px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    backgroundColor: 'rgba(0,0,0,.2)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerSelected: {
    padding: '10px',
    minHeight: '74px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    backgroundColor: 'rgba(0,0,0,.4)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  roomTitle: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    color: theme.palette.text.primary,
    fontSize: theme.fontSize.md,
    textAlign: 'left',
  },
  roomSubTitle: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    color: theme.palette.text.disabled,
    fontSize: theme.fontSize.xs,
    textAlign: 'left',
  },
  onlineStatus: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.primary,
    fontSize: theme.fontSize.xs,
    padding: theme.spacing(0.5),
  },
  avatar: {
    padding: theme.spacing(0.5),
  },
  user: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
    flexDirection: 'column',
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },

  online: {
    fontSize: theme.fontSize.xs,
    color: teal[400],
  },
  offline: {
    fontSize: theme.fontSize.xs,
    color: fade(red[400], 0.5),
  },
  lastMessageTime: {
    overflow: 'wrap',
    color: theme.palette.text.disabled,
    fontSize: theme.fontSize.xs,
    padding: theme.spacing(0.5),
  },
});

const getTitle = (room, usersObj, organisationsObj) => {
  if (room.organisationId && room.type && room.type.toLowerCase() !== 'user') {
    const org = organisationsObj[room.organisationId];
    if (org) {
      return org.shortName;
    }
  }
  if (room.userId) {
    const usr = usersObj[room.userId];
    if (usr) {
      return `${usr.firstName} ${usr.lastName}`;
    }
  }
  return room.shortName || room.name;
};

const getSubTitle = (room, usersObj, organisationsObj) => {
  if (room.userId) {
    const user = usersObj[room.userId];
    if (user && user.organisationId) {
      const org = organisationsObj[user.organisationId];
      return org ? org.shortName : '';
    }
  }
  return room.shortName ? room.name : room.type;
};

const getLastMessage = (messages) => {
  if (messages !== null && messages !== undefined) {
    const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
    const today = moment(new Date()).format('YYYY-MM-DD');
    const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
    const lastMessageDay =
      lastMessage !== null ? moment(lastMessage.createdTime).format('YYYY-MM-DD') : null;
    if (lastMessageDay === today) {
      return `Today at ${moment(lastMessage.createdTime).format('HH:mm')}`;
    }
    if (lastMessageDay === yesterday) {
      return `Yesterday at ${moment(lastMessage.createdTime).format('HH:mm')}`;
    }
    if (lastMessageDay === null || lastMessageDay === undefined) {
      return '';
    }
    return moment(lastMessage.createdTime, 'YYYY-MM-DD').format('ddd, MMM Do YYYY');
  } else {
    return '';
  }
};

const UserTab = ({
  classes,
  roomInfo,
  onRoomClick,
  selected,
  users,
  organisations,
  updateChats,
}) => {
  const handleClick = () => {
    if (selected && roomInfo.newMessages > 0) {
      const payload = {
        action: 'markAsRead',
        items: [{ id: roomInfo.id }],
      };
      updateChats(payload);
    }

    if (typeof onRoomClick === 'function') {
      onRoomClick(roomInfo);
    }
  };
  const { messages } = roomInfo;
  const lastMessageTime = getLastMessage(messages);

  return (
    <div className={selected ? classes.containerSelected : classes.container} onClick={handleClick}>
      <div className={classes.avatar}>
        <UserAvatar
          organisationId={roomInfo.organisationId}
          userId={roomInfo.userId}
          name={roomInfo.name}
        />
      </div>
      <div className={classes.user}>
        <CustomTooltip title={getTitle(roomInfo, users, organisations)}>
          <div className={classes.roomTitle}>{getTitle(roomInfo, users, organisations)}</div>
        </CustomTooltip>
        <div className={classes.roomSubTitle}>{getSubTitle(roomInfo, users, organisations)}</div>
      </div>
      <div style={{ flexGrow: 1, textAlign: 'right' }}>
        <Badge color="secondary" badgeContent={roomInfo.newMessages || 0}>
          <div className={classes.lastMessageTime}>{lastMessageTime}</div>
        </Badge>
      </div>

      <FiberManualRecordIcon
        className={roomInfo.loggedIn === true ? classes.online : classes.offline}
      />
    </div>
  );
};
UserTab.propTypes = {
  classes: PropTypes.object.isRequired,
  roomInfo: PropTypes.object.isRequired,
  organisations: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  onRoomClick: PropTypes.func,
  updateChats: PropTypes.func,
  selected: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  organisations: organisations.selectors.getAllObject(state),
  users: users.selectors.getAllObject(state),
});
const mapDispatchToProps = (dispatch) => {
  return {
    updateChats: (payload) => {
      dispatch(updateChatsAction(CHATS_UPDATE, payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserTab));
