export const NAME = 'trades';

export const GET_TRADES = 'trades/get';
export const GET_TRADES_SUCCESS = 'trades/get/success';
export const GET_TRADES_ERROR = 'trades/get/error';

export const RESET_TRADES = 'trades/reset';

export const GET_TRADE = 'trade/get';
export const GET_TRADE_SUCCESS = 'trade/get/success';
export const GET_TRADE_ERROR = 'trade/get/error';

export const INSERT_TRADES_FROM_SOCKET = 'trades/insert/from/socket';
export const INSERT_TRADES_FROM_SOCKET_SUCCESS = 'trades/insert/from/socket/success';
export const INSERT_TRADES_FROM_SOCKET_ERROR = 'trades/insert/from/socket/error';

export const UPDATE_TRADES_FROM_SOCKET = 'trades/update/from/socket';
export const UPDATE_TRADES_FROM_SOCKET_SUCCESS = 'trades/update/from/socket/success';
export const UPDATE_TRADES_FROM_SOCKET_ERROR = 'trades/update/from/socket/error';

export const TRADE_DETAILS_OPEN = 'tradeDetails/open';
export const TRADE_DETAILS_OPEN_SUCCESS = 'tradeDetails/open/success';
export const TRADE_DETAILS_OPEN_ERROR = 'tradeDetails/open/error';

export const POST_TRADE_FORM_OPEN = 'postTradeForm/open';
export const POST_TRADE_FORM_OPEN_SUCCESS = 'postTradeForm/open/success';
export const POST_TRADE_FORM_OPEN_ERROR = 'postTradeForm/open/error';

export const UPDATE_END_RFQ_PROMPT = 'endRfqPrompt/update';
export const UPDATE_END_RFQ_PROMPT_SUCCESS = 'endRfqPrompt/update/success';
export const UPDATE_END_RFQ_PROMPT_ERROR = 'endRfqPrompt/update/error';

export const TRADE_CANCELLATION = 'trade/cancellation';
export const TRADE_CANCELLATION_SUCCESS = 'trade/cancellation/success';
export const TRADE_CANCELLATION_ERROR = 'trade/cancellation/error';

export const tradeCancellationStatus = {
  NONE: 'NONE',
  REQUESTED: 'REQUESTED',
  DECLINED: 'DECLINED',
  APPROVED: 'APPROVED',
};
