import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Icon, Tooltip, Avatar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';
import Cell from './Cell';
import clsx from 'clsx';

const styles = (theme) => ({});

const ActiveGridHeaders = (props) => {
  const { classes } = props;

  return (
    <tr className={classes.container}>
      <th style={{ width: '300px' }}></th>
      <th style={{ width: '64px' }}></th>
      <th style={{ width: '4px' }}></th>
      <th style={{ width: '64px' }}></th>
      <th style={{ width: '64px', minWidth: '16px' }}></th>
      <th style={{ width: '64px' }}></th>
      <th style={{ width: '4px' }}></th>
      <th style={{ width: '64px' }}></th>
      <th></th>
    </tr>
  );
};

ActiveGridHeaders.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActiveGridHeaders);
