import { takeLatest, fork, takeEvery } from 'redux-saga/effects';
import { INIT_PROCESS } from '../constants';
import { HOME_PAGE_URL } from '../../commons/constants';

import auth from '../../modules/auth';
import workspaces from '../../modules/workspaces';
import orders from '../../modules/orders';
import users from '../../modules/users';
import organisations from '../../modules/organisations';
import contracts from '../../modules/contracts';
import market from '../../modules/market';
import calculators from '../../modules/calculators';
import rfqs from '../../modules/rfqs';
import rfqs_new from '../../modules/rfqs_new';
import fxrates from '../../modules/fxrates';
import trades from '../../modules/trades';
import settings from '../../modules/settings';
import chat from '../../modules/chat';
import charts from '../../modules/charts';
import admin from '../../modules/admin';
import fileUpload from '../../modules/fileUpload';
import blotters from '../../modules/blotters';
import schemas from '../../modules/schemas';
import socket from '../../modules/socket';
import inventory from '../../modules/inventory';
import statistics from '../../modules/statistics';
import logos from '../../modules/logos';
//import watchSocket from './socket';

import { removeFromLS } from '../localStorage';

/**
 *
 * @param {*} action
 */
function* initProcess() {
  const tasks = [
    yield fork(socket.sagas.watchSocket),

    yield fork(auth.sagas.watchSocketConnected),
    yield fork(auth.sagas.watchSocketReconnecting),
    yield fork(auth.sagas.watchSocketDisconnected),
    yield fork(auth.sagas.watchUpdateSettings),
    yield fork(auth.sagas.runAutoUpdateSettings, 20000), // 2 minutes for auto update settings

    yield fork(schemas.sagas.watchInitSchemas),

    yield fork(workspaces.sagas.watchInitWorkspace),
    yield fork(workspaces.sagas.watchActivateWorkspace),
    yield fork(workspaces.sagas.watchResetWorkspace),
    yield fork(workspaces.sagas.watchClearWorkspace),
    yield fork(workspaces.sagas.watchAddComponent),
    yield fork(workspaces.sagas.watchUpdateWorkspace),
    yield fork(workspaces.sagas.watchRemoveLayoutWidget),
    yield fork(workspaces.sagas.watchRemoveWidget),
    yield fork(workspaces.sagas.watchUpdateWidget),
    yield fork(workspaces.sagas.watchUpdateManageWorkspaces),
    yield fork(workspaces.sagas.watchUpdateWorkspaces),
    yield fork(workspaces.sagas.watchSignOutSuccess),

    yield fork(contracts.sagas.watchGetContracts),
    yield fork(contracts.sagas.watchGetContract),
    yield fork(contracts.sagas.watchSignOutSuccess),

    yield fork(market.sagas.watchInitMarket),
    yield fork(market.sagas.watchDestroyMarket),
    yield fork(market.sagas.watchUpdateMarket),
    yield fork(market.sagas.watchGetMarket),
    yield fork(market.sagas.watchChangeStateOrders),
    yield fork(market.sagas.watchChangeStateContract),
    yield fork(market.sagas.watchSignOutSuccess),

    yield fork(inventory.sagas.watchInitInventory),

    yield fork(statistics.sagas.watchInitStatistics),
    yield fork(statistics.sagas.watchUpdateStatistics),

    yield fork(fxrates.sagas.watchInsertFxratesFromSocket),
    yield fork(fxrates.sagas.watchUpdateFxratesFromSocket),
    yield fork(fxrates.sagas.watchGetFxrates),
    yield fork(fxrates.sagas.watchInitFxrate),
    yield fork(fxrates.sagas.watchDestroyFxrate),
    yield fork(fxrates.sagas.watchUpdateFxrate),
    yield fork(fxrates.sagas.watchChangeStateContract),
    yield fork(fxrates.sagas.watchSignOutSuccess),
    yield fork(fxrates.sagas.watchSocketReconnected),

    yield fork(admin.sagas.watchGetAdmins),
    yield fork(admin.sagas.watchInitAdmin),
    yield fork(admin.sagas.watchDestroyAdmin),
    //yield fork(admin.sagas.watchUpdateAdmin),
    //yield fork(admin.sagas.watchChangeStateContract),
    //yield fork(admin.sagas.watchSignOutSuccess),
    //yield fork(admin.sagas.watchSocketReconnected),watchCreateAdminItem
    yield fork(admin.sagas.watchCreateAdminItem),
    yield fork(admin.sagas.watchUpdateAdminItem),

    yield fork(calculators.sagas.watchInitCalculatorsSettings),
    yield fork(calculators.sagas.watchInitCalculator),
    yield fork(calculators.sagas.watchDestroyCalculator),
    yield fork(calculators.sagas.watchUpdateCalculator),
    yield fork(calculators.sagas.watchUpdateCalculators),
    yield fork(calculators.sagas.watchCalculatorManager),
    yield fork(calculators.sagas.watchSignOutSuccess),

    yield fork(orders.sagas.watchInsertOrdersFromSocket),
    yield fork(orders.sagas.watchUpdateOrdersFromSocket),
    yield fork(orders.sagas.watchUpdateOrders),
    yield fork(orders.sagas.watchGetOrders),
    yield fork(orders.sagas.watchGetOrder),
    yield fork(orders.sagas.watchCreateOrder),
    yield fork(orders.sagas.watchCreateOrderFromManager),
    yield fork(orders.sagas.watchCreateOrderFromRfq),
    yield fork(orders.sagas.watchUpdateForm),
    yield fork(orders.sagas.watchInitOrdersSettings),
    yield fork(orders.sagas.watchUpdateEditorOrderLists),
    yield fork(orders.sagas.watchOrdersManagerInit),
    yield fork(orders.sagas.watchOrdersManagerUpdate),
    yield fork(orders.sagas.watchOrdersManagerTableUpdate),
    yield fork(orders.sagas.watchOrdersManagerTableAddRow),
    yield fork(orders.sagas.watchOrdersManagerTableSendOrders),
    yield fork(orders.sagas.watchUpdateAmendOrderForm),
    yield fork(orders.sagas.watchSendOrderFromAmendOrderForm),
    yield fork(orders.sagas.watchUpdateAmendMultipleOrderForm),
    yield fork(orders.sagas.watchSendOrderFromAmendMultipleOrderForm),
    yield fork(orders.sagas.watchChangeStateCalculators),
    yield fork(orders.sagas.watchSignOutSuccess),
    yield fork(orders.sagas.watchSocketReconnected),

    yield fork(rfqs.sagas.watchInsertRFQsFromSocket),
    yield fork(rfqs.sagas.watchUpdateRFQsFromSocket),
    yield fork(rfqs.sagas.watchGetRFQs),
    yield fork(rfqs.sagas.watchGetRFQ),
    yield fork(rfqs.sagas.watchCreateRFQ),
    yield fork(rfqs.sagas.watchUpdateRFQs),
    yield fork(rfqs.sagas.watchUpdateForm),
    yield fork(rfqs.sagas.watchUpdateRFQViews),
    yield fork(rfqs.sagas.watchUpdateRFQDismissed),
    yield fork(rfqs.sagas.watchUpdateResponseForm),
    yield fork(rfqs.sagas.watchSignOutSuccess),
    yield fork(rfqs.sagas.watchSocketReconnected),

    yield fork(organisations.sagas.watchGetOrganisations),
    yield fork(organisations.sagas.watchGetOrganisation),
    yield fork(organisations.sagas.watchGetMyOrganisation),
    yield fork(organisations.sagas.watchSignOutSuccess),
    yield fork(organisations.sagas.watchSocketReconnected),

    yield fork(users.sagas.watchGetUsers),
    yield fork(users.sagas.watchUpdateUser),
    yield fork(users.sagas.watchInsertUsersFromSocket),
    yield fork(users.sagas.watchUpdateUsersFromSocket),
    yield fork(users.sagas.watchSignOutSuccess),
    yield fork(users.sagas.watchSocketReconnected),

    yield fork(blotters.sagas.watchInitBlotter),
    yield fork(blotters.sagas.watchDestroyBlotter),
    yield fork(blotters.sagas.watchUpdateBlotter),
    yield fork(blotters.sagas.watchUpdateBlotterPresets),

    yield fork(settings.sagas.watchNotificationSettingsOpen),
    yield fork(settings.sagas.watchGeneralSettingsOpen),
    yield fork(settings.sagas.watchGeneralSettingsUpdate),
    yield fork(settings.sagas.watchBlotterPresetsUpdate),
    yield fork(settings.sagas.watchBlotterPresetsOpen),
    yield fork(settings.sagas.watchTradingPartnersOpen),
    yield fork(settings.sagas.watchGetNotificationTypes),
    yield fork(settings.sagas.watchSignOutSuccess),

    yield fork(trades.sagas.watchInsertTradesFromSocket),
    yield fork(trades.sagas.watchUpdateTradesFromSocket),
    yield fork(trades.sagas.watchGetTrades),
    yield fork(trades.sagas.watchGetTrade),
    yield fork(trades.sagas.watchResetTrades),
    yield fork(trades.sagas.watchTradeDetailsOpen),
    yield fork(trades.sagas.watchPostTradeFormOpen),
    yield fork(trades.sagas.watchUpdateEndRfqPrompt),
    yield fork(trades.sagas.watchSignOutSuccess),
    yield fork(trades.sagas.watchSocketReconnected),
    yield fork(trades.sagas.watchTradeCancellation),
    //yield fork(watchUpdateBlotterSettings),
    yield fork(chat.sagas.initChats),

    yield fork(charts.sagas.watchInitChart),
    yield fork(charts.sagas.watchDestroyChart),
    yield fork(charts.sagas.watchUpdateChart),
    yield fork(charts.sagas.watchInitTable),
    yield fork(charts.sagas.watchDestroyTable),
    yield fork(charts.sagas.watchUpdateTable),
    yield fork(charts.sagas.watchSignOutSuccess),

    yield fork(fileUpload.sagas.watchInitFileUpload),
    yield fork(fileUpload.sagas.watchDestroyFileUpload),
    yield fork(fileUpload.sagas.watchUpdateFileUpload),
    yield fork(fileUpload.sagas.watchCreateFile),
    yield fork(fileUpload.sagas.watchGetFile),
    yield fork(fileUpload.sagas.watchGetFileContent),
    yield fork(fileUpload.sagas.watchGetFiles),
    yield fork(fileUpload.sagas.watchDeleteFile),
    yield fork(fileUpload.sagas.watchUpdateFileMeta),

    yield fork(rfqs_new.sagas.watchOpenRfqForm),

    yield fork(logos.sagas.watchGetLogos),
    yield fork(logos.sagas.watchGetLogo),
    yield fork(logos.sagas.watchUpdateLogo),

    yield fork(watchSignOutSuccess),
  ];

  const redirect = {
    whenAuthed: false,
    url: HOME_PAGE_URL,
  };

  yield fork(auth.sagas.watchSignOut, redirect, tasks);
  // yield take(auth.constants.SIGN_OUT);
  // yield cancel(tasks);
  // yield put({ type: auth.constants.SIGN_OUT_SUCCESS });
}

export default function* watchInitProccess() {
  yield takeLatest(INIT_PROCESS, initProcess);
}

function signOutSuccess() {
  removeFromLS('theme');
  removeFromLS('blotterSettings');
}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}
