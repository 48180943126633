import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Button, Icon } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import { darken } from '@material-ui/core/styles/colorManipulator';
import UserAvatar from '../../../commons/components/userAvatar';
import { formatDateTime, formatDateWithDay } from '../../../commons/config/formatters';
import { directions, visibilities } from '../../../commons/models/constants';
import { getOrganisationShortNameFromId } from '../../../commons/utils/functions';

import { getOrganisations, getActiveUserOrganisation } from '../selectors';
import { getOrganisationsObject, getUsersObject, getActiveUser } from '../../orders/selectors';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '4px',
    padding: theme.spacing(),
    position: 'relative',
  },
  containerBuy: {
    border: 'solid 3px',
    borderColor: lightBlue[300],
  },
  containerSell: {
    border: 'solid 3px',
    borderColor: teal[300],
  },
  containerSelected: {
    border: 'solid 3px',
    borderColor: theme.palette.amber,
    backgroundColor: theme.palette.amber_dark,
    transition: 'all 1s ease-out',
  },
  containerOpaque: {
    opacity: 1,
    display: 'flex',
    //marginTop: theme.spacing(),
    //marginBottom: theme.spacing(),
    flexDirection: 'column',
    justifyContent: 'center',
    border: 'solid 1px',
    borderColor: theme.palette.divider,
    backgroundColor: red[500],
    '&:hover': {
      opacity: 0.85,
      cursor: 'pointer',
    },
  },
  containerItem: {
    display: 'flex',
    flex: 1,
    fontSize: theme.typography.fontSize,
  },
  containerItemOrg: {
    fontSize: theme.typography.fontSize * 0.9,
    color: theme.palette.teal,
  },
  containerContentBuy: {
    backgroundColor: 'transparent',
  },
  containerContentSell: {
    backgroundColor: 'transparent',
  },
  containerContentEnded: {
    backgroundColor: darken(red[500], 0.5),
  },

  containerContentItem: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
  containerRecipients: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',

    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    minHeight: '24px',
    color: '#fff',
  },
  recipientsBuy: {
    backgroundColor: 'transparent',
  },
  recipientsSell: {
    backgroundColor: 'transparent',
  },
  recipientsEnded: {
    backgroundColor: darken(red[500], 0.2),
  },
  userAvatar: {
    position: 'absolute',
    top: 6,
    right: 6,
  },
  containerFooter: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },

  containerFooterBuy: {
    backgroundColor: 'transparent',

    color: '#fff',
  },
  containerFooterSell: {
    backgroundColor: 'transparent',
    maxHeight: '36px',
    color: '#fff',
  },
  containerFooterEnded: {
    backgroundColor: red[500],

    color: '#fff',
  },

  direction: {
    color: teal[500],
    fontWeight: 'bold',
  },
  statuss: {
    padding: theme.spacing() / 2,
    paddingLeft: theme.spacing(),
    color: '#fff',
  },
  button: {
    borderRadius: 0,
    borderTopLeftRadius: '4px',
    borderBottomRightRadius: '0px',
    padding: '0px 16px',
    fontSize: theme.typography.fontSize * 0.85,
  },

  buttonColorBuy: {
    backgroundColor: lightBlue[300],

    '&:hover': {
      backgroundColor: lightBlue[400],
    },
  },
  buttonColorSell: {
    backgroundColor: teal[300],

    '&:hover': {
      backgroundColor: teal[400],
    },
  },
  buttonColorSelected: {
    backgroundColor: theme.palette.amber,

    '&:hover': {
      backgroundColor: theme.palette.amber,
    },
    transition: 'all 1s ease-out',
  },
  timestampContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  timestamp: {
    paddingRight: theme.spacing(),
    color: '#fff',
    fontSize: '12px',
    textAlign: 'center',
  },
});

const getRecipients = (selectedOrganisationsIds, organisations, visibility) => {
  const list = [];
  const orgs = [...organisations];
  if (visibility === visibilities.SELECTED_ORGANISATIONS) {
    selectedOrganisationsIds.forEach((r) => {
      let shortName = r.shortName || null;
      if (shortName === null) {
        shortName = getOrganisationShortNameFromId(r, orgs);
      }
      list.push(shortName);
    });
  } else {
    return visibility;
  }

  list.sort();
  const listString = list.join(', ');
  return listString;
};
const getCounterparty = (selectedOrganisationsIds, organisations, isCompanyOrder, rfqOwnerId) => {
  const orgObject = {
    id: null,
    name: '',
    shortName: '',
  };
  const orgs = { ...organisations };
  let orgId = null;
  if (isCompanyOrder) {
    orgId = selectedOrganisationsIds[0];
  } else {
    orgId = rfqOwnerId;
  }
  const org = orgId !== null ? orgs[orgId] : null;

  if (org) {
    orgObject.id = org.id;
    orgObject.name = org.name;
    orgObject.shortName = org.shortName;
  }
  return orgObject;
};
const getData = (data, organisations, isCompanyOrder) => {
  if (Object.prototype.toString.call(data.contract) === '[object Object]') {
    if (Object.prototype.toString.call(data.contract.underlying) === '[object Object]') {
      const selectedOrganisationsIds = data.selectedOrganisationsIds || [];
      const rfqOwnerId = data.organisationId || '';
      const counterparty = getCounterparty(
        selectedOrganisationsIds,
        organisations,
        isCompanyOrder,
        rfqOwnerId,
      );
      const deliveryDate = data.contract.deliveryDate ? data.contract.deliveryDate.to : null;
      return {
        species: (function (word) {
          const words = word.match(/([A-Z]?[^A-Z]*)/g);
          words[0] = words[0][0].toUpperCase() + words[0].substring(1);
          return words.join(' ');
        })(data.contract.underlying.species),
        volume: data.volume,
        deliveryDate: formatDateWithDay(deliveryDate),

        organisation: counterparty.name,
        organisationShortName: counterparty.shortName,
        orgId: counterparty.id,
      };
    }
  }
  return {};
};

const Tab = (props) => {
  const {
    classes,
    organisations,
    organisationsObject,
    usersObject,
    data,
    contacts,
    showDetails,
    user,
    selected,
  } = props;

  const isCompanyOrder = data.organisationId === user.organisationId;
  const status = data.status === 'ACTIVE' ? 'IN PROGRESS' : 'ENDED';
  const callOutData = getData(data, organisationsObject, isCompanyOrder);
  const containerClass =
    status === 'ENDED'
      ? classes.containerOpaque
      : data.direction === directions.BUY
      ? selected
        ? classes.containerSelected
        : classes.containerBuy
      : selected
      ? classes.containerSelected
      : classes.containerSell;

  const containerFooterClass =
    status === 'ENDED'
      ? classes.containerFooterEnded
      : data.direction === directions.BUY
      ? classes.containerFooterBuy
      : classes.containerFooterSell;

  const buttonColorClass =
    status === 'ENDED'
      ? classes.recipientsEnded
      : data.direction === directions.BUY
      ? selected
        ? classes.buttonColorSelected
        : classes.buttonColorBuy
      : selected
      ? classes.buttonColorSelected
      : classes.buttonColorSell;

  return (
    <div className={`${containerClass} ${classes.container}`}>
      <div
        className={clsx(classes.containerItem, classes.containerItemOrg)}
      >{`${callOutData.organisation} | ${callOutData.organisationShortName}`}</div>
      <div
        className={classes.containerItem}
      >{`${callOutData.species} ${callOutData.volume} CTNS`}</div>
      <div className={classes.containerItem}>{`${callOutData.deliveryDate}`}</div>

      <div className={`${containerFooterClass} ${classes.containerFooter}`}>
        {Object.prototype.hasOwnProperty.call(props, 'viewable') && (
          <Button
            endIcon={<Icon>visibility</Icon>}
            //fullWidth
            onClick={() => props.tabClick(data)}
            className={clsx(buttonColorClass, classes.button)}
          >
            View
          </Button>
        )}
      </div>
      <div className={classes.userAvatar}>
        <UserAvatar organisationId={callOutData.orgId} />
      </div>
    </div>
  );
};

Tab.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    organisations: getOrganisations(state), // state.rooms.organisations,
    myOrg: getActiveUserOrganisation(state), // state.logins.organisation,
    organisationsObject: getOrganisationsObject(state),
    usersObject: getUsersObject(state),
    user: getActiveUser(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Tab));
