import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
  IconButton,
  Icon,
  Button,
  TextField,
} from '@material-ui/core';

import PopUp from '../../../commons/components/popUp';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import { CancelButton, ConfirmButton } from '../../../commons/components/buttons';

import { UPDATE_MANAGE_WORKSPACES } from '../constants';

import { updateManageWorkspaces as actionUpdateManageWorkspaces } from '../actions';
import { getManageWorkspaces } from '../selectors';

const styles = (theme) => ({
  title: { fontSize: theme.fontSize * 1.5 },
  container: { backgroundColor: 'rgba(0,0,0,.1)' },
  containerItems: { textAlign: 'center' },
  banner: { backgroundColor: 'rgba(0,0,0,.3)', padding: '5px' },
  textField: { marginTop: '8px', marginBottom: '8px' },
  fab: { marginTop: theme.spacing(), marginBottom: theme.spacing() },
  nameChange: {
    color: 'rgba(0,0,0,.3)',
    display: 'block',
    padding: '0px 25px',
  },
  nameChangeInput: { display: 'block' },
});

class ManageWorkspace extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: [0],
      showText: -1,
    };
  }

  handleToggle = (id) => () => {
    const payload = {
      action: 'toogleWorkspace',
      id,
    };

    this.props.updateManageWorkspaces(payload);
  };

  addWorkspace = () => {
    const payload = {
      action: 'addWorkspace',
    };

    this.props.updateManageWorkspaces(payload);
  };

  deleteWorkspace = (id) => {
    const payload = {
      action: 'deleteWorkspace',
      id,
    };

    this.props.updateManageWorkspaces(payload);
  };

  onCopy = (e, id) => {
    const payload = {
      action: 'copyWorkspace',
      id,
    };

    this.props.updateManageWorkspaces(payload);
  };

  onSave = () => {
    const payload = {
      action: 'saveWorkspaces',
    };

    this.props.updateManageWorkspaces(payload);
  };

  onEditClick = (id) => {
    if (this.state.showText === id) {
      this.setState({ showText: -1 });
    } else {
      this.setState({ showText: id });
    }
  };

  onNameChange = (e, id) => {
    const payload = {
      action: 'changeName',
      id,
      value: e.target.value,
    };

    this.props.updateManageWorkspaces(payload);
  };

  close = () => {
    const payload = {
      action: 'closeManage',
    };

    this.props.updateManageWorkspaces(payload);
  };

  render() {
    const { classes, manageWorkspaces } = this.props;
    const { open, items: workspaces } = manageWorkspaces;
    return (
      <PopUp open={open} title="WORKSPACES">
        <div>
          <div className={classes.container}>
            <div className={classes.containerItems}>
              <List className={classes.root}>
                {workspaces.map((value) => (
                  <div key={`${value.id}_w`}>
                    <ListItem
                      key={value.id}
                      role={undefined}
                      dense
                      button
                      onClick={this.handleToggle(value.id)}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Checkbox checked={value.active} tabIndex={-1} disableRipple />
                      <ListItemText primary={value.name} />

                      <ListItemSecondaryAction>
                        <Tooltip title="COPY" aria-label="COPY">
                          <IconButton onClick={(e) => this.onCopy(e, value.id)}>
                            <Icon>file_copy</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="RENAME" aria-label="RENAME">
                          <IconButton onClick={() => this.onEditClick(value.id)}>
                            <Icon>edit</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="DELETE" aria-label="DELETE">
                          <IconButton onClick={() => this.deleteWorkspace(value.id)}>
                            <Icon>delete</Icon>
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {this.state.showText === value.id && (
                      <div>
                        <TextField
                          classes={{ root: classes.nameChange }}
                          InputProps={{ className: classes.nameChangeInput }}
                          onChange={(e) => this.onNameChange(e, value.id)}
                          placeholder="add New Name"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </List>
            </div>
          </div>

          <Button aria-label="Delete" className={classes.fab} onClick={this.addWorkspace}>
            <Icon className={classes.extendedIcon}>add</Icon>
            <Typography>Add New Workspace</Typography>
          </Button>

          <ToolbarBottom>
            <>
              <CancelButton onClick={this.close} />
              <ConfirmButton onClick={this.onSave} />
            </>
          </ToolbarBottom>
        </div>
      </PopUp>
    );
  }
}

ManageWorkspace.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

const mapStateToProps = (state) => {
  return {
    manageWorkspaces: getManageWorkspaces(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateManageWorkspaces: (payload) => {
      dispatch(actionUpdateManageWorkspaces(UPDATE_MANAGE_WORKSPACES, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManageWorkspace));
