import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Cell from './Cell';
import clsx from 'clsx';

const styles = (theme) => ({
  rowContainer: {
    margin: '20px',
  },
  row: {
    padding: '4px',
    //
  },
  td: {
    padding: '4px 0px',
  },
  tdContent: {
    backgroundColor: theme.palette.background.darkRow,
    padding: '8px 8px',
    minHeight: '33px',
  },
});

const ActiveGridRow = (props) => {
  const { classes, weightClass, allInterests } = props;

  const offerVolume = allInterests.sellers.rows[weightClass]
    ? allInterests.sellers.rows[weightClass].volume
    : '';
  const offerPrice = allInterests.sellers.rows[weightClass]
    ? allInterests.sellers.rows[weightClass].price
    : '';
  const offerStatus = allInterests.sellers.rows[weightClass]
    ? allInterests.sellers.rows[weightClass].status
    : '';
  const bidVolume = allInterests.buyers.rows[weightClass]
    ? allInterests.buyers.rows[weightClass].volume
    : '';
  const bidPrice = allInterests.buyers.rows[weightClass]
    ? allInterests.buyers.rows[weightClass].price
    : '';
  const bidStatus = allInterests.buyers.rows[weightClass]
    ? allInterests.buyers.rows[weightClass].status
    : '';

  return (
    <tr className={classes.row}>
      <td className={classes.td}>
        <div className={classes.tdContent}>{weightClass}</div>
      </td>
      <td className={classes.td}>
        <Cell value={offerVolume} cellType="offerVolume" status={offerStatus}></Cell>
      </td>

      <td className={classes.td}>
        <div className={classes.tdContent}></div>
      </td>
      <td className={classes.td}>
        <Cell value={offerPrice} cellType="offerPrice" status={offerStatus}></Cell>
      </td>

      <td className={classes.td}>
        <div className={classes.tdContent}></div>
      </td>
      <td className={classes.td}>
        <Cell value={bidVolume} cellType="bidVolume" status={bidStatus}></Cell>
      </td>
      <td className={classes.td}>
        <div className={classes.tdContent}></div>
      </td>
      <td className={classes.td}>
        <Cell value={bidPrice} cellType="bidPrice" status={bidStatus}></Cell>
      </td>
      <td className={classes.td}>
        <div className={classes.tdContent}></div>
      </td>
    </tr>
  );
};

ActiveGridRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActiveGridRow);
