import { getFieldsArray } from '../../contracts/utils/modelFunctions';

export const defaultOrderBlotterPreset = () => {
  const fields = getFieldsArray('trade', ['blotter']);
  const items = [];
  const presets = {
    updatedTime: {
      width: 170,
    },
    product: {
      width: 150,
    },
    location: {
      width: 95,
    },
    weightClass: {
      width: 65,
    },
    incoTerms: {
      width: 80,
    },
    direction: {
      width: 95,
    },
    volume: {
      width: 80,
    },
    originalVolume: {
      width: 95,
    },
    unit: {
      width: 95,
    },
    price: {
      width: 95,
    },
    currency: {
      width: 95,
    },
    week: {
      width: 95,
    },

    status: {
      width: 95,
    },
    rfqId: {
      width: 95,
    },
    createdTime: {
      width: 95,
    },

    user: {
      width: 150,
    },
    organisation: {
      width: 150,
    },
    textMessage: {
      width: 150,
    },
  };
  fields.forEach((field) => {
    const hide = !presets[field.accessor];
    const width = presets[field.accessor] ? presets[field.accessor].width : 150;

    const item = {
      colId: field.fullAccessor,
      hide: hide,
      aggFunc: null,
      width: width,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
      accessor: field.accessor,
    };

    items.push(item);
  });

  const presetsArray = Object.keys(presets);
  items.sort((a, b) => {
    const indexA = presetsArray.indexOf(a.accessor) === -1 ? 200 : presetsArray.indexOf(a.accessor);
    const indexB = presetsArray.indexOf(b.accessor) === -1 ? 200 : presetsArray.indexOf(b.accessor);
    return indexA - indexB;
  });

  return items;
};

export const defaultTradeBlotterPreset = () => {
  const fields = getFieldsArray('trade', ['blotter']);
  const items = [];
  const presets = {
    updatedTime: {
      width: 170,
    },
    product: {
      width: 150,
    },
    location: {
      width: 95,
    },
    weightClass: {
      width: 65,
    },
    incoTerms: {
      width: 80,
    },
    direction: {
      width: 95,
    },
    volume: {
      width: 80,
    },
    originalVolume: {
      width: 95,
    },
    unit: {
      width: 95,
    },
    price: {
      width: 95,
    },
    currency: {
      width: 95,
    },
    nominal: {
      width: 150,
    },
    week: {
      width: 65,
    },

    status: {
      width: 95,
    },
    rfqId: {
      width: 95,
    },
    createdTime: {
      width: 95,
    },

    ownUser: {
      width: 150,
    },
    ownCompany: {
      width: 150,
    },
    ownComments: {
      width: 150,
    },
    counterpartyUser: {
      width: 150,
    },
    counterpartyCompany: {
      width: 150,
    },
    counterpartyComments: {
      width: 150,
    },
    rfqComments: {
      width: 250,
    },
  };
  fields.forEach((field) => {
    const hide = !presets[field.accessor];
    const width = presets[field.accessor] ? presets[field.accessor].width : 150;
    const item = {
      colId: field.fullAccessor,
      hide: hide,
      aggFunc: null,
      width: width,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
      accessor: field.accessor,
    };
    items.push(item);
  });
  const presetsArray = Object.keys(presets);
  items.sort((a, b) => {
    const indexA = presetsArray.indexOf(a.accessor) === -1 ? 200 : presetsArray.indexOf(a.accessor);
    const indexB = presetsArray.indexOf(b.accessor) === -1 ? 200 : presetsArray.indexOf(b.accessor);

    return indexA - indexB;
  });

  return items;
};

export const initialBlotterPresets = () => {
  const blotters = defaultBlotterPresets();
  return {
    0: blotters.order,
    1: blotters.order,
    2: blotters.trade,
  };
};

export const defaultBlotterPresets = () => {
  return {
    order: {
      type: 'ORDER',
      name: 'DEFAULT_ORDER',
      id: 'order-1',
      editable: false,
      columnState: defaultOrderBlotterPreset(),
      sortState: [{ colId: 'updatedTime', sort: 'desc' }],
      filterState: [],
      floatingFilter: false,
    },
    trade: {
      type: 'TRADE',
      name: 'DEFAULT_TRADE',
      id: 'trade-1',
      columnState: defaultTradeBlotterPreset(),
      editable: false,
      sortState: [{ colId: 'updatedTime', sort: 'desc' }],
      filterState: [],
      floatingFilter: false,
    },
  };
};
