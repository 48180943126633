import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Popover,
  Typography,
  Icon,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

import ChatNotification from './ChatNotification';
import chat from '../../../modules/chat';
import { getNewMessages } from '../../selectors';

const styles = (theme) => ({
  notifications: {
    width: '440px',
  },
  notificationHeader: {
    color: fade(theme.palette.text.primary, 0.7),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.main,
  },
  messageContainer: {
    overflowY: 'scroll',
    padding: theme.spacing(),
  },
});

const ChatNotificationsMenu = (props) => {
  const { classes } = props;

  const notifications = props.notifications.sort((a, b) => {
    if (a.createdTime > b.createdTime) {
      return -1;
    }
    if (a.createdTime < b.createdTime) {
      return 1;
    }

    return 0;
  });

  const markAll = () => {
    const existChats = {};
    const items = [];

    props.notifications.forEach((n) => {
      if (!existChats[n.chatId]) {
        items.push({ id: n.chatId });
        existChats[n.chatId] = true;
      }
    });

    const payload = {
      action: 'markAsRead', // TODO: move to constants
      items,
    };

    props.markAll(payload);
  };

  return (
    <Popover
      anchorEl={props.anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={props.open}
      onClose={props.close}
    >
      <div className={classes.notifications}>
        <div className={classes.notificationHeader}>
          <Typography variant="h6" color="inherit">
            CHAT MESSAGES
          </Typography>

          <div>
            <Icon>settings</Icon>
          </div>
        </div>
        <div className={classes.messageContainer}>
          <List open>
            {notifications.map((n) => {
              return <ChatNotification key={n.id} notification={n} />;
            })}
            <ListItem button onClick={markAll}>
              <ListItemIcon>
                <Icon>check</Icon>
              </ListItemIcon>
              <ListItemText primary="Mark All As Read" />
            </ListItem>
          </List>
        </div>
      </div>
    </Popover>
  );
};

ChatNotificationsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  markAll: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    notifications: getNewMessages(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    markAll: (payload) => {
      dispatch(chat.actions.updateChats(chat.constants.CHATS_UPDATE, payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ChatNotificationsMenu));
