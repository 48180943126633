import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import TextBox from './textBox';
import Button from './button';

import { createAction } from '../../commons/actions';
import { SIGN_IN } from '../../modules/auth/constants';

import { getStatus, getError } from '../../modules/auth/selectors';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--background-color)',
    height: '100vh',
  },

  textContainer: {
    border: '1px solid var(--text-border-color)',
    borderRadius: '3px',
    margin: '16px 0px',
    padding: '16px 8px',
    transition: 'border 0.7s',
    '&:hover': {
      border: '1px solid var(--text-border-color-hover)',
      transition: 'border 0.7s',
    },
    '&:focus': {
      border: '1px solid var(--text-border-color-focus)',
      transition: 'border 0.7s',
    },
  },
  companyPart1: {
    color: 'var(--text-light-color)',
    fontSize: '24px',
  },
  companyPart2: {
    color: 'var(--alternate-dark-color)',
    fontSize: '24px',
  },
  loginBackground: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  logoContainer: {
    textAlign: 'center',
  },
});

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      username: null,
      password: null,
    };

    // this.redirect = {
    //   whenAuthed: true,
    //   url: auth.constants.CLIENT_PAGE_URL,
    // };
  }

  handleSubmit = () => {
    const { redirect } = this.props;
    const { username, password } = this.state;
    const payload = {
      username,
      password,
      redirect,
    };

    const { signIn } = this.props;
    signIn(payload);
  };

  handleChange = (id) => (event) => {
    this.setState({ [id]: event.target.value });
  };

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  render() {
    const { classes, error } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.loginBackground}>
          <div className={classes.logoContainer}>
            <span className={classes.companyPart1}>OOX</span>
            <span className={classes.companyPart2}>change</span>
          </div>
          <TextBox
            icon="person"
            id="username"
            placeholder="Name"
            hover={!this.state.focus}
            onBlur={() => this.onBlur()}
            onFocus={() => this.onFocus()}
            onChange={this.handleChange}
          ></TextBox>
          <TextBox
            icon="lock"
            id="password"
            placeholder="Password"
            hover={!this.state.focus}
            onBlur={() => this.onBlur()}
            onFocus={() => this.onFocus()}
            onChange={this.handleChange}
          ></TextBox>

          <Button error={error} onClick={this.handleSubmit} />
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    themeType: state.themes.type,
    error: getError(state),
    status: getStatus(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (payload) => dispatch(createAction(SIGN_IN, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
