import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { TextField, FormControl } from '@material-ui/core';

import { textField } from './formFieldStyles';

const styles = (theme) => ({
  textField: textField(theme),
  inputTextHighlight: {
    color: lightBlue[300],
  },
});

const TextInputField = (props) => {
  const { classes, accessor, displayName, value, error } = props;

  return (
    <FormControl
      className={props.noMargin ? null : classes.textField}
      fullWidth={props.fullWidth}
      style={{ ...props.style }}
    >
      <TextField
        id={accessor}
        type="text"
        label={displayName}
        variant="filled"
        size="small"
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        value={value}
        onChange={props.handleChange(accessor)}
        error={props.error ? error.value : false}
        helperText={props.error ? error.message : ''}
        multiline={props.multiline}
        maxRows={props.maxRows}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disableUnderline: true,
          className: props.highlight ? classes.inputTextHighlight : null,
        }}
      />
    </FormControl>
  );
};

TextInputField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextInputField);
