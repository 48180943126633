import coreApi from '../../../commons/api';

class Api {
  constructor() {
    this.baseUrl = `/trades`;
    this.getAll = this.getAll.bind(this);
    this.get = this.get.bind(this);
  }

  async getAll(data = {}) {
    const url = `${this.baseUrl}`;

    const params = data.params || {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async get(tradeId = null, data = {}) {
    const url = `${this.baseUrl}/${encodeURIComponent(tradeId)}`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async update(tradeId = null, data = {}, dataType = 'buyerData') {
    const url = `/trades/${encodeURIComponent(tradeId)}/${dataType}Data`;

    const params = {};

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(data.body),
    };

    return coreApi.getResponse(url, params, options);
  }
  async updateCancel(tradeId = null, data = {}) {
    const url = `/trades/${encodeURIComponent(tradeId)}`;

    const params = {};

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(data.body),
    };

    return coreApi.getResponse(url, params, options);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
