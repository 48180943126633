import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { getTheme } from '../../../commons/selectors';
import { createAction } from '../../../commons/actions';
import * as actionTypes from '../actionTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Select,
  MenuItem,
  AppBar,
  InputLabel,
  FormControl,
  TextField,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import * as selectors from '../selectors';
import { ClearButton } from '../../../commons/components/buttons';
import DateField from '../../../commons/components/formFields/dateField';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.innerAlt,
    padding: theme.spacing(),
    border: '1px solid',
    borderColor: theme.palette.dividerAlt,
  },
  ratesContainer: {
    overflowY: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  responsiveContainer: {
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '32px',
    padding: '24px 8px',
  },
  headerTitle: {
    color: theme.palette.text.primaryLight,
    fontSize: theme.typography.fontSize * 1.5,
    letterSpacing: '1px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
});

const Statistics = (props) => {
  const [dates, setDates] = useState(getDates());
  useEffect(() => {
    const { id, widget } = props;
    const payload = {
      id,
      widget,
    };
    props.initStatistics(payload);

    return function cleanup() {
      const payload = {
        id: props.id,
      };
      props.destroyStatistics(payload);
    };
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      refresh();
    }, 15000);
    return () => clearInterval(interval);
  }, [dates]);

  const handleSegmentValueChange = (e, name) => {
    const value = e.target.value;
    const { id } = props;
    const payload = {
      id,
      action: 'update',
      name,
      value,
      dates,
    };

    props.updateStatistics(payload);
  };

  const handleDateChange = (name) => (date) => {
    //const val= date.format("yyyy-MM-DD")
    const dates = getDates(date);
    setDates(dates);
    const { id } = props;
    const payload = {
      id,
      action: 'refresh',
      dates,
    };

    props.updateStatistics(payload);
  };

  const refresh = () => {
    const { id } = props;
    const payload = {
      id,
      action: 'refresh',
      dates: dates,
    };
    props.updateStatistics(payload);
  };

  function getDates(dt = null) {
    const date = dt === null ? new Date() : new Date(dt);
    const next = dt === null ? new Date() : new Date(dt);
    next.setDate(next.getDate() + 1);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let day2 = next.getDate();
    let month2 = next.getMonth() + 1;
    let year2 = next.getFullYear();
    const createdDateFrom = `${year}-${month}-${day}`;
    const createdDateTo = `${year2}-${month2}-${day2}`;
    return {
      createdDateFrom,
      createdDateTo,
    };
  }

  const getString = (word) => {
    const words = word
      .match(/([A-Z]?[^A-Z]*)/g)
      .join(' ')
      .toUpperCase();

    return words;
  };
  const { classes, segmentValues, stats, theme } = props;
  const value = props.stats.species || '';
  const deliveryCountry = props.stats.deliveryCountry || '';
  const data = props.stats.stats || [];
  const createdDateFrom = dates.createdDateFrom;

  return (
    <div className={` ${classes.container} undraggable`}>
      <div className={classes.header}>
        <FormControl>
          <InputLabel>Species</InputLabel>
          <Select
            value={String(value)}
            label="Fred"
            disableUnderline
            className={classes.headerTitle}
            onChange={(e) => handleSegmentValueChange(e, 'species')}
          >
            {props.valueEnums.species.map((seg) => {
              return (
                <MenuItem key={seg} value={seg}>
                  {getString(seg)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel style={{ width: 250 }}>Delivery Country</InputLabel>
          <Select
            value={String(deliveryCountry)}
            disableUnderline
            className={classes.headerTitle}
            onChange={(e) => handleSegmentValueChange(e, 'deliveryCountry')}
          >
            {props.valueEnums.deliveryCountry.map((seg) => {
              return (
                <MenuItem key={seg} value={seg}>
                  {getString(seg)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <DateField
          accessor={'createdDateFrom'}
          displayName={'Trade Date'}
          value={createdDateFrom}
          disablePast={false}
          handleDateChange={handleDateChange}
          clearable={false}
          width={124}
        />

        {/* <ClearButton title="Refresh" icon="refresh" onClick={refresh}></ClearButton> */}
      </div>

      <div className={classes.responsiveContainer}>
        <ResponsiveContainer width="99%" height="97%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="1 1" stroke={theme.palette.dividerAlt} />
            <XAxis
              type="number"
              stroke={theme.palette.text.primaryLight}
              axisLine={false}
              unit=" CTNS"
            />
            <YAxis type="category" dataKey="name" stroke={theme.palette.text.primary} width={190} />
            <Tooltip
              wrapperStyle={{
                width: 200,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
              contentStyle={{
                backgroundColor: theme.palette.background.row,
                color: theme.palette.text.primary,
              }}
              cursor={{ fill: theme.palette.background.row }}
            />
            <Legend
              verticalAlign="top"
              height="24px"
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
              wrapperStyle={{ top: -5 }}
            />
            <Bar dataKey="Offers" fill={theme.palette.lightBlue} />
            <Bar dataKey="Indicative" fill={theme.palette.amber} />
            <Bar dataKey="Trades" fill={theme.palette.teal} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

Statistics.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id, widgetId, widget } = props;
  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    valueEnums: selectors.getValueEnums(state),
    theme: getTheme(state),
    stats: selectors.getLocalStatisticsById(state, cId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStatistics: (payload) => {
      dispatch(createAction(actionTypes.UPDATE_STATISTICS, payload, { receivedAt: new Date() }));
    },
    initStatistics: (payload) => {
      dispatch(createAction(actionTypes.INIT_STATISTICS, payload, { receivedAt: new Date() }));
    },
    destroyStatistics: (payload) => {
      dispatch(createAction(actionTypes.DESTROY_STATISTICS, payload, { receivedAt: new Date() }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Statistics));
