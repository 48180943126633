import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Grid, Switch, Typography } from '@material-ui/core';

const styles = (theme) => ({
  off: {
    color: theme.palette.text.disabled,
  },
  on: {
    color: theme.palette.amber_500,
  },
});

const SwitchField = (props) => {
  const { value, classes } = props;

  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={0}>
        <Grid item className={props.checked ? classes.off : null}>
          {props.offLabel}
        </Grid>
        <Grid item>
          <Switch checked={!!props.checked} onChange={props.handleChecked} value={value} />
        </Grid>
        <Grid item className={!props.checked ? classes.off : classes.on}>
          {props.onLabel}
        </Grid>
      </Grid>
    </Typography>
  );
};

SwitchField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SwitchField);
