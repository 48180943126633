import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    paddingRight: theme.spacing() / 2,

    alignItems: 'center',
  },
  column1: {
    flex: 1,
    padding: '4px',
  },
  column2: {
    display: 'flex',
    flex: 10,
    flexDirection: 'row',
    //padding: '4px',
  },

  text: {
    textAlign: 'center',
    color: theme.palette.text.subtitle,
    flex: 1,
  },
  textSubHeaders: {
    textAlign: 'center',
    fontSize: '1.3rem',
    color: theme.palette.text.subtitle,
    flex: 1,
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },

  subheaders: {
    border: `1px solid ${theme.palette.divider}`,
    borderBottomWidth: 0,
    backgroundColor: darken(theme.palette.background.banner, 0.1),
    //padding: theme.spacing(),
  },
});

const GridHeader = (props) => {
  const { classes } = props;

  const subSegment = props.weightClasses ? 'weightClasses' : 'weightClass';
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.column1}>
            <div className={classes.text}>ORG</div>
          </div>
          <div className={classes.column2}>
            <div className={classes.text}>WGT CLASSES</div>
          </div>
          <div className={classes.column3} />
        </div>
        <div className={classes.row}>
          <div className={classes.column1} />

          <div className={`${classes.column2} ${classes.subheaders}`}>
            {props[subSegment].map((w) => (
              <div key={w} className={classes.textSubHeaders}>
                {w}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

GridHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridHeader);
