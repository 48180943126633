import {
  GET_ORGANISATIONS,
  GET_ORGANISATIONS_SUCCESS,
  GET_ORGANISATIONS_ERROR,
  GET_ORGANISATION,
  GET_ORGANISATION_SUCCESS,
  GET_ORGANISATION_ERROR,
  GET_MY_ORGANISATION,
  GET_MY_ORGANISATION_SUCCESS,
  GET_MY_ORGANISATION_ERROR,
  RESET_ORGANISATIONS,
} from '../constants';

import { insertItems } from '../../../commons/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  myOrganisation: [],
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANISATIONS:
      return {
        ...state,
        status: 'pending',
      };
    case GET_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_ORGANISATIONS_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case GET_ORGANISATION:
      return {
        ...state,
        status: 'pending',
      };
    case GET_ORGANISATION_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_ORGANISATION_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case GET_MY_ORGANISATION:
      return {
        ...state,
        status: 'pending',
      };
    case GET_MY_ORGANISATION_SUCCESS:
      return {
        ...state,
        myOrganisation: action.payload.item,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_MY_ORGANISATION_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case RESET_ORGANISATIONS:
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
