import { fork } from 'redux-saga/effects';

import auth from '../../modules/auth';
import themes from '../../modules/themes';

import watchInitProccess from './client';

export default function* root() {
  yield fork(auth.sagas.watchCheckAuth);
  yield fork(auth.sagas.watchSignIn);
  yield fork(auth.sagas.watchBackToSignIn);
  yield fork(auth.sagas.watchSignInNewPasswordSent);

  yield fork(themes.sagas.watchInitTheme);
  yield fork(themes.sagas.watchChangeTheme);

  yield fork(watchInitProccess);
}
