import coreApi from '../../../commons/api';

class Api {
  constructor() {
    this.baseUrl = `/files`;

    this.getAll = this.getAll.bind(this);
    this.get = this.get.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
  }

  async getAll(data = {}) {
    const url = `${this.baseUrl}`;

    const params = data.params || {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async get(fileId = null, data = {}) {
    const url = `${this.baseUrl}/${encodeURIComponent(fileId)}`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }
  async getContent(fileId = null, data = {}) {
    //const url = `${this.baseUrl}/${encodeURIComponent(fileId)}/content`;
    const url = `/files/${encodeURIComponent(fileId)}/content`;
    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponseContent(url, params, options);
  }

  async create(data) {
    const url = `${this.baseUrl}`;

    const params = data.params || {};

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${data.token}`,
        //'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
        //'Content-Type': 'multipart/form-data',
      },
      body: data.body,
      noContentHeader: true,
    };
    return coreApi.getResponse(url, params, options);
  }

  async delete(fileId = null, data = {}) {
    const url = `/files/${encodeURIComponent(fileId)}`;
    //const url = `${this.baseUrl}`;
    const params = data.params || {};

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };
    return coreApi.getResponseDelete(url, params, options);
  }

  async update(data = {}) {
    const url = `${this.baseUrl}/${encodeURIComponent(data.body.orderId)}`;

    const params = data.params || {};

    const { orderId, ...body } = data.body;

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(body),
    };

    return coreApi.getResponse(url, params, options);
  }

  async updateMeta(fileId = null, data = {}) {
    //const url = `${this.baseUrl}/${encodeURIComponent(fileId)}`;
    const url = `/files/${encodeURIComponent(fileId)}`;
    const params = {};

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(data.body),
    };

    return coreApi.getResponse(url, params, options);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
