import { createSelector } from 'reselect';
import { NAME } from '../constants';

import auth from '../../auth';
import organisations from '../../organisations';
import { getUserNotificationSettings } from '../../auth/selectors';

const isTradingPartnersListOpenProp = (state) => {
  const form = state[NAME].tradingPartnersList;
  return form.open;
};
export const isTradingPartnersListOpen = createSelector(
  isTradingPartnersListOpenProp,
  (items) => items,
);

const isNotificationSettingsOpenProp = (state) => {
  const form = state[NAME].notificationsSettings;
  return form.open;
};

export const isNotificationSettingsOpen = createSelector(
  isNotificationSettingsOpenProp,
  (items) => items,
);

const isGeneralSettingsOpenProp = (state) => {
  const form = state[NAME].generalSettings;
  return form.open;
};

export const isGeneralSettingsOpen = createSelector(isGeneralSettingsOpenProp, (items) => items);

const getSavedRfqPresetsProp = (state) => {
  const form = state[NAME].generalSettings;
  return form.savedRfqPresets;
};

export const getSavedRfqPresets = createSelector(getSavedRfqPresetsProp, (items) => items);

const isBlotterPresetsOpenProp = (state) => {
  const form = state[NAME].blotterPresets;
  return form.open;
};

export const isBlotterPresetsOpen = createSelector(isBlotterPresetsOpenProp, (items) => items);

const getSavedBlotterPresetsProp = (state) => {
  const form = state[NAME].blotterPresets;
  return form.savedBlotterPresets;
};

export const getSavedBlotterPresets = createSelector(getSavedBlotterPresetsProp, (items) => items);

const notificationTypesProp = (state) => state[NAME].notificationTypes.items;

export const getNotificationTypes = createSelector(
  notificationTypesProp,
  (items) => items.notificationTypes,
);

// TODO: Extract method into one module and invoke this method
export const getTradingPartners = createSelector(
  organisations.selectors.getMyOrganisation,
  organisations.selectors.getAllObject,

  (myOrganisation, organisations) => {
    const tradingPartners = [];
    const tradingPartnersIds = myOrganisation ? myOrganisation.tradingPartnersIds || [] : [];
    tradingPartnersIds.forEach((id) => {
      const tradingPartner = organisations[id];
      if (tradingPartner !== null && tradingPartner !== undefined) {
        tradingPartners.push(tradingPartner);
      }
    });

    tradingPartners.sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return tradingPartners;
  },
);

const getConfig = (setting) => {
  if (setting && setting.channels) {
    return Object.entries(setting.channels)
      .filter((item) => item[1] === true)
      .map((item) => item[0]);
  }
  return [];
};

const notificationDefaults = () => {
  return [
    {
      id: "orderCreated",
      name: "OrderCreated"
  },
  {
      id: "orderUpdated",
      name: "OrderUpdated"
  },
  {
      id: "rfqCreated",
      name: "RfqCreated"
  },
  {
      id: "rfqEnded",
      name: "RfqEnded"
  },
  {
      id: "tradeCreated",
      name: "TradeCreated"
  },
  {
      id: "tradeUpdated",
      name: "TradeUpdated"
  },
  {
      id: "chatMessageCreated",
      name: "ChatMessageCreated"
  }
  ];
};

export const getNotificationSettings = createSelector(
  [getUserNotificationSettings, getNotificationTypes],
  (settings = [], types = notificationDefaults()) => {
    return types.map(({ id, name }) => {
      const current = settings.find(({ notificationTypeId }) => notificationTypeId === id);
      return {
        id,
        name,
        value: getConfig(current),
      };
    });
  },
);
