import lightBlue from '@material-ui/core/colors/lightBlue';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
//import { rgb } from 'color';
// import { rgbToHex } from "@material-ui/core/styles/colorManipulator";

export const dark = {
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: 'none',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue['400'],
      },
    },
    MuiPickersDay: {
      // day: {
      //   color: lightBlue.A700,
      // },
      isSelected: {
        backgroundColor: lightBlue['400'],
      },
      current: {
        color: lightBlue['900'],
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '2px',
        marginBottom: '2px',
      },
    },
    MuiInput: {
      input: {
        underline: {
          '&:after': {
            borderBottom: `0px !important`,
          },
        },
      },
      formControl: {
        'label + &': {
          //marginTop: '20px',
        },
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //fontSize: '16px',
        padding: 2,
        '&$focused': {
          // increase the specificity for the pseudo class
          color: 'rgba(225,225,255,.8)',
        },
      },
      shrink: {
        transform: 'translate(6px, 6px) scale(0.85) !important',
      },
      marginDense: {
        transform: 'translate(6px, 6px) scale(0.85) !important',
      },
    },

    MuiInputAdornment: {
      root: {
        '& [class*="MuiTypography-root"]': {
          //fontSize: '0.7rem',
          whiteSpace: 'normal',
        },
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: '48px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    // PrivateSwitchBase :{
    //   root:{
    //     padding:0
    //   }
    // },

    MuiChip: {
      root: {
        minHeight: '18px',
        height: 'none',
        //fontSize: '14px',
        backgroundColor: 'rgba(255,255,255,.2)',
      },
    },
    MuiAvatar: {
      img: {
        backgroundColor: 'transparent',
      },
      root: {
        height: '32px',
        width: '32px',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: lightBlue[700],
        rippleBackgroundColor: lightBlue[700],
        opacity: 1.0,
        filter: 'alpha(opacity=100)',
        fontSize: '1em',
      },
      popper: {
        // backgroundColor: lightBlue[400],
        // rippleBackgroundColor: lightBlue[400],
        opacity: 1.0,
        filter: 'alpha(opacity=100)',
      },
    },
    MuiButton: {
      textPrimary: {
        color: 'rgb(255,255,255)',
      },
      root: {
        lineHeight: 1,
      },
    },

    MuiToolbar: {
      gutters: {
        paddingLeft: '16px',
        paddingRight: '16px',
        '@media (min-width: 600px)': {
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
    MuiInputBase: {
      inputMarginDense: {
        paddingTop: '5px',
        paddingBottom: '2px',
      },
    },
    MuiTypography: {
      root: {
        padding: '0px 4px',
        fontSize: '1em',
      },
      // body1: {
      //   padding: '0px 4px',
      //   fontSize: '1em',
      // },
    },
    MuiSwitch: {
      switchBase: {
        color: '#ccc',
      },
      colorSecondary: {
        '&$checked': {
          color: amber[500],
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: '#fff',
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: amber[500],
        },
      },
    },
    MuiTabs: {
      fixed: { backgroundColor: '#20586c' },
    },
  },

  palette: {
    type: 'dark',

    background: {
      paper: '#20586c',
      default: '#0b353f',
      widget: '#20586c',
      appBar: '#20586c',
      banner: '#204a5a',
      rfqTabBuy: 'rgb(24,59,85)',
      rfqTabSell: 'rgb(24,56,64)',
      userTab: 'rgb(255,0,0)',
      userTabold: 'rgb(30,40,54)',
      tile: 'rgb(0,0,0,.2)',
      row: '#1e5164',
      darkRow: '#07272e',
      innerAlt: '#0b353f',
      inner: '#1d4f61',
      bidBackground: '#0b5161',
      offerBackground: '#084949',
      card: '#135A6A',
      filled: 'rgba(255,255,255,.09)',
    },
    common: { black: '#000', white: '#fff' },
    primary: {
      main: '#20586c', //color for headers  i.e. "RFQ VIEWER"
      dark: '#1b3e4d',
      // light: "rgba(30, 40, 54 ,1)",
      row: '#1d3d48',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgb(255, 138, 123)',
      main: 'rgb(219, 89, 79)',
      dark: 'rgb(164, 39, 38)',
      contrastText: '#fff',
    },

    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },

    text: {
      primary: '#fff',
      primaryLight: 'rgb(225,225,225)',
      opacity: 'rgb(255,255,255,.3)',
      secondary: 'rgba(225,225,255,.50)',
      disabled: 'rgba(93, 117, 152, .87)',
      hint: '#30a9a4',
      subtitle: '#30a9a4',
    },
    divider: 'rgba(255,255,255,.1)',
    dividerAlt: '#38697a',
    tableBorder: 'rgba(255,255,255,.05)',
    scrollbar: 'rgba(0,0,0,.3)', //#103646',
    chatMessages: 'rgb(240,240,240)',
    teal: teal['400'],
    teal_dark: teal['900'],
    teal_A400: teal['A400'],
    lightBlue: lightBlue['400'],
    lightBlue_A400: lightBlue['A400'],
    amber: '#c96001',
    amber_dark: '#4a422a',
    amber_A400: amber['A400'],
    amber_500: amber['500'],
    red: red[400],
    bidBorder: '#23aec9',
    offerBorder: '#24bbac',
  },

  fontSize: {
    xxxl: '3.0rem',
    xxl: '2.0rem',
    xl: '1.5rem',
    lg: '1.25rem',
    md: '1.0rem',
    sm: '.875rem',
    xs: '.75rem',
    xxs: '.5rem',
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    //htmlFontSize: 18,
  },
};
