import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Modal, Typography, Card } from '@material-ui/core';

import { directions, status } from '../../../../commons/models/constants';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';
import { CancelButton, ConfirmButton, ClearButton } from '../../../../commons/components/buttons';

import { getContractWeek } from '../../../contracts/utils/deliveryWeeks';

import ExecuteTradeFormRow from './ExecuteTradeFormRow';
import TotalsRow from '../TotalsRow';

import { updateForm } from '../../actions';
import { UPDATE_RESPONSE_FORM } from '../../constants';
import orders from '../../../orders';
import {
  isOpenFormExecute,
  getRfqExecuteForm,
  getOrdersExecuteForm,
  getExecuteFormFields,
  getResponseFormFields,
  getActiveUser,
  getActiveUserOrganisation,
  getOrganisations,
  getOrganisationsObject,
  getOrderIdToCancelExecuteForm,
} from '../../selectors';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '98vw',
    height: '95vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  paperNotRfqOwner: {
    position: 'absolute',

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.inner,
  },

  section: {
    marginTop: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
  },
  sectionFlex: {
    marginTop: theme.spacing(0.5),
    //flex: 1,
    //display: "flex",
    //flexDirection: "column",
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
  },
  totals: {
    display: 'flex',
    flexDirection: 'column',
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    //overflowY: 'scroll',
  },

  banner: {
    //backgroundColor: theme.palette.primary.dark,
    //padding: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.typography.fontSize,
  },

  title: { fontSize: theme.fontSize * 1.5 },
  comments: {
    padding: '8x !important',
    margin: '2.47px',
  },
});

class ExecuteTradeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      orderRows: [],
      showDetails: false,
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChecked = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  submit = () => {
    this.sendOrder();
  };

  getDirection = (direction) => {
    let value = directions.BUY;
    if (direction === directions.BUY) {
      value = directions.SELL;
    }
    return value;
  };

  sendOrder = () => {
    let orderRows = this.props.orders;
    const { orderIdToCancel } = this.props;

    if (true) {
      const createOrderPayload = {
        orderRows,
        textMessage: this.state.textMessage,
      };

      if (orderIdToCancel) {
        const orderId = orderIdToCancel;
        const orderToAmend = {
          orderId,
          status: status.CANCELLED,
        };
        const payload = { items: [orderToAmend] };

        this.props.updateOrders(payload);
      }
      this.props.createOrder(createOrderPayload);
      this.setState({ textMessage: '' });
      this.closeForm();
    }
  };

  closeForm = () => {
    const payload = {
      type: 'execute',
      action: 'closeForm',
    };

    this.props.updateResponseForm(payload);
  };

  render() {
    const { classes, orders, open, formFields, contract, activeRfq, user } = this.props;
    const orderRows = orders || [];
    const orderRow = orders[0] || {};

    const direction = orderRow.direction || directions.BUY;
    const client = orderRow.selectedOrganisationsIds
      ? orderRow.selectedOrganisationsIds[0].name
      : '';
    const isRfqRequestor = activeRfq ? activeRfq.organisationId === user.organisationId : false;
    const orderRowFields = formFields.allFields || null;
    const orderRowFieldKeys = orderRowFields ? Object.keys(orderRowFields) : [];

    const formFieldsTop = orderRowFieldKeys.filter((f) => orderRowFields[f].formTopSection);
    const formFieldsLeft = orderRowFieldKeys.filter((f) => orderRowFields[f].formLeftSection);
    const formFieldsRight = orderRowFieldKeys.filter((f) => orderRowFields[f].formRightSection);
    const formFieldsBottom = orderRowFieldKeys.filter((f) => orderRowFields[f].formBottomSection);

    const FIELD_WIDTH = '150px';
    return (
      <Modal open={open} onClose={this.closeForm}>
        <div
          style={getModalStyle()}
          className={this.state.showDetails ? classes.paper : classes.paperNotRfqOwner}
        >
          <Typography className={classes.title}>Execute Trade?</Typography>
          <div className={classes.container}>
            <div className={classes.section}>
              <div className={classes.subsection}>
                <ReadOnlyField displayName={'Direction'} value={direction} fullWidth />
                <ReadOnlyField displayName={'Client'} value={client} fullWidth />
                <ReadOnlyField
                  displayName={'Validity'}
                  value={orderRow.timeInForce !== null ? 'TIME IN FORCE' : 'GTC'}
                  fullWidth
                />

                {orderRow.timeInForce !== null && (
                  <ReadOnlyField displayName={'Validity'} value={orderRow.timeInForce} fullWidth />
                )}
              </div>
            </div>

            <div className={classes.sectionFlex}>
              {this.state.showDetails && (
                <React.Fragment>
                  <div style={{ flex: 1 }}>
                    <Typography className={classes.banner}>{`COMPANY`}</Typography>

                    <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                      {orderRowFields &&
                        formFieldsTop.map((field) => {
                          if (orderRowFields[field] && orderRow) {
                            return (
                              <ReadOnlyField
                                displayName={orderRowFields[field].displayName}
                                value={orderRow[field] || ''}
                                width={FIELD_WIDTH}
                              />
                            );
                          }
                        })}
                    </Card>
                  </div>
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      <Typography className={classes.banner}>{`PRODUCT`}</Typography>

                      <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                        {orderRowFields &&
                          formFieldsLeft.map((field) => {
                            if (orderRowFields[field] && orderRow) {
                              return (
                                <ReadOnlyField
                                  displayName={orderRowFields[field].displayName}
                                  value={orderRow[field] || ''}
                                  width={FIELD_WIDTH}
                                />
                              );
                            }
                          })}
                      </Card>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography className={classes.banner}>{`FREIGHT`}</Typography>

                      <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                        {orderRowFields &&
                          formFieldsRight.map((field) => {
                            if (orderRowFields[field] && orderRow) {
                              return (
                                <ReadOnlyField
                                  displayName={orderRowFields[field].displayName}
                                  value={orderRow[field] || ''}
                                  width={FIELD_WIDTH}
                                />
                              );
                            }
                          })}
                      </Card>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography className={classes.banner}>{`OFFER`}</Typography>

                    <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                      {orderRowFields &&
                        formFieldsBottom.map((field) => {
                          if (orderRowFields[field] && orderRow) {
                            return (
                              <ReadOnlyField
                                displayName={orderRowFields[field].displayName}
                                value={orderRow[field] || ''}
                                width={FIELD_WIDTH}
                              />
                            );
                          }
                        })}
                    </Card>
                  </div>
                </React.Fragment>
              )}
              <div style={{ flex: 1 }}>
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <ClearButton
                    icon="visibility"
                    title="Details"
                    onClick={() => this.setState({ showDetails: !this.state.showDetails })}
                  />
                </div>

                <div className={classes.orders}>
                  {orders.map((row, index) => {
                    return (
                      <ExecuteTradeFormRow
                        key={row.id}
                        orderRow={row}
                        contract={contract}
                        activeRfq={activeRfq}
                        index={index}
                        formFields={formFields.defaultRowFields}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.totals}>
            <TotalsRow rows={orderRows} direction={direction} />
          </div>
          <ToolbarBottom>
            <>
              <CancelButton onClick={this.closeForm} />
              <ConfirmButton title="TRADE" onClick={() => this.submit()} />
            </>
          </ToolbarBottom>
        </div>
      </Modal>
    );
  }
}

ExecuteTradeForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    orders: getOrdersExecuteForm(state),
    rfq: getRfqExecuteForm(state),
    formFields: getExecuteFormFields(state),
    open: isOpenFormExecute(state),
    orderIdToCancel: getOrderIdToCancelExecuteForm(state),
    user: getActiveUser(state),
    userOrganisation: getActiveUserOrganisation(state),
    organisations: getOrganisations(state),
    organisationsObject: getOrganisationsObject(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createOrder: (payload) => {
      dispatch(orders.actions.createOrder(orders.constants.CREATE_ORDER_FROM_RFQ, payload));
    },
    updateResponseForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
    updateOrders: (payload) => {
      dispatch(orders.actions.updateOrders(orders.constants.UPDATE_ORDERS, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecuteTradeForm));
