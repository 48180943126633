import { createSelector } from 'reselect';
import { NAME } from '../constants';
/* 
import contracts from '../../contracts';
import organisations from '../../organisations';
import auth from '../../auth';
import users from '../../users';
import orders from '../../orders';
import settings from '../../settings';
import schemas from '../../schemas';
import * as marketSelectors from '../../market/selectors';

import { getFromLS } from '../../../commons/localStorage'; */

export const isOpenRequestForm = createSelector((state) => {
  const { request } = state[NAME].forms;
  const value = request.open;
  return value;
});
