import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Avatar,
  FormControl,
  InputLabel,
  Input,
  Box,
  Icon,
  Button,
  Card,
} from '@material-ui/core';

import NumberFieldFilled from '../../../../commons/components/formFields/numberFieldFilled';
import NumberFieldNew from '../../../../commons/components/formFields/numberFieldNew';
import NumberFieldCustom from '../../../../commons/components/formFields/numberFieldCustom';
import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';
import { errorMessages } from '../../../../commons/models/constants';
import { checkPrice, checkVolume } from '../../../../commons/config/formatters';
import contractType from '../../../contracts/models/contractType';
import orderUnderlying from '../../../contracts/models/orderUnderlying';

const styles = (theme) => ({
  tableCellCalculator: {
    padding: 0,
    '&:last-child': {
      paddingRight: '0px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    //backgroundColor: 'rgba(0,0,0,.1)',
  },
  containerOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    //backgroundColor: 'rgba(0,0,0,.2)',
  },
  containerItems: {
    padding: theme.spacing(1),
  },
  rowNotVisible: {
    height: '1px',
  },
  rowMargin: {
    marginBottom: theme.spacing(),
  },
  small: {
    width: theme.spacing(2.4),
    height: theme.spacing(2.4),
    backgroundColor: theme.palette.text.primary,
  },
});

// TODO: Extract all functionality to saga ans store
class AmendFormRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      showCalculator: false,
    };
  }

  checkValid = (key, value) => {
    const returnValue = { message: '', value: false };
    switch (key) {
      case 'price':
        const price = checkPrice(value);
        returnValue.value = !price;
        returnValue.message = price ? '' : errorMessages.price;
        break;
      case 'volume':
        const volume = checkVolume(value);

        returnValue.value = !volume;
        returnValue.message = volume ? '' : errorMessages.volume;
        break;
      case 'targetOrganisations':
        returnValue.value = value.length !== 0;
        returnValue.message = errorMessages.targetOrganisations;
        break;
      default:
    }
    return returnValue;
  };

  handleChange = (name) => (event) => {
    const { errors } = this.state;
    const valid = this.checkValid(name, event.target.value);

    errors[name] = valid;

    this.setState({
      errors,
      [name]: event.target.value,
    });
    const payload = {
      type: 'amend',
      action: 'updateOrderRow',
      key: this.props.orderRow.key,
      field: name,
      value: event.target.value,
    };

    this.props.updateOrderRow(payload);
  };

  getErrors = (key) => {
    if (this.state.errors[key]) {
      return this.state.errors[key];
    }
    return { message: '', value: false };
  };

  getShowFreezeDate = () => {
    if (this.props.activeRfq) {
      if (this.props.activeRfq.contract) {
        if (this.props.activeRfq.contract.underlying.states) {
          if (
            this.props.activeRfq.contract.underlying.states.includes('FROZEN') ||
            this.props.activeRfq.contract.underlying.states.includes('ANY')
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  render() {
    const { classes, formFields } = this.props;

    const orderRow = this.props.orderRow || {};
    const subSegment = orderUnderlying.fields[contractType.subSegment];
    const FIELD_WIDTH = '182px';
    const isOdd = this.props.index % 2 ? classes.containerOdd : classes.container;
    return (
      <Card style={{ margin: '4px', display: 'flex', flexDirection: 'row' }}>
        {/*  <div className={classes.containerItems}>
          <IconButton>
            <Avatar className={classes.small}>{this.props.index + 1}</Avatar>
          </IconButton>
        </div> */}

        <div className={classes.containerItems} style={{ flex: 1 }}>
          <ReadOnlyField
            displayName={subSegment.displayName}
            value={orderRow[subSegment.accessor]}
            width={FIELD_WIDTH}
          />
          <NumberFieldCustom
            accessor={'price'}
            key={`price-${orderRow.key}`}
            displayName={formFields['price'].displayName}
            value={orderRow['price']}
            handleChange={this.handleChange}
            error={this.getErrors('price')}
            adornment={orderRow[formFields['price'].adornment]}
            width={FIELD_WIDTH}
            step={formFields['price'].step}
          />
          <NumberFieldCustom
            accessor={'volume'}
            key={`volume-${orderRow.key}`}
            displayName={formFields['volume'].displayName}
            value={orderRow['volume']}
            handleChange={this.handleChange}
            error={this.getErrors('volume')}
            adornment={'CTNS'}
            width={FIELD_WIDTH}
            step={formFields['volume'].step}
          />

          {/* {Object.keys(formFields).map((field) => {
            if (formFields[field].amendable) {
              return (
                <NumberFieldFilled
                  accessor={field}
                  key={`${field}-${orderRow.key}`}
                  displayName={formFields[field].displayName}
                  value={orderRow[field]}
                  handleChange={this.handleChange}
                  error={this.getErrors(field)}
                  adornment={orderRow[formFields[field].adornment]}
                  width={FIELD_WIDTH}
                  step={formFields[field].step}
                />
              );
            }
            return null;
          })} */}
          {/*  {Object.keys(formFields).map((field) => {
            if (!formFields[field].amendable) {
              return (
                <ReadOnlyField
                  displayName={formFields[field].displayName}
                  value={orderRow[field]}
                  width={FIELD_WIDTH}
                />
              );
            }
            return null;
          })} */}
        </div>
      </Card>
    );
  }
}

AmendFormRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AmendFormRow);
