import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';
import clsx from 'clsx';
const styles = (theme) => ({
  componentContainer: {
    margin: '16px 0px',
  },
  textContainer: {
    border: '1px solid var(--text-border-color)',
    borderRadius: '3px',

    padding: '16px 8px',
    transition: 'border 0.7s',
  },
  textContainerHover: {
    '&:hover': {
      border: '1px solid var(--text-border-color-hover)',
      transition: 'border 0.7s',
    },
  },
  textContainerFocus: {
    border: '1px solid var(--text-border-color-focus)',
    transition: 'border 0.7s',
  },
  textField: {
    border: 'none',
    fontSize: '16px',
    color: 'var(--text-light-color)',
    outline: 'none !important',
    width: '300px',
    backgroundColor: 'transparent',
    '&::placeholder ': {
      color: 'var(--placeholder)',
      opacity: 1,
    },
  },
  icon: {
    color: 'var(--alternate-dark-color)',
    verticalAlign: 'middle',
  },
  errorMessage: {
    color: theme.palette.error.main,

    textAlign: 'center',
  },
  hasError: {},
});

const TextBox = (props) => {
  const { classes } = props;
  const [focus, setFocus] = useState(false);
  return (
    <div className={classes.componentContainer}>
      <div
        className={clsx(classes.textContainer, {
          [classes.textContainerHover]: props.hover,
          [classes.textContainerFocus]: focus,
        })}
      >
        <span className={classes.icon}>
          <Icon>{props.icon}</Icon>
        </span>
        <span>
          <input
            className={classes.textField}
            id={props.id}
            type={props.id === 'password' ? 'password' : 'text'}
            placeholder={props.placeholder}
            onFocus={function () {
              props.onFocus();
              setFocus(true);
            }}
            onBlur={function () {
              props.onBlur();
              setFocus(false);
            }}
            onChange={props.onChange(props.id)}
          />
        </span>
      </div>
      <div
        className={clsx(classes.errorMessage, {
          [classes.hasError]: props.errorMessage,
        })}
      >
        {props.errorMessage}
      </div>
    </div>
  );
};

TextBox.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextBox);
