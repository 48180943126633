// TODO: split this saga into smaller ones
import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import {
  GET_RFQS_SUCCESS,
  GET_RFQS_ERROR,
  GET_RFQ_SUCCESS,
  GET_RFQ_ERROR,
  CREATE_RFQ_SUCCESS,
  CREATE_RFQ_ERROR,
  GET_RFQS,
  RESET_RFQS,
  CREATE_RFQ,
  GET_RFQ,
  UPDATE_RFQS,
  UPDATE_RFQS_ERROR,
  UPDATE_RFQS_SUCCESS,
  INSERT_RFQS_FROM_SOCKET,
  INSERT_RFQS_FROM_SOCKET_SUCCESS,
  INSERT_RFQS_FROM_SOCKET_ERROR,
  UPDATE_RFQS_FROM_SOCKET,
  UPDATE_RFQS_FROM_SOCKET_SUCCESS,
  UPDATE_RFQS_FROM_SOCKET_ERROR,
  UPDATE_FORM,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_ERROR,
  UPDATE_RFQ_VIEWS,
  UPDATE_RFQ_VIEWS_SUCCESS,
  UPDATE_RFQ_VIEWS_ERROR,
  UPDATE_RFQ_DISMISSED,
  UPDATE_RFQ_DISMISSED_SUCCESS,
  UPDATE_RFQ_DISMISSED_ERROR,
  UPDATE_RESPONSE_FORM,
  UPDATE_RESPONSE_FORM_SUCCESS,
  UPDATE_RESPONSE_FORM_ERROR,
} from '../constants';

import {
  getRFQs,
  getRFQ,
  createRFQ,
  updateRFQs,
  resetRFQs,
  insertRFQs,
  updateForm as actionUpdateForm,
  updateRFQs as actionUpdateRFQs,
} from '../actions';

import { getFromLS, saveToLS, removeFromLS } from '../../../commons/localStorage';

import api from '../api';

import auth from '../../auth';
import organisationsModule from '../../organisations';

import {
  getRfqFormOrder,
  getRfqResponseForm,
  getViews,
  getDismissed,
  getLastUpdatedTime,
  getActiveUser,
  getOrdersResponseForm,
  getDefaultOrdersResponseForm,
  getOrdersAmendForm,
  getRfqOrderRows,
  getMyOrganisation,
} from '../selectors';

import { getDatesFromWeek } from '../../contracts/utils/deliveryWeeks';

import { isObject, createId } from '../../../commons/utils/functions';
import { SOCKET_RECONNECTED } from '../../socket/actionTypes';

import {
  getContractType,
  getRfqResponseFromOrder,
  getRfqResponseItem,
  getRfqResponseDefaultItem,
  getRfqRespsonseEnumerations,
  getFieldsObject,
  getDefaultFormState,
  getEnumerations,
  getFieldsArray,
  getDefaultRfqFromOrder,
  getUpdatedRfqFormState,
  createApiRfq,
  mapDayToInteger,
  checkRfqState,
  checkRfqStateAgainstEnums,
  checkRfqOrderRowsStateAgainstEnums,
  getFresh,
} from '../../contracts/utils/modelFunctions';

import marketSegments from '../../contracts/models/marketSegments';
import { isArray } from 'lodash';
/**
 *
 * @param {*} action
 */
export function* getAll() {
  try {
    // TODO: move into interceptor
    const token = yield call(auth.selectors.getToken);

    const options = {
      token,
      params: {},
    };

    const lastUpdatedTime = yield select(getLastUpdatedTime);

    if (lastUpdatedTime) {
      options.params.updatedTimeFrom = lastUpdatedTime;
    }

    const RFQs = yield call(api.getAll, options);

    const payload = {
      items: RFQs,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getRFQs(GET_RFQS_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getRFQs(GET_RFQS_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* get() {
  try {
    const RFQ = yield call(api.get);

    const payload = {
      items: [RFQ],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getRFQ(GET_RFQ_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getRFQ(GET_RFQ_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
// TODO: Refactor for processing and prepare data. extract and dynamic
function* create(action) {
  try {
    const rfqState = yield select(getRfqFormOrder);
    const orderRows = action.payload.orderRows || {};
    rfqState.orderRows = orderRows;
    const rfq = createApiRfq(rfqState);

    /* const rfq = {
      contract: {
        deliveryDate: {
          to: '2023-01-24',
          from: '2023-01-24',
        },
        packingDate: {
          to: '2023-01-22',
          from: '2023-01-22',
        },
        consignee: '',
        instrument: 'SPOT',
        rfqContractUnderlying: {
          cartonNetWeight: ['fifteen'],
          colour: ['sixtyPercent'],
          currency: 'AUD',
          deliveryPoint: 'ANY',
          flightNumber: '',
          incoTerms: 'FCA',
          location: 'ANY',
          mortalityRateAllowance: ['fivePercent'],
          packaging: 'polycarton',
          paymentTerms: ['prepayment'],
          presentation: 'live',
          product: 'spinyLobster',
          region: 'queensland',
          species: 'southernRock',
          unit: 'KG',
          weightAllowance: ['threePercent'],
          weightClass: ['AA_U_400_GM'], //i need to change from weightClasses to weightClass
        },
      },
      direction: 'BUY',
      extensions: '',
      fileIds: [],
      assets: [],
      selectedOrganisationsIds: '',
      textMessage: '',
      timeInForce: '2023-01-26T00:00:00Z',
      visibility: 'TRADING_PARTNERS',
      volume: 11,
    }; */
    console.log('created Rfq saga - RFQ:', JSON.parse(JSON.stringify(rfq)));
    saveToLS('myRfqs', rfqState);
    const token = yield call(auth.selectors.getToken);
    const createdRfq = yield call(api.create, { token, body: rfq });
    console.log('createdRfq result: ', createdRfq);
    const payload = {
      items: [createdRfq],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(createRFQ(CREATE_RFQ_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(createRFQ(CREATE_RFQ_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
// TODO: ADD ONE SCHEMA FOR RFQ
function* update(action) {
  try {
    const {
      payload: { rfqs, status, userId },
    } = action;

    const payload = {
      items: [],
    };

    const meta = {
      receivedAt: new Date(),
    };

    if (status && Array.isArray(rfqs)) {
      const token = yield call(auth.selectors.getToken);

      const tasks = [];

      rfqs.forEach((rfq) => {
        if (!rfq.id || rfq.status === status || rfq.createdByUserId !== userId) return;

        const newRFQ = {
          rfqId: rfq.id,
          status,
        };

        if (Object.keys(newRFQ).length > 0) {
          tasks.push(call(api.update, { token, body: newRFQ }));
        }
      });
      if (tasks.length > 0) {
        const RFQs = yield all(tasks);
        payload.items = RFQs;
      }
    }

    yield put(updateRFQs(UPDATE_RFQS_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(updateRFQs(UPDATE_RFQS_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* insertRFQsFromSocket(action) {
  try {
    const {
      payload: { items },
    } = action;

    const payload = {
      items,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(insertRFQs(INSERT_RFQS_FROM_SOCKET_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(insertRFQs(INSERT_RFQS_FROM_SOCKET_ERROR, { error }));
  }
}

function* updateFromSocket(action) {
  try {
    const {
      payload: { items },
    } = action;

    const payload = {
      items,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(updateRFQs(UPDATE_RFQS_FROM_SOCKET_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(updateRFQs(UPDATE_RFQS_FROM_SOCKET_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* updateForm(action) {
  try {
    const { type, action: actionForm, value, name } = action.payload;
    const savedRfqPresets = getFromLS('savedRfqPresets') || [];
    const myOrganisation = yield select(getMyOrganisation);
    console.log('myOrganisation', myOrganisation);
    const payload = {
      type,
      savedRfqPresets,
    };

    let rfqState = {};
    if (actionForm === 'open') {
      if (action.payload.order) {
        if (isObject(action.payload.order)) {
          rfqState = getDefaultRfqFromOrder(action.payload.order);
          rfqState.orderRows = [];
          const organisations = yield select(organisationsModule.selectors.getAllObject);
          const list = rfqState.selectedOrganisationsIds;
          const newList = formatSelectedOrganisations(list, organisations);
          rfqState.selectedOrganisationsIds = newList;
        }
      } else {
        //rfqState = getFromLS('myRfqs');
        rfqState = null;

        const defaultState = getDefaultFormState('rfq');
        defaultState.orderRows = [];
        if (rfqState) {
          rfqState = checkRfqState(rfqState, defaultState);
        } else {
          rfqState = defaultState;
          rfqState.orderRows = [];
        }
        rfqState.textMessage = '';
      }

      const contractType = getContractType();

      let rows = rfqState.orderRows ? [...rfqState.orderRows] : [];

      const rfqType = rfqState.rfqType ? rfqState.rfqType.toLowerCase() : 'spot';
      const enumerations = getEnumerations('rfq', rfqState, myOrganisation);
      const fresh = getFreshFrozen(rfqState);
      const rfqTypeFields = getFieldsArray('rfq', ['isRfqType', rfqType, fresh]);
      const breakDownRowFields = getFieldsArray('order', ['breakdownRow', rfqType, fresh]);
      const formFields = getFieldsArray('rfq', ['rfqForm', rfqType, fresh]);
      payload.open = true;
      payload.enumerations = enumerations;
      payload.formFields = { formFields, rfqTypeFields, breakDownRowFields };

      payload.item = rfqState;

      payload.contractType = contractType;
      payload.orderRows = rows;
    } else if (actionForm === 'close') {
      payload.open = false;
    } else if (actionForm === 'reset') {
      removeFromLS('myRfqs');
      const rfqState = getDefaultFormState('rfq');
      const contractType = getContractType();
      const enumerations = getEnumerations('rfq', rfqState, myOrganisation);

      let rows = [];

      const rfqType = rfqState.rfqType ? rfqState.rfqType.toLowerCase() : 'spot';
      const fresh = getFreshFrozen(rfqState);
      const rfqTypeFields = getFieldsArray('rfq', ['isRfqType', rfqType, fresh]);
      const breakDownRowFields = getFieldsArray('order', ['breakdownRow', rfqType, fresh]);
      const formFields = getFieldsArray('rfq', ['rfqForm', rfqType, fresh]);

      payload.open = true;
      payload.enumerations = enumerations;
      payload.formFields = { formFields, rfqTypeFields, breakDownRowFields };
      payload.item = rfqState;

      payload.contractType = contractType;
      payload.orderRows = rows;
    } else if (actionForm === 'update') {
      const oldRfqState = yield select(getRfqFormOrder);
      const oldOrderRows = yield select(getRfqOrderRows);

      oldRfqState[name] = value;

      const rfqType = oldRfqState.rfqType.toLowerCase();
      const fresh = getFreshFrozen(oldRfqState);
      const formFields = getFieldsArray('rfq', ['rfqForm', rfqType, fresh]);
      let rfqState = getUpdatedRfqFormState(name, oldRfqState, rfqType, fresh);

      const breakDownRowFields = getFieldsArray('order', ['breakdownRow', rfqType, fresh]);

      const enumerations = getEnumerations('rfq', rfqState, myOrganisation);

      rfqState = checkRfqStateAgainstEnums(rfqState, enumerations);

      const orderRows = checkRfqOrderRowsStateAgainstEnums(oldOrderRows, enumerations);
      const rfqTypeFields = getFieldsArray('rfq', ['isRfqType', rfqType, fresh]);

      if (name === 'states') {
        if (fresh !== 'fresh') {
          const dt = rfqState.maxDeliveryDate;
          rfqState.maxHarvestDate = dt;
          rfqState.maxFreezeDate = dt;
        }
      }

      if (name === 'harvestDay' || name === 'deliveryDay') {
        orderRows.forEach((row) => {
          row[name] = value;
        });
        payload.orderRows = orderRows;
      }
      if (name === 'rfqType' || name === 'states') {
        const newOrderRows = [];
        orderRows.forEach((row) => {
          const newRow = {};
          breakDownRowFields.forEach((field) => {
            const val = isArray(rfqState[field.accessor])
              ? rfqState[field.accessor][0]
              : rfqState[field.accessor];
            newRow[field.accessor] = row[field.accessor] || val;
          });
          newRow.key = row.key;
          newOrderRows.push(newRow);
        });
        payload.orderRows = newOrderRows;
      }

      payload.enumerations = enumerations;
      payload.formFields = { formFields, rfqTypeFields, breakDownRowFields };
      payload.item = rfqState;
    } else if (actionForm === 'updateDates') {
      let rfqState = yield select(getRfqFormOrder);
      const rfqType = rfqState.rfqType.toLowerCase();
      const fresh = getFreshFrozen(rfqState);
      const formFields = getFieldsArray('rfq', ['rfqForm', rfqType, fresh]);
      const week = value;
      const dates = getDatesFromWeek(week, fresh);
      Object.keys(dates).forEach((d) => {
        if (rfqState.hasOwnProperty(d)) {
          if (formFields.some((element) => element.accessor === d)) {
            rfqState[d] = dates[d];
          } else {
            console.log('not found date', d);
          }
        }
      });

      rfqState = getUpdatedRfqFormState(name, rfqState, rfqType, fresh);

      payload.item = rfqState;
    } else if (actionForm === 'changeDates') {
      const rfqState = yield select(getRfqFormOrder);
      Object.keys(value).forEach((d) => {
        if (rfqState.hasOwnProperty(d)) {
          rfqState[d] = value[d];
        }
      });
      payload.item = rfqState;
    } else if (actionForm === 'updateOrderRows') {
      const rfqState = yield select(getRfqFormOrder);
      const { orderRows, totals } = action.payload.value;

      Object.keys(totals).forEach((key) => {
        if (Array.isArray(totals[key])) {
          if (totals[key].includes('ANY')) {
            rfqState[key] = ['ANY'];
          } else {
            if (totals[key].length !== 0) {
              let arr = [...rfqState[key], ...totals[key]];

              let newArr = [...new Set(arr)];
              if (newArr.length > 1 && newArr.includes('ANY')) {
                let i = newArr.indexOf('ANY');
                newArr.splice(i, 1);
              }
              rfqState[key] = newArr;
            }
          }
        } else {
          rfqState[key] = totals[key];
        }
      });

      let newHarvestDay = 'SATURDAY';
      let newDeliveryDay = 'SUNDAY';
      orderRows.forEach((row) => {
        const rowHarvestDayInteger = mapDayToInteger(row.harvestDay);
        const rowDeliveryDayInteger = mapDayToInteger(row.deliveryDay);
        const newHarvestDayInteger = mapDayToInteger(newHarvestDay);
        const newDeliveryDayInteger = mapDayToInteger(newDeliveryDay);
        newHarvestDay =
          rowHarvestDayInteger < newHarvestDayInteger ? row.harvestDay : rfqState.harvestDay;
        newDeliveryDay =
          rowDeliveryDayInteger > newDeliveryDayInteger ? row.deliveryDay : rfqState.deliveryDay;
      });

      payload.item = rfqState;
      payload.orderRows = orderRows;
    } else if (actionForm === 'updateValidity') {
      const rfqState = yield select(getRfqFormOrder);
      rfqState.timeInForce = value;

      payload.item = rfqState;
    } else if (actionForm === 'savePresets') {
      saveToLS('savedRfqPresets', action.payload.item);
      payload.savedRfqPresets = action.payload.item;
    } else if (actionForm === 'choosePreset') {
      const rfqState = action.payload.item.data;
      const contractType = getContractType();
      const enumerations = getEnumerations('rfq', rfqState, myOrganisation);

      let rows = rfqState.orderRows || [];

      const rfqType = rfqState.rfqType ? rfqState.rfqType.toLowerCase() : 'spot';
      const fresh = getFreshFrozen(rfqState);
      const rfqTypeFields = getFieldsArray('rfq', ['isRfqType', rfqType, fresh]);
      const breakDownRowFields = getFieldsArray('order', ['breakdownRow', rfqType, fresh]);
      const formFields = getFieldsArray('rfq', ['rfqForm', rfqType, fresh]);

      payload.open = true;
      payload.enumerations = enumerations;
      payload.formFields = { formFields, rfqTypeFields, breakDownRowFields };
      payload.item = rfqState;

      payload.contractType = contractType;
      payload.orderRows = rows;
    }
    const meta = {
      receivedAt: new Date(),
    };
    /* if (actionForm !== 'closeForm') {
      saveToLS('myRfqs', payload.item);
    } */

    console.log('rfq:saga:updateForm:payload', JSON.parse(JSON.stringify(payload)));

    yield put(actionUpdateForm(UPDATE_FORM_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateForm(UPDATE_FORM_ERROR, { error, type: action.payload.type }));
  }
}

/**
 *
 * @param {*} action
 */
// TODO: ADD ONE SCHEMA FOR RFQ
function* updateRFQViews(action) {
  try {
    const {
      payload: { id },
    } = action;

    const payload = {};

    const meta = {
      receivedAt: new Date(),
    };

    const views = yield select(getViews);

    const index = views.findIndex((v) => v === id);

    if (index === -1) {
      payload.items = [...views, id];
      saveToLS('rfqViews', payload.items);
    }

    yield put(actionUpdateRFQs(UPDATE_RFQ_VIEWS_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateRFQs(UPDATE_RFQ_VIEWS_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
// TODO: ADD ONE SCHEMA FOR RFQ
function* updateRFQDismissed(action) {
  try {
    const {
      payload: { id },
    } = action;

    const payload = {};

    const meta = {
      receivedAt: new Date(),
    };

    const dismissed = yield select(getDismissed);

    const index = dismissed.findIndex((v) => v === id);

    if (index === -1) {
      payload.items = [...dismissed, id];
      saveToLS('rfqDismissed', payload.items);
    }

    yield put(actionUpdateRFQs(UPDATE_RFQ_DISMISSED_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateRFQs(UPDATE_RFQ_DISMISSED_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* updateResponseForm(action) {
  try {
    const { type, action: actionForm } = action.payload;
    const payload = {
      type,
    };

    if (type === 'response') {
      if (actionForm === 'openForm') {
        const { rfq, orders, orderIdToCancel, counterpartyContract } = action.payload;
        const rfqType = getRfqType(rfq);
        const user = yield select(getActiveUser);
        const enumerations = getRfqRespsonseEnumerations(rfq, true);

        const fresh = getFresh(rfq.contract.underlying) === 'fresh' ? 'fresh' : 'frozen';
        const allFields = getFieldsObject('order', [fresh, rfqType]);
        const orderRowFields = getFieldsObject('order', ['responseForm', fresh, rfqType]);
        const defaultRowFields = getFieldsObject('order', [
          'responseFormDefaultRow',
          fresh,
          rfqType,
        ]);

        let direction = rfq.direction || 'BUY';
        let newOrders;
        let defaultOrders;
        let visibility;
        let orgIds = [];
        const isCompanyRfq = rfq.organisationId === user.organisationId;

        if (!isCompanyRfq) {
          direction = direction === 'SELL' ? 'BUY' : 'SELL';
        }

        if (orders !== null) {
          const isCompanyOrder = orders.organisationId === user.organisationId;
          defaultOrders = getRfqResponseDefaultItem(rfq, direction, orders);
          newOrders = getRfqResponseFromOrder(orders, direction, false, counterpartyContract);
          visibility = 'SELECTED_ORGANISATIONS';
          if (isCompanyOrder) {
            orgIds = orders.selectedOrganisationsIds;
          } else {
            orgIds = [orders.organisationId];
          }
        } else {
          defaultOrders = getRfqResponseDefaultItem(rfq, direction, null);
          newOrders = getRfqResponseItem(rfq, direction, defaultOrders);

          //const allOrders = yield select(getOrders);

          /*  newOrders.forEach((order) => {
            console.log('updateResponseForm:order', order);
            const price = getPrices(order.weightClass, rfq, user, allOrders);
            if (price !== null && price !== undefined) {
              order.price = price;
            }
          });
          defaultOrders.forEach((order) => {
            const price = getPrices(order.weightClass, rfq, user, allOrders);
            if (price !== null && price !== undefined) {
              order.price = price;
            }
          }); */
          if (isCompanyRfq) {
            visibility = rfq.visibility;
            orgIds = rfq.selectedOrganisationsIds || [];
          } else {
            visibility = 'SELECTED_ORGANISATIONS';
            orgIds = [rfq.organisationId];
          }
        }
        const organisations = yield select(organisationsModule.selectors.getAllObject);
        const selectedOrganisations = formatSelectedOrganisations(orgIds, organisations);

        newOrders.forEach((order) => {
          order.visibility = visibility;
          order.selectedOrganisationsIds = selectedOrganisations;
        });

        payload.open = true;
        payload.rfq = rfq;
        payload.orders = newOrders;
        payload.orderIdToCancel = orderIdToCancel;
        payload.defaultOrders = defaultOrders;
        payload.enumerations = enumerations;
        payload.formFields = { allFields, orderRowFields, defaultRowFields };
      } else if (actionForm === 'updateAll') {
        const { field, value } = action.payload;
        const orders = yield select(getOrdersResponseForm);
        orders.forEach((order) => {
          order[field] = value;
        });
        payload.orders = orders;
      } else if (actionForm === 'updateOrderRow') {
        console.log('UPDATE ORDER ROW');
        let orders = yield select(getOrdersResponseForm);
        const { field, value, key } = action.payload;
        const index = orders.findIndex((o) => o.key === key);
        if (index > -1) {
          orders[index][field] = value;
        }
        console.log('ORDERS PRE SORT', JSON.parse(JSON.stringify(orders)));
        orders = sortOrders(orders);
        console.log('ORDERS POST SORT', JSON.parse(JSON.stringify(orders)));
        payload.orders = orders;
      } else if (actionForm === 'updateDefaultOrderRow') {
        const orders = yield select(getDefaultOrdersResponseForm);
        const { field, value, key } = action.payload;
        const index = orders.findIndex((o) => o.key === key);
        if (index > -1) {
          orders[index][field] = value;
        }
        payload.defaultOrders = orders;
      } else if (actionForm === 'updatePrices') {
        const defaultOrders = yield select(getDefaultOrdersResponseForm);
        const orders = yield select(getOrdersResponseForm);
        const rfq = yield select(getRfqResponseForm);
        const fresh =
          rfq.contract.underlying.states[0].toLowerCase() === 'fresh' ? 'fresh' : 'frozen';
        orders.forEach((order) => {
          defaultOrders.forEach((dOrder) => {
            const orderHarvestDay = order.harvestDate.day();
            const dOrderHarvestDay = mapDayToInteger(dOrder.harvestDay);

            const orderDeliveryDay = order.deliveryDate.day();
            const dOrderDeliveryDay = mapDayToInteger(dOrder.deliveryDay);

            const weightClass = order.weightClass;
            const dWeightClass = dOrder.weightClass;

            const certification = order.certification;
            const dCertification = dOrder.certification;

            const quality = order.quality;
            const dQuality = dOrder.quality;

            const colorFan = order.colorFan;
            const dColorFan = dOrder.colorFan;

            const presentation = order.presentation;
            const dPresentation = dOrder.presentation;

            if (orderHarvestDay === dOrderHarvestDay || fresh === 'frozen') {
              if (orderDeliveryDay === dOrderDeliveryDay) {
                if (certification === dCertification) {
                  if (quality === dQuality) {
                    if (colorFan === dColorFan) {
                      if (presentation === dPresentation) {
                        if (weightClass === dWeightClass) {
                          order.price = dOrder.price;
                        }
                      }
                    }
                  }
                }
              }
            }
          });
        });
        payload.orders = orders;
      } else if (actionForm === 'buildOrders') {
        const defaultOrders = yield select(getDefaultOrdersResponseForm);
        const oldOrders = yield select(getOrdersResponseForm);
        const rfq = yield select(getRfqResponseForm);
        const user = yield select(getActiveUser);
        const isCompanyRfq = rfq.organisationId === user.organisationId;
        let direction = rfq.direction || 'BUY';
        if (!isCompanyRfq) {
          direction = direction === 'SELL' ? 'BUY' : 'SELL';
        }

        const orders = getRfqResponseItem(rfq, direction, defaultOrders);
        orders.forEach(
          (order) => (order.selectedOrganisationsIds = oldOrders[0].selectedOrganisationsIds),
        );
        payload.orders = orders;
      } else if (actionForm === 'closeForm') {
        payload.open = false;
      } else if (actionForm === 'deleteRow') {
        const orders = yield select(getOrdersResponseForm);
        if (orders.length > 1) {
          const key = action.payload.key || '';
          const orderRows = [...orders];
          const index = orderRows.findIndex((i) => i.key === key);
          orderRows.splice(index, 1);
          payload.orders = orderRows;
        }
      } else if (actionForm === 'deleteDefaultRow') {
        const orders = yield select(getDefaultOrdersResponseForm);
        if (orders.length > 1) {
          const key = action.payload.key || '';
          const orderRows = [...orders];
          const index = orderRows.findIndex((i) => i.key === key);
          orderRows.splice(index, 1);
          payload.defaultOrders = orderRows;
        }
      } else if (actionForm === 'addRow') {
        const orders = yield select(getOrdersResponseForm);

        const newOrders = getNewOrders(orders);
        payload.orders = newOrders;
      } else if (actionForm === 'addDefaultRow') {
        let orders = yield select(getDefaultOrdersResponseForm);
        const length = orders.length;
        const newOrder = { ...orders[length - 1] };
        newOrder.key = createId();
        orders.push(newOrder);
        orders = sortOrders(orders);
        payload.defaultOrders = orders;
      } else if (actionForm === 'copyRow') {
        let orders = yield select(getOrdersResponseForm);
        const key = action.payload.key || '';
        let orderRows = [...orders];
        const index = orderRows.findIndex((i) => i.key === key);
        const newOrder = { ...orderRows[index] };
        newOrder.key = createId();
        orderRows.splice(index, 0, newOrder);
        orderRows = sortOrders(orderRows);
        payload.orders = orderRows;
      } else if (actionForm === 'copyDefaultRow') {
        const orders = yield select(getDefaultOrdersResponseForm);
        const key = action.payload.key || '';
        const orderRows = [...orders];
        const index = orderRows.findIndex((i) => i.key === key);
        const newOrder = { ...orderRows[index] };
        newOrder.key = createId();
        orderRows.splice(index, 0, newOrder);
        payload.defaultOrders = orderRows;
      }
    } else if (type === 'execute') {
      if (actionForm === 'openForm') {
        const { rfq, orders, orderIdToCancel } = action.payload;
        const rfqType = getRfqType(rfq);
        const user = yield select(getActiveUser);

        const fresh = getFresh(rfq.contract.underlying) === 'fresh' ? 'fresh' : 'frozen';
        const allFields = getFieldsObject('order', [fresh, rfqType]);
        const orderRowFields = getFieldsObject('order', ['responseForm', fresh, rfqType]);
        const defaultRowFields = getFieldsObject('order', [
          'responseFormDefaultRow',
          fresh,
          rfqType,
        ]);
        const formFields = { allFields, orderRowFields, defaultRowFields };
        let direction = rfq.direction || 'BUY';

        const isCompanyRfq = rfq.organisationId === user.organisationId;

        if (!isCompanyRfq) {
          direction = direction === 'SELL' ? 'BUY' : 'SELL';
        }

        const orgIds = [orders.organisationId, user.organisationId];
        const newOrders = getRfqResponseFromOrder(orders, direction, true);
        const visibility = 'SELECTED_ORGANISATIONS';

        const organisations = yield select(organisationsModule.selectors.getAllObject);
        const selectedOrganisations = formatSelectedOrganisations(orgIds, organisations);

        newOrders.forEach((order) => {
          order.visibility = visibility;
          order.selectedOrganisationsIds = selectedOrganisations;
        });
        payload.open = true;
        payload.rfq = rfq;
        payload.orders = newOrders;
        payload.orderIdToCancel = orderIdToCancel;
        payload.formFields = formFields;
      } else if (actionForm === 'closeForm') {
        payload.open = false;
      }
    } else if (type === 'amend') {
      if (actionForm === 'openForm') {
        const { orders } = action.payload;
        const direction = orders.assets[0].direction;
        let rfqType = 'spot';
        let fresh = 'fresh';
        /*  if (rfq) {
          const rfqType = getRfqType(rfq);
          fresh = rfq.contract.underlying.states[0].toLowerCase() === 'fresh' ? 'fresh' : 'frozen';
        } else {
          rfqType = orders[0].rfqType ? orders[0].rfqType : 'spot';
          fresh = orders[0].contract.underlying.state || 'fresh';
        } */

        const formFields = getFieldsObject('order', ['responseForm', fresh, rfqType]);
        const newOrders = getRfqResponseFromOrder(orders, direction);
        newOrders.forEach((order) => {
          order.orderGroupId = orders.id;
        });

        payload.open = true;
        payload.orders = newOrders;
        payload.formFields = formFields;
      } else if (actionForm === 'closeForm') {
        payload.open = false;
      } else if (actionForm === 'updateOrderRow') {
        const orders = yield select(getOrdersAmendForm);
        const { field, value, key } = action.payload;
        const index = orders.findIndex((o) => o.key === key);
        if (index > -1) {
          orders[index][field] = value;
        }
        payload.orders = orders;
      }
    }

    const meta = {
      receivedAt: new Date(),
    };
    yield put(actionUpdateForm(UPDATE_RESPONSE_FORM_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateForm(UPDATE_RESPONSE_FORM_ERROR, { error, type: action.payload.type }));
  }
}

/**
 *
 * @param {*} action
 */
function* reset() {
  yield put(resetRFQs(RESET_RFQS));
}

export function* watchGetRFQs() {
  yield takeEvery(GET_RFQS, getAll);
}

export function* watchGetRFQ() {
  yield takeEvery(GET_RFQ, get);
}

export function* watchCreateRFQ() {
  yield takeEvery(CREATE_RFQ, create);
}

export function* watchUpdateRFQs() {
  yield takeEvery(UPDATE_RFQS, update);
}

export function* watchInsertRFQsFromSocket() {
  yield takeEvery(INSERT_RFQS_FROM_SOCKET, insertRFQsFromSocket);
}

export function* watchUpdateRFQsFromSocket() {
  yield takeEvery(UPDATE_RFQS_FROM_SOCKET, updateFromSocket);
}

export function* watchResetRFQs() {
  yield takeEvery(RESET_RFQS, reset);
}

export function* watchUpdateForm() {
  yield takeEvery(UPDATE_FORM, updateForm);
}

export function* watchUpdateResponseForm() {
  yield takeEvery(UPDATE_RESPONSE_FORM, updateResponseForm);
}

export function* watchUpdateRFQViews() {
  yield takeEvery(UPDATE_RFQ_VIEWS, updateRFQViews);
}

export function* watchUpdateRFQDismissed() {
  yield takeEvery(UPDATE_RFQ_DISMISSED, updateRFQDismissed);
}

function formatSelectedOrganisations(oldList, organisations) {
  const selectedOrganisations = [];
  oldList.forEach((r) => {
    const orgId = r === null || r === undefined ? null : r.hasOwnProperty('id') ? r.id : r;
    if (orgId !== null) {
      const org = organisations[orgId];

      if (isObject(org)) {
        selectedOrganisations.push(org);
      }
    }
  });
  return selectedOrganisations;
}

const getRfqType = (rfq) => {
  let rfqType = 'spot';
  if (rfq.rfqType) {
    rfqType = rfq.RfqType;
  }
  if (rfq.extensions) {
    const extensions = JSON.parse(rfq.extensions) || {};
    if (extensions.rfqType) {
      rfqType = extensions.rfqType.toLowerCase();
    }
  }
  return rfqType;
};

const getFreshFrozen = (rfqState) => {
  const fresh = rfqState.states
    ? rfqState.states.toLowerCase() === 'fresh'
      ? 'fresh'
      : 'frozen'
    : 'fresh';
  return fresh;
};

/* const getPrices = (wgt, activeRfq, user, allOrders) => {
  let price = 5.6;
  const getDirection = () => {
    const isRfqRequestor = activeRfq.organisationId === user.organisationId;

    let direction = 'BUY';
    if (isRfqRequestor) {
      if (activeRfq.direction === 'SELL') direction = 'SELL';
    } else {
      if (activeRfq.direction === 'BUY') direction = 'SELL';
    }

    return direction;
  };
  const direction = getDirection();

  if (activeRfq.contract) {
    const orgId = activeRfq.organisationId;
    const currency = activeRfq.contract.underlying.currency || '';
    const location = activeRfq.contract.underlying.locations
      ? activeRfq.contract.underlying.locations[0]
      : activeRfq.contract.underlying.location;
    const product = activeRfq.contract.underlying.product;
    const orders = JSON.parse(JSON.stringify(allOrders))
      .filter((order) => order.assets[0].contract.underlying.product === product)
      .filter((order) => order.assets[0].contract.underlying.location === location)
      .filter((order) => order.assets[0].contract.underlying.currency === currency)
      .filter((order) => order.assets[0].contract.underlying.weightClass === wgt)
      .filter((order) => order.status === 'ACTIVE')
      .filter((order) => order.direction === direction)
      .filter((order) => order.rfqId === null || order.rfqId === 'null')
      .filter((order) => order.organisationId === user.organisationId)
      .filter((order) => {
        const list = order.selectedOrganisationsIds;
        const index = list.findIndex((l) => l.id === orgId);
        if (index === -1) {
          if (order.visibility === 'TRADING_PARTNERS' || order.visibility === 'PUBLIC') {
            return true;
          }
        }
        return true;
      });
    if (direction === 'BUY') {
      orders.sort((a, b) => b.assets[0].price - a.assets[0].price);
    } else {
      orders.sort((a, b) => a.assets[0].price - b.assets[0].price);
    }

    if (orders.length > 0) {
      price = orders[0].assets[0].price;
    }
  }
  return price;
}; */

function signOutSuccess() {
  removeFromLS('myRfqs');
  removeFromLS('rfqViews');
}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}

function* socketReconnected() {
  yield call(getAll);
}

export function* watchSocketReconnected() {
  yield takeEvery(SOCKET_RECONNECTED, socketReconnected);
}

function getNewOrders(orders) {
  const species = orders[0].species;
  const marketSegment = Object.keys(marketSegments).find(
    (item) => marketSegments[item].species === species,
  );
  const orderWeights = orders.map((o) => o.weightClass);
  const weightClasses = Object.keys(marketSegments[marketSegment].enums.weightClass) || [];

  let nextWeightClasses = weightClasses.filter((wgt) => !orderWeights.some((w) => w === wgt));

  if (nextWeightClasses.length === 0) {
    return orders;
  } else {
    const newOrder = { ...orders[orders.length - 1] };

    newOrder.key = createId();
    newOrder.weightClass = nextWeightClasses[0];
    newOrder.price = 0;
    newOrder.volume = 0;
    orders.push(newOrder);
    orders.sort((a, b) => {
      const aIndex = weightClasses.indexOf(a.weightClass);
      const bIndex = weightClasses.indexOf(b.weightClass);

      return aIndex > bIndex ? 1 : bIndex > aIndex ? -1 : 0;
    });
    return orders;
  }
}

function sortOrders(orders) {
  const species = orders[0].species;
  const marketSegment = Object.keys(marketSegments).find(
    (item) => marketSegments[item].species === species,
  );

  const weightClasses = Object.keys(marketSegments[marketSegment].enums.weightClass) || [];

  if (weightClasses.length === 0) {
    return orders;
  } else {
    orders.sort((a, b) => {
      const aIndex = weightClasses.indexOf(a.weightClass);
      const bIndex = weightClasses.indexOf(b.weightClass);

      return aIndex > bIndex ? 1 : bIndex > aIndex ? -1 : 0;
    });
    return orders;
  }
}
