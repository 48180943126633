import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Icon,
  //Button,
  //Avatar,
  Badge,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import MenuIcon from '@material-ui/icons/Menu';

import NotificationsIcon from '@material-ui/icons/Notifications';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { fade } from '@material-ui/core/styles/colorManipulator'; //
import UserAvatar from '../userAvatar';
import { avatarSize } from '../../models/constants';
import { getContractWeek } from '../../../modules/contracts/utils/deliveryWeeks';
import { getRfqNotifications, getThemes, getUserFullInfo } from '../../selectors';

import { CHANGE_THEME } from '../../../modules/themes/actionTypes';
import { THEME_TYPES } from '../../../modules/themes/constants';
import WorkspacesMenu from './WorkspacesMenu';
import NotificationsMenu from './NotificationsMenu';
import ChatNotificationsMenu from './ChatNotificationsMenu';
import UserSettingsMenu from './UserSettingsMenu';
import MenuDrawer from './MenuDrawer';

import auth from '../../../modules/auth';
import workspaces from '../../../modules/workspaces';
//import orders from '../../../modules/orders';
import settings from '../../../modules/settings';
import chat from '../../../modules/chat';
import rfqs from '../../../modules/rfqs';
import rfqs_new from '../../../modules/rfqs_new';
import { createAction } from '../../actions';

const drawerWidth = 280;

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.background.appBar || theme.palette.primary,
    color: theme.palette.common.white,
    boxShadow: '1px 2px 4px #000 !important',
    padding: '8px 0px',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 16,
  },
  hide: {
    display: 'none',
  },
  workspaceTitle: {
    color: fade('#fff', 0.87),
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerLondon: {
    fontSize: '22.7px',
    color: '#4fa1ca',
    letterSpacing: '6.5px',
    lineHeight: '1.2rem',
    fontWeight: 'bold',
  },
  headerFish: {
    fontSize: '14.5px',
    color: '#c1bdb6',
    lineHeight: '1rem',
    letterSpacing: '1.05px',
  },

  weekTitle: {
    color: fade(theme.palette.common.white, 0.2),
    fontSize: '14px',
  },

  root: {
    display: 'flex',
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  img: {
    width: '70%',
    padding: '25px',
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  icon: {},
  item: {
    opacity: 1,
  },
  connection: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(),
  },
  connectionStatusOn: {
    color: teal[300],
    fontSize: theme.typography.fontSize,
  },
  connectionStatusOff: {
    color: red[500],
    fontSize: theme.typography.fontSize,
  },
  farRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '400px',
  },
  appBarItem: {
    flex: 1,
  },
  workspace: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderRadius: '4px',
  },
  companyPart1: {
    color: 'var(--text-light-color)',
    fontSize: '24px',
  },
  companyPart2: {
    color: 'var(--alternate-dark-color)',
    fontSize: '24px',
  },
});

class AppMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      menuOpen: false,
      anchorEl: null,
      notificationsAnchorEl: null,
      userSettingsAnchorEl: null,
      chatAnchorEl: null,
    };
    this.myRef = React.createRef();
    this.addComponentInWorkspaceThrottled = _.throttle(this.addComponentInWorkspace, 100);
  }

  addComponentInWorkspace = (type) => {
    this.props.addComponentInWorkspace({ type });
  };

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  handleNotificationsOpen = (event) => {
    this.setState({ notificationsAnchorEl: event.currentTarget });
  };

  handleNotificationsClose = () => {
    this.setState({ notificationsAnchorEl: null });
  };

  handleUserSettingsOpen = (event) => {
    this.setState({ userSettingsAnchorEl: event.currentTarget });
  };

  handleUserSettingsClose = () => {
    this.setState({ userSettingsAnchorEl: null });
  };

  handleChatOpen = (event) => {
    this.setState({ chatAnchorEl: event.currentTarget });
  };

  handleChatClose = () => {
    this.setState({ chatAnchorEl: null });
  };

  workspaceClick = (e) => {
    this.setState({ anchorEl: e.currentTarget, menuOpen: !this.state.menuOpen });
  };

  changeWorkspace = (id) => {
    this.props.activateWorkspace(id);
    this.setState({ menuOpen: false });
  };

  resetWorkspace = () => {
    this.props.resetWorkspace();
  };

  clearWorkspace = () => {
    this.props.clearWorkspace();
  };

  togglePalette = () => {
    const themeType =
      this.props.theme.type === THEME_TYPES.dark ? THEME_TYPES.light : THEME_TYPES.dark;

    const payload = {
      type: themeType,
    };

    this.props.changeTheme(payload);
  };

  logout = () => {
    this.props.logout();
  };

  createDocumentTitle = (notificationsCount) => {
    if (notificationsCount.all === 0) {
      return 'LFEX';
    }
    return `LFEX - msgs(${notificationsCount.all})`;
  };

  render() {
    const { classes, user, rfqNotifications } = this.props;
    const { userName, fullName, connected } = user;

    document.title = this.createDocumentTitle(this.props.notificationsCount);

    const isMenuOpen = Boolean(this.state.notificationsAnchorEl);

    const connectionStyle =
      connected === true ? classes.connectionStatusOn : classes.connectionStatusOff;
    const connectionText = connected === true ? 'Connected' : 'Disconnected';

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.drawerOpen,
          })}
        >
          <Toolbar disableGutters={!this.state.drawerOpen}>
            <div className={classes.appBarItem} style={{ display: 'flex' }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, {
                  [classes.hide]: this.state.drawerOpen,
                })}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.logo}>
                <span className={classes.companyPart1}>OOX</span>
                <span className={classes.companyPart2}>change</span>
                {/*   <Avatar src={logo} style={{ height: 40, width: 40, marginRight: '8px' }} /> */}
                <div className={classes.header}>
                  {/*  <div className={classes.headerLondon}>LONDON</div>
                <div className={classes.headerFish}>FISH EXCHANGE</div> */}
                </div>
              </div>
            </div>

            <div
              className={classes.appBarItem}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div ref={this.myRef} className={classes.workspace}>
                <Typography variant="h6" className={classes.workspaceTitle}>
                  {this.props.workspaceName}
                </Typography>
                <IconButton onClick={this.workspaceClick} aria-label="Delete">
                  <ExpandMoreIcon />
                </IconButton>
              </div>

              <WorkspacesMenu
                anchorEl={this.myRef.current}
                open={this.state.menuOpen}
                onClick={this.changeWorkspace}
                onClose={() => this.setState({ menuOpen: false })}
                showManageWorkspaces={this.props.showManageWorkspaces}
              />
            </div>

            <div className={classes.appBarItem} style={{ display: 'flex', justifyContent: 'end' }}>
              <div className={classes.farRight}>
                <IconButton color="inherit" onClick={this.handleChatOpen}>
                  <Badge badgeContent={this.props.notificationsCount.all} color="secondary">
                    <ChatBubbleIcon />
                  </Badge>
                </IconButton>
                <IconButton color="inherit" onClick={this.handleNotificationsOpen}>
                  <Badge badgeContent={rfqNotifications.length} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <div className={classes.connection}>
                  <Icon className={connectionStyle} style={{ paddingRight: '24px' }}>
                    lens
                  </Icon>
                  <Typography className={connectionStyle}>{connectionText}</Typography>
                </div>

                <NotificationsMenu
                  anchorEl={this.state.notificationsAnchorEl}
                  open={isMenuOpen}
                  onClose={this.handleNotificationsClose}
                  updateRfqViews={this.props.updateRFQViews}
                />
                <ChatNotificationsMenu
                  anchorEl={this.state.chatAnchorEl}
                  open={this.state.chatAnchorEl !== null}
                  close={this.handleChatClose}
                  updateChatViews={this.props.updateChatViews}
                  chatViews={this.props.rooms.chatViews}
                />
                <IconButton color="inherit" onClick={this.handleUserSettingsOpen}>
                  <UserAvatar organisationId={user.organisation.id} size={avatarSize.LARGE} />
                </IconButton>
                <UserSettingsMenu
                  anchorEl={this.state.userSettingsAnchorEl}
                  open={Boolean(this.state.userSettingsAnchorEl)}
                  onClose={this.handleUserSettingsClose}
                  user={this.props.user}
                  tradingPartnersListOpen={this.props.tradingPartnersListOpen}
                  notificationSettingsOpen={this.props.notificationSettingsOpen}
                  generalSettingsOpen={this.props.generalSettingsOpen}
                  blotterPresetsOpen={this.props.blotterPresetsOpen}
                  logout={this.logout}
                />
                <settings.components.TradingPartnersList />
                <settings.components.NotificationSettings />
                <settings.components.ManageRfqPresets />
                <settings.components.ManageBlotterPresets />
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <MenuDrawer
          fullName={fullName}
          username={userName}
          open={this.state.drawerOpen}
          handleDrawerClose={this.handleDrawerClose}
          addLayout={this.addComponentInWorkspaceThrottled}
          addDefaultLayout={this.resetWorkspace}
          clearLayout={this.clearWorkspace}
          togglePalette={this.togglePalette}
          showManageWorkspaces={this.props.showManageWorkspaces}
          createOrder={this.createOrder}
          isSuperAdmin={this.props.isSuperAdmin}
          openRfqForm={this.props.openRfqForm}
        />
      </div>
    );
  }
}

const rooms = [];

const mapStateToProps = (state) => {
  return {
    theme: getThemes(state),
    workspaceName: workspaces.selectors.getActiveWorkspaceName(state),
    notificationsCount: chat.selectors.getNewMessagesCount(state),
    user: getUserFullInfo(state),
    rooms,
    rfqNotifications: getRfqNotifications(state),
    isSuperAdmin: auth.selectors.isSuperAdmin(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activateWorkspace: (payload) => {
      dispatch(
        workspaces.actions.activateWorkspace(workspaces.constants.ACTIVATE_WORKSPACE, payload),
      );
    },
    addComponentInWorkspace: (payload) => {
      dispatch(workspaces.actions.addComponent(workspaces.constants.ADD_COMPONENT, payload));
    },
    resetWorkspace: (payload) => {
      dispatch(workspaces.actions.resetWorkspace(workspaces.constants.RESET_WORKSPACE, payload));
    },
    clearWorkspace: (payload) => {
      dispatch(workspaces.actions.clearWorkspace(workspaces.constants.CLEAR_WORKSPACE, payload));
    },
    logout: (payload) => {
      dispatch(auth.actions.signOut(auth.constants.SIGN_OUT, payload));
    },

    changeTheme: (payload) => {
      dispatch(createAction(CHANGE_THEME, payload));
    },
    // TODO: update to action->saga->reducer
    showManageWorkspaces: (payload) => {
      dispatch(
        workspaces.actions.updateManageWorkspaces(
          workspaces.constants.UPDATE_MANAGE_WORKSPACES,
          payload,
        ),
      );
    },
    tradingPartnersListOpen: (payload) => {
      dispatch(
        settings.actions.tradingPartnersListOpen(
          settings.constants.TRADING_PARTNERS_LIST_OPEN,
          payload,
        ),
      );
    },
    notificationSettingsOpen: (payload) => {
      dispatch(
        settings.actions.notificationSettingsOpen(
          settings.constants.NOTIFICATION_SETTINGS_OPEN,
          payload,
        ),
      );
    },
    generalSettingsOpen: (payload) => {
      dispatch(
        settings.actions.generalSettingsOpen(settings.constants.GENERAL_SETTINGS_OPEN, payload),
      );
    },
    blotterPresetsOpen: (payload) => {
      dispatch(
        settings.actions.blotterPresetsOpen(settings.constants.BLOTTER_PRESETS_OPEN, payload),
      );
    },
    updateChatViews: (payload) => {
      /*dispatch({
        type: actionTypes.UPDATE_CHAT_VIEWS,
        payload,
      });*/
    },
    updateRFQViews: (payload) => {
      dispatch(rfqs.actions.updateRFQViews(rfqs.constants.UPDATE_RFQ_VIEWS, payload));
    },
    openRfqForm: (payload) => {
      dispatch(createAction(rfqs_new.actionTypes.OPEN_RFQ_FORM, { open: true }));
    },
  };
};

AppMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppMenu));
