import * as actionTypes from '../actionTypes';

const initialState = {
  orderAssetsModel: {},
  orderContractModel: {},
  orderContractUnderlyingModel: {},
  rfqAssetsModel: {},
  rfqContractModel: {},
  rfqContractUnderlyingModel: {},
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case actionTypes.INIT_SCHEMAS:
      nextState = {
        ...state,
        status: 'pending',
      };
      return nextState;

    case actionTypes.INIT_SCHEMAS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.INIT_SCHEMAS_ERROR:
      nextState = {
        ...state,
        status: 'error',
        error: action.payload,
      };
      return nextState;

    default:
      return state;
  }
};

export default reducer;
