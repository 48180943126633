import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Modal, Typography } from '@material-ui/core';
import TotalsRow from './TotalsRow';
import ToolbarBottom from '../../../commons/components/toolbarBottom';

import { CancelButton } from '../../../commons/components/buttons';
import ReadOnlyField from '../../../commons/components/formFields/readOnlyField';
import { getOrganisationFullNameFromId } from '../../../commons/utils/functions';

import { getOrganisations, getActiveUser } from '../selectors';
import { getKeyValuePairsFromOrder } from '../../contracts/utils/modelFunctions';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    //height: '70vh',
    //maxHeight: '70vh',
    flex: 1,
    overflowY: 'scroll',
  },
  section: {
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
  },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },

  itemTitle: {
    backgroundColor: theme.palette.background.banner,
    padding: theme.spacing(),
    textAlign: 'center',
    color: theme.palette.text.subtitle,
  },
  key: {
    color: theme.palette.text.subtitle,
  },
  sectionFlex: {
    marginTop: theme.spacing(0.5),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
    alignItems: 'center',
  },
  subsectionNoFlex: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
    //overFlowY: "scroll"
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    //overflowY: "scroll"
  },

  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.fontSize.sm,
  },
  title: { fontSize: theme.fontSize.lg },
  orderRow: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '0 2px',
  },
  orderRowAlt: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class ResponseDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, user, open } = this.props;
    const userData = {
      'User Name': this.props.userData.userName,
      'Organisation Name': this.props.userData.organisationName,
      'Org Short Name': this.props.userData.organisationShortName,
    };
    //const orderRows = this.props.orderGroup.assets || [];
    const order = this.props.orderGroup || {};
    let orderRows = [];
    if (order.assets) {
      orderRows = getKeyValuePairsFromOrder(order);
    }
    const iWeightClass =
      orderRows.length > 0 ? orderRows[0].findIndex((item) => item.key === 'Weight Class') : 0;
    const list = order.selectedOrganisationsIds || [];

    return (
      <Modal open={open} onClose={this.closeForm}>
        <div style={getModalStyle()} className={classes.paper}>
          <Typography className={classes.title}>RESPONSE DETAILS</Typography>
          <div className={classes.container}>
            <div className={classes.section}>
              <List open classes={{ root: classes.list }}>
                {Object.keys(userData).map((item) => (
                  <ListItem key={item['User Name']} button classes={{ root: classes.listItem }}>
                    <ListItemText primary={item} className={classes.key} />
                    <ListItemText primary={userData[item]} align="right" />
                  </ListItem>
                ))}
                {order.organisationId === user.organisationId && (
                  <ListItem key="recipients" button classes={{ root: classes.listItem }}>
                    <ListItemText primary="Order Sent To:" className={classes.key} />
                    <ListItemText
                      primary={
                        <>
                          {list.map((v, index) => {
                            let name;
                            if (v.name) {
                              name = v.name;
                            } else {
                              name = getOrganisationFullNameFromId(v, this.props.orgs);
                            }

                            return <div key={name}>{name}</div>;
                          })}
                        </>
                      }
                      align="right"
                    />
                  </ListItem>
                )}

                <ListItem key="textMessage" button classes={{ root: classes.listItem }}>
                  <ListItemText primary="Comments" className={classes.key} />
                  <ListItemText primary={this.props.orderGroup.textMessage} align="right" />
                </ListItem>
              </List>
            </div>

            <div className={classes.section}>
              <TotalsRow rows={order.assets || []} />
            </div>

            <div className={classes.section}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                {orderRows.map((orderRow, index) => {
                  return (
                    <div
                      key={index}
                      className={orderRows.length < 6 ? classes.orderRow : classes.orderRowAlt}
                    >
                      <div className={classes.itemTitle}>{`Item ${index + 1}`}</div>
                      {orderRows.length < 6 ? (
                        <ReadOnlyField
                          displayName={'Weight Class'}
                          value={orderRow[iWeightClass].value || ''}
                          fullWidth
                          key={`WeightClass-${orderRow.key}`}
                        />
                      ) : (
                        <ReadOnlyField
                          displayName={'Weight Class'}
                          value={orderRow[iWeightClass].value || ''}
                          key={`WeightClass-${orderRow.key}`}
                        />
                      )}
                      {orderRow.map((item, index) => {
                        const itemKey = `${item.key}-${index}`;

                        return orderRows.length < 6 && item.key !== 'Weight Class' ? (
                          <ReadOnlyField
                            displayName={item.key}
                            value={item.value}
                            fullWidth
                            key={itemKey}
                          />
                        ) : item.key !== 'Weight Class' ? (
                          <ReadOnlyField displayName={item.key} value={item.value} key={itemKey} />
                        ) : null;
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <ToolbarBottom>
            <>
              <CancelButton onClick={this.props.onClose} title={'Close'} />
            </>
          </ToolbarBottom>
        </div>
      </Modal>
    );
  }
}

ResponseDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    orgs: getOrganisations(state), // state.rooms.organisations,
    user: getActiveUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // onClose: payload => {
    //   dispatch(groupListToggle(payload));
    // }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResponseDetail));
