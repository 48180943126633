import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Modal, Typography } from '@material-ui/core';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import { CancelButton, ConfirmButton } from '../../../commons/components/buttons';
import SelectField from '../../../commons/components/formFields/SelectField';
import TextInputField from '../../../commons/components/formFields/TextInputField';
import DateField from '../../../commons/components/formFields/dateField';
import { getPostTradeForm, getPostTradeFormData, isPostTradeFormOpen } from '../selectors';
import { POST_TRADE_FORM_OPEN } from '../constants';
import { postTradeFormOpen } from '../actions';
import fileUpload from '../../fileUpload';
import { fields } from '../config';
import CollapseContainerAlt from '../../../commons/components/collapseContainerAlt';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '80vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  modalRoot: {
    zIndex: '9999 !important',
    backgroundColor: 'red',
  },

  container: { marginTop: '10px' },
  containerItems: {
    backgroundColor: theme.palette.primary.row,
    padding: '10px',
  },
  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: '5px',
    color: theme.palette.text.disabled,
  },
  uploads: {
    padding: theme.spacing(),
    borderRadius: '4px',
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
});

const PostTradeForm = (props) => {
  const { classes, open, onClose, form, data } = props;

  const handleChange = (name) => (e) => {
    const payload = {
      type: 'update',
      name: name,
      value: e.target.value,
    };
    props.onUpdate(payload);
  };
  const handleDateChange = (name) => (date) => {
    const payload = {
      type: 'update',
      name: name,
      value: date,
    };
    props.onUpdate(payload);
  };
  const getFieldValue = (field) => {
    const allValues = props.files[field.fileType];

    const id = data[field.accessor] ? data[field.accessor].id : null;

    if (id === null) {
      return {};
    } else {
      return allValues.filter((val) => val.id === id)[0] || {};
    }
  };
  const fieldsToMap = form ? (fields[form.formType] ? fields[form.formType] : []) : [];

  return (
    <div className={`undraggable`}>
      <Modal open={open} onClose={onClose} classes={{ root: classes.modalRoot }}>
        <div style={getModalStyle()} className={`${classes.paper} undraggable`}>
          <Typography className={classes.title}>{form.title}</Typography>
          <div className={classes.container}>
            {Object.values(fieldsToMap).map((field) => {
              switch (field.component) {
                case 'text':
                  return (
                    <TextInputField
                      accessor={field.accessor}
                      key={field.accessor}
                      displayName={field.displayName}
                      value={data[field.accessor] || ''}
                      handleChange={handleChange}
                      fullWidth
                    />
                  );
                case 'date':
                  return (
                    <DateField
                      accessor={field.accessor}
                      key={field.accessor}
                      displayName={field.displayName}
                      value={data[field.accessor] || new Date()}
                      disablePast={false}
                      handleDateChange={handleDateChange}
                      fullWidth
                    />
                  );
                case 'select':
                  return (
                    <SelectField
                      accessor={field.accessor}
                      key={field.accessor}
                      displayName={field.displayName}
                      value={getFieldValue(field)}
                      values={props.files[field.fileType] || []}
                      handleChange={handleChange}
                      fullWidth
                      files
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>
          <div>
            <CollapseContainerAlt title={'UPLOAD NEW FILES'}>
              <fileUpload.components.Dropzone
                sendTo={form.counterpartyId}
              ></fileUpload.components.Dropzone>
            </CollapseContainerAlt>
          </div>

          <ToolbarBottom>
            <>
              <CancelButton onClick={onClose} />
              <ConfirmButton onClick={props.onSend} />
            </>
          </ToolbarBottom>
        </div>
      </Modal>
    </div>
  );
};

PostTradeForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    open: isPostTradeFormOpen(state),
    form: getPostTradeForm(state),
    data: getPostTradeFormData(state),
    files: fileUpload.selectors.getAllByType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSend: (payload) => {
      dispatch(postTradeFormOpen(POST_TRADE_FORM_OPEN, { type: 'send' }));
    },
    onUpdate: (payload) => {
      dispatch(postTradeFormOpen(POST_TRADE_FORM_OPEN, payload));
    },
    onClose: (payload) => {
      dispatch(postTradeFormOpen(POST_TRADE_FORM_OPEN, { type: 'close', open: false }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PostTradeForm));
