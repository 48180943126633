import * as actionTypes from './actionTypes';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import api from './api';

const reducers = {
  reducer,
};

export default { actionTypes, constants, reducers, sagas, selectors, api };
