export const findSharedFiles = (trades, myOrgId) => {
  let files = [];
  trades.forEach((trade) => {
    const postTradeData = trade.buyOrganisationId === myOrgId ? trade.sellerData : trade.buyerData;
    if (postTradeData) {
      if (postTradeData.files) {
        files = [...files, ...postTradeData.files];
      }
    }
  });
  return files;
};

export const createFormData = (payload) => {
  const orgs = payload.organisations || [];
  const selectedOrganisationsIds = orgs.map((org) => org.id);
  const file = payload.file;
  const visibility = payload.visibility || 'SELECTED_ORGANISATIONS';
  const type = file.documentType;
  const name = file.documentName || '';
  /*   const obj = {
    file,
    type,
    visibility,
    name,
    selectedOrganisationsIds,
  }; */
  const formData = new FormData();

  formData.append('file', file, file.name);
  formData.append('type', type);
  formData.append('visibility', visibility);
  formData.append('name', name);
  formData.append('selectedOrganisationsIds', selectedOrganisationsIds);
  return formData;
};
