import { takeEvery, call, put, select } from 'redux-saga/effects';

import {
  GET_ADMINS_SUCCESS,
  GET_ADMINS_ERROR,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_ERROR,
  GET_ADMINS,
  RESET_ADMINS,
  GET_ADMIN,
  INIT_ADMIN_SUCCESS,
  INIT_ADMIN_ERROR,
  DESTROY_ADMIN_SUCCESS,
  DESTROY_ADMIN_ERROR,
  UPDATE_ADMIN_ERROR,
  UPDATE_ADMIN_SUCCESS,
  INIT_ADMIN,
  DESTROY_ADMIN,
  UPDATE_ADMIN,
  CREATE_ADMIN_ITEM,
  CREATE_ADMIN_ITEM_SUCCESS,
  CREATE_ADMIN_ITEM_ERROR,
  UPDATE_ADMIN_ITEM,
  UPDATE_ADMIN_ITEM_SUCCESS,
  UPDATE_ADMIN_ITEM_ERROR,
} from '../constants';

import {
  getAdmins,
  getAdmin,
  resetAdmins,
  initAdmin as actionInitAdmin,
  destroyAdmin as actionDestroyAdmin,
  updateAdmin as actionUpdateAdmin,
  createAdminItem,
} from '../actions';

import api from '../api';
import auth from '../../auth';
import organisations from '../../organisations';
import users from '../../users';

import { isObject } from '../../../commons/utils/functions';

/* import {
  getAll as getAllAdmins,
  getActiveContract,
  getAllLocalAdmins,
  getLastUpdatedTime,
} from '../selectors'; */

import { SOCKET_RECONNECTED } from '../../../modules/socket/actionTypes';

/**
 *
 * @param {*} action
 */
export function* getAll() {
  try {
    const token = yield call(auth.selectors.getToken);

    let options = {
      token,
      params: {},
      type: 'organisations',
    };
    const _organisations = yield call(api.getAll, options);
    const organisations = {};
    _organisations.forEach((o) => {
      organisations[o.id] = o;
    });

    options.type = 'users';

    const _users = yield call(api.getAll, options);
    const users = {};
    _users.forEach((o) => {
      users[o.id] = o;
    });

    const payload = {
      organisations,
      users,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getAdmins(GET_ADMINS_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getAdmins(GET_ADMINS_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* get() {
  try {
    const fxrate = yield call(api.get);

    const payload = {
      items: [fxrate],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getAdmin(GET_ADMIN_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getAdmin(GET_ADMIN_ERROR, { error }));
    }
  }
}

function* create(action) {
  try {
    const token = yield call(auth.selectors.getToken);

    const options = {
      ...action.payload,
      token,
    };
    const { type } = options;
    const response = yield call(api.create, options);
    let nextResponse;
    const meta = {
      receivedAt: new Date(),
    };

    if (type === 'organisations') {
      const organisationId = response.id;
      if (organisationId) {
        const options = {
          token,
          params: {},
        };
        nextResponse = yield call(organisations.api.get, organisationId, options);
      }
      const payload = {
        type: options.type,
        items: nextResponse ? [nextResponse] : [response],
      };

      yield put(getAdmin(organisations.constants.GET_ORGANISATION_SUCCESS, payload, meta));
    }

    if (type === 'users') {
      const userId = response.id;
      if (userId) {
        const options = {
          token,
          params: {},
        };
        nextResponse = yield call(users.api.get, userId, options);
      }
      const payload = {
        type: options.type,
        items: nextResponse ? [nextResponse] : [response],
      };

      yield put(getAdmin(users.constants.GET_USER_SUCCESS, payload, meta));
    }
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(createAdminItem(CREATE_ADMIN_ITEM_ERROR, { error }));
    }
  }
}

function* update(action) {
  try {
    const token = yield call(auth.selectors.getToken);
    const user = yield select(auth.selectors.getServerUser);
    const options = {
      ...action.payload,
      token,
    };

    const { type } = options;

    const response = yield call(api.update, options);
    let nextResponse;
    const meta = {
      receivedAt: new Date(),
    };

    if (type === 'organisations') {
      const organisationId = response.id;

      if (organisationId) {
        const options = {
          token,
          params: {},
        };
        nextResponse = yield call(organisations.api.get, organisationId, options);
      }

      let payload = {
        type: options.type,
        items: nextResponse ? [nextResponse] : [response],
      };

      if (organisationId === user.organisationId) {
        payload = {
          type: options.type,
          item: nextResponse ? nextResponse : response,
        };
        yield put(getAdmin(organisations.constants.GET_MY_ORGANISATION_SUCCESS, payload, meta));
      } else {
        yield put(getAdmin(organisations.constants.GET_ORGANISATION_SUCCESS, payload, meta));
      }
    }

    if (type === 'users') {
      const userId = response.id;
      if (userId) {
        const options = {
          token,
          params: {},
        };
        nextResponse = yield call(users.api.get, userId, options);
      }
      const payload = {
        type: options.type,
        items: nextResponse ? [nextResponse] : [response],
      };

      yield put(getAdmin(users.constants.GET_USER_SUCCESS, payload, meta));
    }
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getAdmin(UPDATE_ADMIN_ITEM_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */

/**
 *
 * @param {*} action
 */

function* initAdmin(action) {
  try {
    const {
      payload: { id, widget },
    } = action;

    const payload = {
      id,
    };

    const meta = { receivedAt: new Date() };

    if (isObject(widget)) {
      payload.widget = widget;
    }

    yield put(actionInitAdmin(INIT_ADMIN_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionInitAdmin(INIT_ADMIN_ERROR, { error }, { receivedAt: new Date() }));
  }
}

function* destroyAdmin(action) {
  try {
    const payload = {
      id: action.payload.id,
    };

    const meta = { receivedAt: new Date() };

    yield put(actionDestroyAdmin(DESTROY_ADMIN_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionDestroyAdmin(DESTROY_ADMIN_ERROR, { error }, { receivedAt: new Date() }));
  }
}

function* updateAdmin(action) {
  try {
    const {
      payload: { id },
    } = action;

    const payload = {
      id,
    };

    const meta = { receivedAt: new Date() };

    yield put(actionUpdateAdmin(UPDATE_ADMIN_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionUpdateAdmin(UPDATE_ADMIN_ERROR, { error }, { receivedAt: new Date() }));
  }
}

/**
 *
 * @param {*} action
 */
function* reset() {
  yield put(resetAdmins(RESET_ADMINS));
}

export function* watchGetAdmins() {
  yield takeEvery(GET_ADMINS, getAll);
}

export function* watchGetAdmin() {
  yield takeEvery(GET_ADMIN, get);
}

export function* watchResetAdmins() {
  yield takeEvery(RESET_ADMINS, reset);
}

export function* watchInitAdmin() {
  yield takeEvery(INIT_ADMIN, initAdmin);
}

export function* watchDestroyAdmin() {
  yield takeEvery(DESTROY_ADMIN, destroyAdmin);
}

export function* watchUpdateAdmin() {
  yield takeEvery(UPDATE_ADMIN, updateAdmin);
}

export function* watchCreateAdminItem() {
  yield takeEvery(CREATE_ADMIN_ITEM, create);
}

export function* watchUpdateAdminItem() {
  yield takeEvery(UPDATE_ADMIN_ITEM, update);
}

// TODO: DUPLICATE FUNCTIONALIT IN INIT AND processAdmins. Extract in single method
/* function* processAdmins() {
  try {
    const payload = {};
    const meta = {};
    yield put(actionUpdateAdmin(UPDATE_ADMIN_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionUpdateAdmin(UPDATE_ADMIN_ERROR, { error }, { receivedAt: new Date() }));
  }
} */

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}

function* socketReconnected() {
  yield call(getAll);
}

export function* watchSocketReconnected() {
  yield takeEvery(SOCKET_RECONNECTED, socketReconnected);
}
