import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { lightBlue } from '@material-ui/core/colors';
import FileRow from '../../fileUpload/components/FileRow';
import FileViewer from '../../fileUpload/components/FileViewer';

import { ResetButton } from '../../../commons/components/buttons';
import * as actionTypes from '../actionTypes';
import { createAction } from '../../../commons/actions';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(),
  },
  dropContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed ${theme.palette.divider}`,
    color: theme.palette.text.opacity,
    padding: theme.spacing(),
    borderRadius: '8px',
    backgroundColor: theme.palette.background.row,
    height: '190px',
    maxHeight: '190px',
  },

  fileInput: {
    opacity: 0,
    position: 'absolute',
    ZIndex: -1,
  },
  uploadIcon: {
    fontSize: '48px',
  },
  browse: {
    padding: theme.spacing(),
    border: `1px solid ${lightBlue[300]}`,
    color: lightBlue[300],
    '&:hover': {
      transition: 'all .5s ease',
      backgroundColor: lightBlue[500],
      color: 'white',
    },
    cursor: 'pointer',
  },
  itemContainer: {
    padding: theme.spacing(0.5),
  },
  uploadedFiles: {
    padding: theme.spacing(),
    flex: 1,
    overflowY: 'scroll',
  },
  buttonRow: {
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LogoUpload = (props) => {
  const { classes } = props;
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState();
  const [showFile, setShowFile] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
  const [snackbarFileErrorOpen, setSnackbarFileErrorOpen] = useState(false);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  useEffect(() => {
    if (props) {
    }
  });
  const handleSuccessClose = () => {
    setSnackbarSuccessOpen(false);
  };
  const handleErrorClose = () => {
    setSnackbarErrorOpen(false);
  };
  const handleFileErrorClose = () => {
    setSnackbarFileErrorOpen(false);
    setRejectedFiles([]);
  };
  const handleLogoUploadResult = (result) => {
    if (result) {
      setSnackbarSuccessOpen(true);
    } else {
      setSnackbarErrorOpen(true);
    }
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*, .png, .jpeg',
    noClick: true,
    noKeyboard: true,
    maxSize: 50000,
    onDrop: (acceptedFiles, fileRejections) => {
      const nextFiles = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            filename: file.name,
          }),
        ),
      ];
      setRejectedFiles(fileRejections);
      if (fileRejections.length > 0) setSnackbarFileErrorOpen(true);
      setFiles(nextFiles);
      setDragging(false);
    },
    onDragEnter: () => {
      setDragging(true);
    },
    onDragLeave: () => {
      setDragging(false);
    },
  });
  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);

    setFiles(newFiles);
  };
  const handleShowFile = (file) => {
    setFile(file);
    setShowFile(true);
  };

  const handleDocumentNameChange = (name) => (event) => {
    const newFiles = [...files];
    newFiles[name].documentName = event.target.value;
    setFiles(newFiles);
  };
  const createFiles = () => {
    files.forEach((file) => {
      var reader = new FileReader();
      let base64data;
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const dataUrl = reader.result;
        base64data = dataUrl.split(',')[1];

        const payload = {
          organisationId: props.organisationId,
          body: { fileName: props.organisationShortName, body: base64data },
          callback: handleLogoUploadResult,
        };
        props.updateLogo(payload);
      };
    });

    setFiles([]);
  };

  return (
    <div className={`${classes.container} undraggable`}>
      <div
        className={classes.dropContainer}
        {...getRootProps()}
        style={dragging ? { opacity: 0.5 } : { opacity: 1 }}
      >
        <div className={classes.itemContainer}>
          <div className={`material-icons ${classes.uploadIcon}`}>get_app</div>
        </div>
        <div className={classes.itemContainer}>
          <div>{`Drag and Drop .PNG or JPEG files (Max. Size 50KB) `}</div>
        </div>

        <input style={{ flex: 1 }} {...getInputProps()} id="upload-file"></input>

        <div className={classes.itemContainer}>
          <div>or</div>
        </div>
        <div className={classes.itemContainer}>
          <div type="button" onClick={open} className={classes.browse}>
            Browse Files
          </div>
        </div>
      </div>

      <div className={classes.uploadedFiles}>
        {files.map((file, index) => (
          <FileRow
            file={file}
            index={index}
            key={file.name}
            removeFile={() => removeFile(index)}
            showFile={() => handleShowFile(file)}
            handleDocumentNameChange={handleDocumentNameChange}
          />
        ))}
        <Snackbar open={snackbarSuccessOpen} autoHideDuration={6000} onClose={handleSuccessClose}>
          <Alert onClose={handleSuccessClose} severity="info" sx={{ width: '100%' }}>
            Logo successfully uploaded!
          </Alert>
        </Snackbar>
        <Snackbar open={snackbarErrorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
            Logo failed to upload!
          </Alert>
        </Snackbar>
        <Snackbar
          open={snackbarFileErrorOpen}
          autoHideDuration={6000}
          onClose={handleFileErrorClose}
        >
          <Alert onClose={handleFileErrorClose} severity="error" sx={{ width: '100%' }}>
            {rejectedFiles.map((file) => {
              return file.errors[0].message;
            })}
          </Alert>
        </Snackbar>
      </div>
      <div className={classes.footer}>
        <ResetButton title="UPLOAD" icon="publish" onClick={createFiles} />
      </div>
      <FileViewer file={file} open={showFile} onClose={() => setShowFile(false)} preview />
    </div>
  );
};

LogoUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateLogo: (payload) => {
      dispatch(createAction(actionTypes.UPDATE_LOGO, payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LogoUpload));
