const get = (obj, fieldsToCheck = []) => {
  let fields = {};

  obj
    .filter((item) => {
      let val = true;
      fieldsToCheck.forEach((f) => {
        if (item.hasOwnProperty(f)) {
          if (item[f] === false) {
            val = false;
          }
        } else {
          val = false;
        }
      });
      return val;
    })
    .forEach((item) => (fields[item.name] = item));

  /* Object.keys(obj)
    .filter((key) => {
      let val = true;
      fieldsToCheck.forEach((f) => {
        if (obj[key].hasOwnProperty(f)) {
          if (obj[key][f] === false) {
            val = false;
          }
        } else {
          val = false;
        }
      });
      return val;
    })
    .forEach((key) => (fields[key] = obj[key])); */
  return fields;
};

export const getFieldsObject = (models, type = 'order', fieldsToCheck = ['required']) => {
  switch (type) {
    case 'order':
    case 'rfq':
      return {
        ...get(models[`${type}Model`].fields, fieldsToCheck),
        ...get(models[`${type}AssetsModel`].fields, fieldsToCheck),
        ...get(models[`${type}ContractModel`].fields, fieldsToCheck),
        ...get(models[`${type}ContractUnderlyingModel`].fields, fieldsToCheck),
      };

    /*  case 'trade':
      const tradeFields = { ...trade.fields, ...getPostTradeBlotterItems() };
      return {
        ...get(orderContract.fields, fieldsToCheck),
        ...get(orderUnderlying.fields, fieldsToCheck),
        ...get(order.fields, fieldsToCheck),
        ...get(orderData.fields, fieldsToCheck),
        ...get(rfqExtensions.fields, fieldsToCheck),
        ...get(tradeFields, fieldsToCheck),
      }; */
    default:
      return {};
  }
};
