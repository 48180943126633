import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import {
  Button,
  MenuItem,
  Select,
  AppBar,
  Toolbar,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import { fade } from '@material-ui/core/styles/colorManipulator';

import OrderTable from './OrderTable';
import OrderListsMenu from './OrderListsMenu';
import GlobalSettings from './GlobalSettings';

import CalcTool from '../../../calculators/components/CalcTool';

import { getContractWeek, getDatesFromWeek } from '../../../contracts/utils/deliveryWeeks';

import {
  ordersManager as actionOrdersManager,
  editorOrderLists as actionEditorOrderLists,
} from '../../actions';

import {
  ORDERS_MANAGER_UPDATE,
  ORDERS_MANAGER_TABLE_SEND_ORDERS,
  UPDATE_EDITOR_ORDER_LISTS,
  ORDERS_MANAGER_INIT,
} from '../../constants';

import {
  getActiveContract,
  getOrdersFromSettings,
  getSegmentValuesFromActiveContract,
  getMarketFromManagerOrder,
  getCalcultorFromManagerOrder,
  getOthersFromManagerOrder,
  getActiveOrderList,
} from '../../selectors';

const week = getContractWeek(new Date());
const dates = getDatesFromWeek(week);

const styles = (theme) => ({
  container: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: fade(theme.palette.common.black, 0.1),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },

    marginRight: '2px',
    marginLeft: '25px',
    width: '200px',
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    width: '20px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    margin: '0px 5px',
  },
  input: {
    width: '100%',
    marginLeft: '30px',
    color: theme.palette.text.disabled,
  },
  rows: {
    diplay: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  banner: {
    height: '48px',
    minHeight: '48px',
    color: 'white',
    justifyContent: 'space-between',
    paddingRight: 0,
  },
  bannerRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  appBar: {},
  title: {
    fontSize: '18.85px',
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.text.hint,
    fontWeight: 700,
    fontSize: theme.fontSize.sm,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    paddingTop: theme.spacing(1.6),
    paddingBottom: theme.spacing(1.6),
  },
  snackbar: {
    position: 'absolute',
  },
  snackbarContent: {
    height: 100,
    width: 500,
    backgroundColor: teal[300],
    color: '#fff',
    fontSize: theme.fontSize.md,
  },
  close: {
    padding: theme.spacing(0.5),
  },
  workspaceTitle: {
    color: theme.palette.text.disabled,
  },
  formControlLabel: {
    minWidth: '120px',
  },
});

class Manager extends React.PureComponent {
  constructor(props) {
    super(props);

    this.textInput = React.createRef();

    this.orderListId = null;

    this.state = {
      activeCell: { id: null, name: null },
      specifyHarvestDate: false,
      harvestWeek: week,
      harvestDate: null,
      minHarvestDate: dates.minHarvestDate,
      maxHarvestDate: dates.maxHarvestDate,
    };
  }

  componentDidMount() {
    this.updateOrderListId();
  }

  componentDidUpdate() {
    this.updateOrderListId();
  }

  handleSnackbarClose = () => {
    const payload = {
      action: 'closeSnackbar',
      snackbarOpen: false,
    };

    this.props.updateOrdersManager(payload);
  };

  handleSpecifyHarvestDate = (event) => {
    this.setState({ specifyHarvestDate: event.target.checked });
  };

  handleHarvestChange = (name) => (event) => {
    let state = {};
    if (name === 'harvestWeek') {
      const week = event.target.value;
      const dates = getDatesFromWeek(week);
      state = {
        [name]: event.target.value,
        ...dates,
      };
    } else {
      state = { [name]: event };
    }
    this.setState(state);
  };

  handleCalculatorChange = (name, value, errors) => {
    const payload = {
      action: 'updateCalculator',
      fieldName: name,
      value,
      errors,
    };

    this.props.updateOrdersManager(payload);
  };

  handleRowDel = (order) => {
    const payload = {
      action: 'deleteOrder',
      order,
    };

    this.props.updateOrdersManager(payload);
  };

  handleProductTable = (orderId) => (event) => {
    const { name } = event.target;
    const value =
      name === 'active' || name === 'anonymous' ? event.target.checked : event.target.value;

    const payload = {
      action: 'proccessProductTable',
      orderId,
      fieldName: name,
      value,
    };

    this.props.updateOrdersManager(payload);
  };

  handleMarketChange = (event) => {
    const payload = {
      action: 'changeMarket',
      value: event.target.value,
    };

    this.props.updateOrdersManager(payload);
  };

  handleGlobalsChange = (name) => (event) => {
    const payload = {
      action: 'globalsChange',
      fieldName: name,
      value: event.target.value,
    };

    this.props.updateOrdersManager(payload);
  };

  handleGlobalsCheckedChange = (name) => (event) => {
    const payload = {
      action: 'changeStatus',
      fieldName: name,
      active: event.target.checked,
    };

    this.props.updateOrdersManager(payload);
  };

  handleApplyGlobals = (name) => (event) => {
    const payload = {
      action: 'applyGlobals',
      fieldName: name,
    };

    this.props.updateOrdersManager(payload);
  };

  handleSpinnerClick = (number, accessor) => {
    const payload = {
      action: 'applySpinner',
      number,
      accessor,
    };

    this.props.updateOrdersManager(payload);
  };

  sendOrders = () => {
    const { harvestWeek, harvestDate, specifyHarvestDate } = this.state;
    let newIncoTerms = 'FCA';
    if (this.props.calculator) {
      if (this.props.calculator.calculatorState) {
        if (this.props.calculator.calculatorState.ddpPrice) {
          newIncoTerms = 'DDP';
        }
      }
    }
    const payload = {
      harvestWeek,
      harvestDate,
      specifyHarvestDate,
      newIncoTerms,
    };

    this.props.sendOrders(payload);
  };

  showOrderListsMenu = (e) => {
    const payload = {
      action: 'showOrderListsMenu',
      anchorEl: e.target,
      openedMenu: true,
    };

    this.props.updateOrdersManager(payload);
  };

  closeOrderListsMenu = () => {
    const payload = {
      action: 'closeOrderListsMenu',
      openedMenu: false,
    };

    this.props.updateOrdersManager(payload);
  };

  calculatorClick = (e) => {
    const payload = {
      action: 'toogleOpenCalculator',
      anchorElCalculator: e.target,
      openedCalculator: !this.props.settings.openedCalculator,
    };

    this.props.updateOrdersManager(payload);
  };

  changeOrderList = (id) => {
    const payload = {
      action: 'changeOrderList',
      orderListId: id,
      openedMenu: true,
    };

    this.props.updateOrdersManager(payload);
  };

  changeCalculator = (id) => {
    const payload = {
      action: 'changeCalculator',
      calculatorId: id,
    };

    this.props.updateOrdersManager(payload);
  };

  showOrderListEditor = () => {
    const payload = {
      action: 'showOrderListEditor',
      show: true,
    };

    this.props.updateEditorOrderLists(payload);
  };

  init() {
    const payload = {};
    this.props.initOrdersManager(payload);
  }

  updateOrderListId() {
    if (
      (this.props.orderList.id || this.orderListId) &&
      this.props.orderList.id !== this.orderListId
    ) {
      this.orderListId = this.props.orderList.id;
      const payload = {
        action: 'changeOrderList',
        orderListId: this.props.orderList.id,
      };
      setTimeout(() => {
        this.props.updateOrdersManager(payload);
      }, 0);
    }
  }

  render() {
    const {
      classes,
      contract,
      orderLists,
      orderList,
      market,
      segmentValues,
      calculator,
      settings,
    } = this.props;
    const { openedMenu, anchorEl } = settings;
    return (
      <div className={`${classes.container} undraggable`}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar variant="dense">
            <Select
              value={market}
              disableUnderline
              name="market"
              className={classes.title}
              onChange={this.handleMarketChange}
            >
              {segmentValues.map((seg) => (
                <MenuItem key={seg} value={seg}>
                  {seg}
                </MenuItem>
              ))}
            </Select>
            <div style={{ flexGrow: 2 }} />

            <FormControlLabel
              labelPlacement="top"
              classes={{
                label: classes.workspaceTitle,
                labelPlacementTop: classes.formControlLabel,
              }}
              control={
                <Button
                  onClick={this.showOrderListsMenu}
                  aria-label="Delete"
                  style={{ padding: 0 }}
                >
                  <Typography variant="subtitle2">{orderList.name}</Typography>
                </Button>
              }
              label="Order List"
            />

            <OrderListsMenu
              anchorEl={anchorEl}
              open={openedMenu}
              orderLists={orderLists}
              onClick={this.changeOrderList}
              showEditor={this.showOrderListEditor} // TODO: add show order list editor
              onClose={this.closeOrderListsMenu}
            />
          </Toolbar>
        </AppBar>
        <CalcTool
          handleChange={this.handleCalculatorChange}
          calculator={calculator}
          calculatorState={calculator.calculatorState}
          calculatorClick={this.calculatorClick}
          {...calculator.calculatorState}
          changeCalculator={this.changeCalculator}
        />

        <GlobalSettings
          checked={this.state.specifyHarvestDate}
          handleChecked={this.handleSpecifyHarvestDate}
          handleChange={this.handleHarvestChange}
          harvestDate={this.state.harvestDate}
          harvestWeek={this.state.harvestWeek}
          minHarvestDate={this.state.minHarvestDate}
          maxHarvestDate={this.state.maxHarvestDate}
        />
        <OrderTable
          handleSpinnerClick={this.handleSpinnerClick}
          handleApplyGlobals={this.handleApplyGlobals}
          handleGlobalsChange={this.handleGlobalsChange}
          handleGlobalsCheckedChange={this.handleGlobalsCheckedChange}
          handleRowDel={this.handleRowDel}
          handleSendOrders={this.sendOrders}
          handleSnackbarClose={this.handleSnackbarClose}
          onProductTableUpdate={this.handleProductTable}
          orderListId={orderList.id}
          filterText={this.state.filterText}
          activeCell={this.state.activeCell}
          textInput={this.textInput}
          localCurrency={calculator.calculatorState.localCurrency}
          finalCurrency={calculator.calculatorState.finalCurrency}
          contract={contract}
        />
      </div>
    );
  }
}

Manager.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    orderLists: getOrdersFromSettings(state),
    orderList: getActiveOrderList(state, ownProps.widget),
    contract: getActiveContract(state),
    market: getMarketFromManagerOrder(state),
    segmentValues: getSegmentValuesFromActiveContract(state),
    calculator: getCalcultorFromManagerOrder(state),
    settings: getOthersFromManagerOrder(state),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    initOrdersManager: (payload) => {
      dispatch(actionOrdersManager(ORDERS_MANAGER_INIT, payload));
    },
    updateOrdersManager: (payload) => {
      dispatch(actionOrdersManager(ORDERS_MANAGER_UPDATE, payload));
    },
    updateEditorOrderLists: (payload) => {
      dispatch(actionEditorOrderLists(UPDATE_EDITOR_ORDER_LISTS, payload));
    },
    sendOrders: (payload) => {
      dispatch(actionOrdersManager(ORDERS_MANAGER_TABLE_SEND_ORDERS, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Manager));
