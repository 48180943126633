import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReadOnlyField from '../../../commons/components/formFields/readOnlyField';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
  },
  containerOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
  },
  containerItems: {
    //padding: theme.spacing(1),
  },
  rowNotVisible: {
    height: '1px',
  },
  rowMargin: {
    marginBottom: theme.spacing(),
  },
  small: {
    width: theme.spacing(2.4),
    height: theme.spacing(2.4),
    backgroundColor: theme.palette.text.primary,
  },
});

const DetailsAssetsRow = (props) => {
  const { classes } = props;

  const orderRow = props.orderRow || {};
  //const FIELD_WIDTH = '152px';
  const isOdd = props.index % 2 ? classes.containerOdd : classes.container;

  return (
    <div className={isOdd}>
      <div className={classes.containerItems} style={{ flex: 1 }}>
        {Object.keys(orderRow).map((item) => {
          if (item === 'contract') {
            return Object.keys(orderRow[item]).map((i) => (
              <ReadOnlyField displayName={i} value={orderRow[item][i]} key={i} />
            ));
          } else if (item !== 'id' && item !== 'direction') {
            return <ReadOnlyField displayName={item} value={orderRow[item]} key={item} />;
          }
        })}
      </div>
    </div>
  );
};

DetailsAssetsRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailsAssetsRow);
