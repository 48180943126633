import * as actionTypes from '../actionTypes';

//import { isObject, isBoolean, insertItems, updateItems } from '../../../commons/utils/functions';

//import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  forms: {
    request: {
      open: false,
      item: {},
      enums: [],
      fields: {},
      status: null,
      error: null,
      receivedAt: null,
    },
  },
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case actionTypes.OPEN_RFQ_FORM:
      nextState = {
        ...state,
      };
      nextState.forms.request = {
        ...nextState.forms.request,
        status: 'success',
      };
      return nextState;
    case actionTypes.OPEN_RFQ_FORM_SUCCESS:
      nextState = {
        ...state,
      };
      nextState.forms.request = {
        ...nextState.forms.request,
        ...action.payload,
        ...action.meta,
        status: 'success',
      };

      return nextState;
    case actionTypes.OPEN_RFQ_FORM_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };

    default: {
      return state;
    }
  }
};

export default reducer;
