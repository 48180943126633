import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import yellow from '@material-ui/core/colors/amber';

import PopUp from '../../../commons/components/popUp';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import { ConfirmButton, CancelButton } from '../../../commons/components/buttons';
import { directions } from '../../../commons/config/constants';
import SnackbarMessage from '../../../commons/components/snackbarMessage';

import List from './List';
import Active from './Active';

import { getAll, getActiveContract, getUsername } from '../selectors';

const styles = (theme) => ({
  container: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
  },
  rfqTabBorder: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(0.5),
    borderRight: '1px solid',
    borderRightColor: 'rgba(255,255,255,.2)',
    maxWidth: '270px',
    minWidth: '270px',
  },
  rfqTabContainer: {
    overflowY: 'scroll',
  },
  activeRfqContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',

    color: theme.palette.text.disabled,

    marginLeft: theme.spacing(0.5),
  },
  activeRfqContentBorder: {
    padding: theme.spacing(),
    overflow: 'auto',
    border: '1px solid',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  activeRfqContent: {
    overflowY: 'scroll',
    // padding: theme.spacing() * 2,
    flex: 1,
  },
  allRfqTabsContent: {
    // display: "flex",
    // flexDirection:"column",
    overflowY: 'scroll',
    paddingRight: '0px',
    // marginRight: "-10px"
  },

  grow: {
    flexGrow: 1,
  },
  icon: {
    color: 'rgba(255,255,255,.7)',
    cursor: 'pointer',
  },
  row: {
    backgroundColor: theme.palette.primary.row,
  },
  contractRowItems: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(),
  },
  contractRowItems0: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.row,
    padding: theme.spacing(2),
  },

  rfqDetails: {
    backgroundColor: theme.palette.primary.row,
    padding: theme.spacing(0.5),
    display: 'block',
  },

  responseHeaders: {
    backgroundColor: theme.palette.primary.dark,
    textAlign: 'center',
    color: theme.palette.text.hint,
    padding: '14px 0px',
  },
  contractTitle: {
    color: theme.palette.text.disabled,
    fontSize: theme.fontSize.sm,
  },
  contractValue: {
    color: theme.palette.text.secondary,
  },
  ownPrice: {
    fontSize: theme.fontSize.xl,
    color: teal[500],
    padding: 0,
    textAlign: 'center',
  },
  responsePrice: {
    fontSize: theme.fontSize.xl,
    color: yellow[500],
    padding: '0px',
    textAlign: 'center',
  },
  priceBuy: {
    fontSize: theme.fontSize.xl,
    color: lightBlue[500],
    padding: '0px',
    textAlign: 'center',
  },

  priceSell: {
    fontSize: theme.fontSize.xl,
    color: teal[500],
    padding: '0px',
    textAlign: 'center',
  },
  tableCellDefault: {
    fontSize: theme.fontSize.md,
    padding: '0px',

    // textAlign: "center",
    flex: 1,
    '&:last-child': {
      paddingRight: '0px',
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: '0px',
  },
  cellHeader: {
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.hint,
    fontWeight: 500,
    fontSize: theme.fontSize.sm,
    fontFamily: 'Roboto',

    borderBottom: '1px solid',
    borderBottomColor: 'rgba(255,255,255,.2)',
  },

  popUpContainer: { backgroundColor: 'rgba(0,0,0,0)' },
  popUpContainerItems: {
    backgroundColor: theme.palette.primary.row,
    textAlign: 'center',
    padding: '10px',
  },

  tradeConfirmHeaderItem: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.text.disabled,
    fontWeight: 500,
    fontSize: theme.fontSize.sm,
    fontFamily: 'Roboto',
    padding: theme.spacing(),
  },
  textField: {
    width: 300,
  },
  formControlLabel: {
    marginRight: theme.spacing(3),
    marginTop: 0,
    marginBottom: 0,
    fontSize: theme.fontSize.xs,
    color: theme.palette.text.primary,
  },
  iconButton: {
    padding: theme.spacing(0.5),
  },
  buttonDiv: {
    // position: "absolute",
    // right: "10px",
    cursor: 'pointer',
  },
  buyer: {
    color: lightBlue[500],
    fontSize: theme.fontSize.lg,
  },
  seller: {
    color: teal[500],
    fontSize: theme.fontSize.lg,
  },
  sectionHeader: {
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(),

    borderBottom: '1px solid',
    borderColor: theme.palette.divider,
    color: 'rgba(93, 117, 152, .87)',
  },
});

class Rfq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rfqId: null,

      tradeConfirmVisible: false,
      rfqTradeConfirmVisible: false,

      restingOrder: {},
      rfqResponses: [],

      snackbarOpen: false,
      snackbarContent: '',
      snackbarColor: teal[300],
    };
  }

  tabClick = (id) => {
    this.setState({ rfqId: id });
  };

  activeRfq = () => {
    if (this.props.rfqs.length === 0) {
      return null;
    }
    return this.props.rfqs.find((r) => r.id === this.state.rfqId) || null;
  };

  handleChange = (event) => {
    const { restingOrder } = this.state;
    restingOrder.volume = event.target.value;
    this.setState({ restingOrder });
  };

  handleRfqTradeClick = (restingOrder) => {
    this.setState({ rfqTradeConfirmVisible: true, restingOrder });
  };

  closeRfqTradeClick = (responses) => {
    this.setState({ rfqTradeConfirmVisible: false, rfqResponses: [] });
  };

  render() {
    const { classes, widget } = this.props;

    const activeRfq = this.activeRfq(); // TODO: Extract to saga

    /* const trades = JSON.parse(JSON.stringify(this.props.trades))
      .filter((trade) => Object.prototype.hasOwnProperty.call(trade, 'rfqId'))
      .filter((trade) => trade.rfqId === activeRfqId); // TODO: Extract to saga */

    return (
      <div className={`${classes.container} undraggable`}>
        <List tabClick={this.tabClick} activeRfqId={this.state.rfqId} />
        <Active activeRfq={activeRfq} tradeClick={this.handleRfqTradeClick} widget={widget} />

        <PopUp
          open={this.state.tradeConfirmVisible}
          onClose={() => this.setState({ tradeConfirmVisible: false })}
          title="EXECUTE?"
          containerWidth={400}
        >
          <>
            <div className={classes.popUpContainer} />
            <Typography className={classes.tradeConfirmHeaderItem}>ORDER</Typography>
            <div className={classes.popUpContainerItems}>
              <TextField
                id="direction"
                label="Direction"
                defaultValue={
                  this.state.restingOrder.direction === directions.BUY
                    ? directions.SELL
                    : directions.BUY
                }
                className={classes.textField}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                id="price"
                label="Price"
                defaultValue={this.state.restingOrder.price}
                className={classes.textField}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                id="volume"
                type="number"
                label="Volume"
                className={classes.textField}
                value={this.state.restingOrder.volume}
                onChange={this.handleChange}
                inputProps={{ step: 1 }}
                margin="normal"
              />
            </div>
            <ToolbarBottom>
              <CancelButton onClick={() => this.setState({ tradeConfirmVisible: false })} />
              <ConfirmButton onClick={this.executeTrade} />
            </ToolbarBottom>
          </>
        </PopUp>

        <SnackbarMessage
          open={this.state.snackbarOpen}
          close={() => this.setState({ snackbarOpen: false })}
          message={this.state.snackbarContent}
          color={this.state.snackbarColor}
        />
      </div>
    );
  }
}

Rfq.propTypes = {
  classes: PropTypes.object.isRequired,
};

const trades = []; // state.trades.trades,

const mapStateToProps = (state) => {
  return {
    trades,
    rfqs: getAll(state),
    userName: getUsername(state),
    contract: getActiveContract(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Rfq));
