import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Modal, Typography } from '@material-ui/core';
import ReadOnlyField from '../../../commons/components/formFields/readOnlyField';
import DetailsRow from './DetailsRow';
import DetailsAssetsRow from './DetailsAssetsRow';
import { getOrganisationFullNameFromId } from '../../../commons/utils/functions';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import { CancelButton } from '../../../commons/components/buttons';
import {
  getKeyValuePairsFromRfq,
  getFieldsArray,
  getFresh,
} from '../../contracts/utils/modelFunctions';

import { getOrganisations, getActiveUser } from '../selectors';
import { visibilities } from '../../../commons/models/constants';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '98vw',
    height: '95vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    flex: 1,
    backgroundColor: theme.palette.background.inner,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.5),
    borderTop: `1px solid ${theme.palette.divider}`,
    //backgroundColor: 'green',
  },
  sectionTitle: {
    display: 'flex',
    color: theme.palette.text.subtitle,
    paddingLeft: theme.spacing(1),
    //backgroundColor: 'blue',
    flex: 1,
  },
  sectionBody: {
    display: 'flex',
    padding: theme.spacing(),
    //backgroundColor: 'rgba(0,0,0,.1)',
    flex: 1,
  },
  title: {
    color: theme.palette.text.subtitle,
    paddingLeft: theme.spacing(1),
  },
  orderRowsContainer: {
    padding: theme.spacing(),
    //backgroundColor: 'rgba(0,0,0,.1)',
  },
  rowsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    padding: theme.spacing(),
    //backgroundColor: 'rgba(0,0,0,.1)',
  },
});

const getRecipients = (selectedOrganisationsIds, organisations, visibility) => {
  const list = [];
  const orgs = [...organisations];
  if (visibility === visibilities.SELECTED_ORGANISATIONS) {
    selectedOrganisationsIds.forEach((r) => {
      let shortName = r.shortName || null;
      if (shortName === null) {
        shortName = getOrganisationFullNameFromId(r, orgs);
      }
      list.push(shortName);
    });
  } else {
    return [visibility];
  }

  list.sort();

  return list;
};

const Details = (props) => {
  const { classes, activeRfq, organisations, user } = props;
  let recipients = [];
  let details = [];
  let extensions = [];
  let orderItems = [];
  let rfqTypeFields = [];
  let assets = [];
  let sender = '';

  if (activeRfq !== null) {
    if (activeRfq.organisationId === user.organisationId) {
      const visibility = activeRfq.visibility;
      const selectedOrganisationsIds = activeRfq.selectedOrganisationsIds || [];

      recipients = getRecipients(selectedOrganisationsIds, organisations, visibility);
    }
    sender = getOrganisationFullNameFromId(activeRfq.organisationId, organisations);

    //details = new RFQRequestDetails(activeRfq);
    const values = getKeyValuePairsFromRfq(activeRfq);
    const fresh = getFresh(activeRfq.contract.underlying);
    details = values.order;
    extensions = values.extensions || {};
    const rfqTypeObj = extensions.find((item) => item.key === 'Rfq Type') || {};
    let rfqType = details.instrument || 'spot';
    rfqType = rfqType.toLowerCase();
    rfqTypeFields = getFieldsArray('rfq', ['isRfqType', rfqType, fresh]);
    orderItems = extensions.find((item) => item.key === 'Order Items') || {};
    if (orderItems.value === undefined) orderItems.value = [];
    assets = values.assets || [];
  }

  return (
    <Modal open={props.open} onClose={props.closeForm}>
      <div style={getModalStyle()} className={classes.paper}>
        <Typography className={classes.title}>OFFER DETAILS</Typography>
        <div className={classes.container}>
          <div className={classes.section}>
            <div className={classes.sectionTitle}>
              <div>Sender:</div>
            </div>
            <div
              className={classes.sectionBody}
              style={{ flexDirection: 'column', fontSize: '16px' }}
            >
              {sender}
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionTitle}>
              <div>Rfq Sent To:</div>
            </div>
            <div className={classes.sectionBody} style={{ flexDirection: 'column' }}>
              {recipients.map((r) => (
                <div key={r} style={{ fontSize: '16px' }}>
                  {r}
                </div>
              ))}
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitle}>Comments:</div>
            <div className={classes.sectionBody}>
              <div style={{ fontSize: '16px' }}>{activeRfq.textMessage || ''}</div>
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitle}>Rfq Type:</div>
            <div className={classes.rowsContainer}>
              {rfqTypeFields.map((ext, index) => {
                const displayName = ext.displayName;
                const valueObj = extensions.find((item) => item.key === displayName) || {};
                const value = valueObj.value || '';
                return (
                  <React.Fragment key={index}>
                    <ReadOnlyField displayName={displayName} value={value} />
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitle}>Suggested Breakdown:</div>
            <div className={classes.orderRowsContainer}>
              {orderItems.value.map((item, index) => {
                return <DetailsRow key={index} orderRow={item} index={index} />;
              })}
              {assets.map((asset, index) => {
                return <DetailsAssetsRow key={asset.id} orderRow={asset} index={index} />;
              })}
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitle}>Details:</div>
            <div className={classes.rowsContainer}>
              {details.map((detail, index) => {
                const val = detail.value;
                const displayName = detail.key;
                let value = '';
                if (Array.isArray(val)) {
                  value = val.join(' , ');
                } else {
                  value = val;
                }

                return (
                  <React.Fragment key={index}>
                    <ReadOnlyField displayName={displayName} value={value} />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <ToolbarBottom>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CancelButton title="Close" onClick={props.closeForm} />
          </div>
        </ToolbarBottom>
      </div>
    </Modal>
  );
};

Details.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    organisations: getOrganisations(state), // state.rooms.organisations,
    //contract: getActiveContract(state), // state.client.contract,
    user: getActiveUser(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Details));
