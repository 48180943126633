import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Typography } from '@material-ui/core';

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  title: { fontSize: theme.fontSize * 1.5 },

  container: {
    backgroundColor: 'rgba(0,0,0,.1)',

    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

const PopUp = (props) => {
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      width: props.containerWidth || 640,
      maxHeight: props.containerHeight || '90%',
    };
  }

  const { classes } = props;
  return (
    <Modal open={props.open} onClose={props.onClose} className="undraggable">
      <div style={getModalStyle()} className={classes.paper}>
        <div style={{ flex: 1, paddingBottom: '8px' }}>
          <Typography className={classes.title}>{props.title}</Typography>
        </div>

        <div className={classes.container}>{props.children}</div>
      </div>
    </Modal>
  );
};

PopUp.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withStyles(styles)(PopUp);
